import {
  Col,
  Row,
  PageHeader,
  Card,
  Table,
  Space,
  Button,
  Input,
  Checkbox,
} from "antd";
import Layout from "antd/lib/layout/layout";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { NameWithAvatar } from "./components/StudentNameWithAvatar";
import { Link } from "react-router-dom";
import PhoneNumberExport from "./components/PhoneNumberExport";

class Students extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    showNotVerified: false,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              this.handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ marginBottom: 8 }}>
            <Checkbox
              checked={this.state.showNotVerified}
              onChange={(e) => {
                const value = e.target.checked;
                this.setState({
                  showNotVerified: value,
                });
              }}
            >
              Profile Picture Not Verified
            </Checkbox>
          </div>
        </Col>
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },

    render: (text, record) =>
      dataIndex === "name" ? (
        this.state.searchedColumn === dataIndex ? (
          <NameWithAvatar
            searchText={this.state.searchText}
            text={text}
            record={record}
            withHighlighter
          />
        ) : (
          <NameWithAvatar text={text} record={record} />
        )
      ) : this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "", showNotVerified: false });
  };

  render() {
    const { students } = this.props;

    if (isLoaded(students)) {
      const columns = [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          ...this.getColumnSearchProps("name"),
        },
        {
          title: "Phone Number",
          dataIndex: "phone",
          key: "phone",
          ...this.getColumnSearchProps("phone"),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
          sorter: (a, b) => a.createdAt - b.createdAt,
          sortDirections: ["descend", "ascend"],
          render: (text, record) => (
            <span>{moment(text).format("YYYY-MM-DD HH:mm A").toString()}</span>
          ),
        },
        {
          title: "Action",
          key: "action",
          render: (text, record) => (
            <Space>
              <Link to={`/student/${record.key}`}>
                <Button icon={<EyeOutlined />}>View</Button>
              </Link>
            </Space>
          ),
        },
      ];

      let data = students.map((student) => ({
        key: student.id,
        name: `${student.firstName} ${student.lastName}`,
        phone: student.phoneNumber,
        profilePic: student?.profilePicture,
        createdAt: student?.createdAt?.toDate(),
      }));

      if (this.state.showNotVerified) {
        data = students
          .filter((value) => value?.profilePicture?.isVerified === false)
          .map((student) => ({
            key: student.id,
            name: `${student.firstName} ${student.lastName}`,
            phone: student.phoneNumber,
            profilePic: student?.profilePicture,
            createdAt: student?.createdAt?.toDate(),
          }));
      }

      return (
        <Layout>
          <PageHeader
            ghost={false}
            className="site-page-header"
            title="Students"
          />
          <Row>
            <Col justify="center" md={{ span: 24 }} sm={{ span: 24 }}>
              <Card
                title="Phone Number Export"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <PhoneNumberExport />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card title="All Students" style={{ margin: "1rem 1rem 0 1rem" }}>
                <Table size="small" dataSource={data} columns={columns} />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            className="site-page-header"
            title="Students"
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All Students"
                style={{ margin: "1rem 1rem 0 1rem" }}
                bodyStyle={{ paddingTop: 0 }}
              >
                <Table size="small" loading={true} className="pt-3" />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    students: state.firestore.ordered.students,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const out = [];

    if (props.userRole === "COMPANY_ADMIN") {
      out.push({
        collection: "students",
        where: [["signupCompleted", "==", true]],
        orderBy: ["createdAt", "desc"],
      });
    }

    return out;
  })
)(Students);