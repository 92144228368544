import { Button, Row, Select, Space } from "antd";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";

import fileDownload from "js-file-download";
const { Option } = Select;

class PhoneNumberExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGrade: undefined,
      selectedStream: undefined,
      loading: false,
    };
    this.csvRef = React.createRef();
  }

  handleExport = async () => {
    this.setState({
      loading: true,
    });
    let data;
    let formated = [];
    const ref = this.props.firestore.collection("students");
    if (this.state.selectedGrade && this.state.selectedStream) {
      data = await ref
        .where("gradeUid", "==", this.state.selectedGrade)
        .where("streamUid", "==", this.state.selectedStream)
        .get();
    } else {
      if (this.state.selectedGrade) {
        data = await ref
          .where("gradeUid", "==", this.state.selectedGrade)
          .get();
      } else if (this.state.selectedStream) {
        data = await ref
          .where("streamUid", "==", this.state.selectedStream)
          .get();
      } else {
        data = await ref.get();
      }
    }

    formated = await data.docs.map((i) => {
      const phoneNumber = i.data()["phoneNumber"];
      return phoneNumber;
    });
    fileDownload(
      formated.join("\n"),
      `export_phone_${new Date().getTime()}.csv`
    );

    this.setState({
      loading: false,
    });
  };

  render() {
    const { grades, streams } = this.props;

    if (isLoaded(grades, streams)) {
      return (
        <div>
          <Row>
            <Space>
              <Select
                disabled={this.state.loading}
                allowClear
                style={{ width: 300 }}
                placeholder="Select Stream"
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  this.setState({
                    selectedStream: value,
                  });
                }}
              >
                {streams.map((stream, i) => {
                  return (
                    <Option value={stream.id} key={i}>
                      {stream.name}
                    </Option>
                  );
                })}
              </Select>
              <Select
                disabled={this.state.loading}
                allowClear
                style={{ width: 300 }}
                placeholder="Select Grade"
                showSearch
                optionFilterProp="children"
                onChange={(value) => {
                  this.setState({
                    selectedGrade: value,
                  });
                }}
              >
                {grades.map((grade, i) => {
                  return (
                    <Option value={grade.id} key={i}>
                      {grade.display}
                    </Option>
                  );
                })}
              </Select>
              <Button
                onClick={this.handleExport}
                loading={this.state.loading}
                disabled={this.state.loading}
              >
                {this.state.loading ? "Loading..." : " Export Phone Numbers"}
              </Button>
            </Space>
          </Row>
        </div>
      );
    }
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    streams: state.firestore.ordered.streams,
    grades: state.firestore.ordered.grades,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: "streams",
    },
    {
      collection: "grades",
      where: [["active", "==", true]],
    },
  ])
)(PhoneNumberExport);
