import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import {
  Layout,
  PageHeader,
  Spin,
  Row,
  Col,
  Card,
  Descriptions,
  Typography,
  Skeleton,
  Tag,
  List,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { getTransactionById } from "../../../store/actions/transaction";

const { Text } = Typography;

class TxPreview extends Component {
  state = {
    loading: false,
  };

  componentDidMount(){
    const transactionId = this.props.match.params.id;
    this.props.getTransactionById(transactionId);
  }

  getEntityTypeName(entityType){
    let name = '';
    switch(entityType){
      case "S":
        name = "SESSION"
        break
      case "C":
        name = "CLASS CARD"
        break
      case "L":
        name = "LIVE SESSION"
        break
      case "P":
        name = "PAPER"
        break
      case "T":
        name = "TOPUP"
        break
      default:
        name = "-"
    }
    return name;
  }

  render() {
    const {
      selected_tx,
      selected_tx_id,
      selected_tx_paper,
      selected_tx_session,
      // selected_tx_session_booking,
      selected_tx_user,
    } = this.props;
    if (
      selected_tx != null &&
      isLoaded(selected_tx, selected_tx_user)
    ) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Transaction"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card style={{ margin: "1rem 1rem 0 1rem" }}>
                <Descriptions title="Transaction Info">
                  <Descriptions.Item label="Transaction ID">
                    {selected_tx?.id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Amount">
                    {selected_tx?.amount}
                  </Descriptions.Item>
                  <Descriptions.Item label="Type">
                    {selected_tx?.type}
                  </Descriptions.Item>
                  <Descriptions.Item label="Entity Type">
                    {this.getEntityTypeName(selected_tx?.entity_type)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created">
                    {moment(selected_tx?.created_at).fromNow()}
                  </Descriptions.Item>
                  <Descriptions.Item label="Exact Time">
                    {moment(selected_tx?.created_at).format(
                      "dddd, MMM Do YYYY, h:mm:ss a"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Order Id">
                    {selected_tx?.order_id}
                  </Descriptions.Item>
                </Descriptions>

                <List
                  header={<Text strong>Validations</Text>}
                  bordered
                  dataSource={selected_tx?.validations}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card style={{ margin: "1rem 1rem 0 1rem" }}>
                <Descriptions title="User Info">
                  <Descriptions.Item label="Name">
                    {selected_tx_user?.firstName} {selected_tx_user.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {selected_tx_user?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone">
                    {selected_tx_user?.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Grade">
                    {selected_tx_user?.gradeName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Medium">
                    {selected_tx_user?.medium}
                  </Descriptions.Item>
                  <Descriptions.Item label="Stream">
                    {selected_tx_user?.streamName}
                  </Descriptions.Item>
                  <Descriptions.Item label="District">
                    {selected_tx_user?.district}
                  </Descriptions.Item>
                  <Descriptions.Item label="School">
                    {selected_tx_user?.schoolName}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          {isLoaded(selected_tx_session) && selected_tx.entity_type === "S" ? (
            <Row>
              <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
                <Card style={{ margin: "1rem 1rem 0 1rem" }}>
                  <Descriptions title="Session Info">
                    <Descriptions.Item label="Name">
                      {selected_tx_session?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee">
                      {selected_tx_session?.fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Extension Fee">
                      {selected_tx_session?.extensionFee}
                    </Descriptions.Item>
                    {/* {selected_tx_session_booking ? (
                      <>
                        <Descriptions.Item label="Video Played">
                          {selected_tx_session_booking.vidPlayStarted
                            ? moment(selected_tx_session_booking.vidPlayStarted.toDate()).format("dddd, MMM Do YYYY, h:mm:ss a")
                            : "N/A"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Booking Payment">
                          {selected_tx_session_booking?.fee}
                        </Descriptions.Item>
                      </>
                    ) : null} */}
                  </Descriptions>
                  <NavLink
                    to={{
                      pathname:
                        "/classes/edit/" +
                        selected_tx?.entity_uid?.split('_')[0] +
                        "/sessions/" +
                        selected_tx?.entity_uid?.split('_')[1],
                      key: selected_tx?.class_uid,
                    }}
                    className="waves-effect sidenav-close"
                  >
                    Read more
                  </NavLink>
                </Card>
              </Col>
            </Row>
          ) : null}
          {isLoaded(selected_tx_paper) && selected_tx.type === "PAPER" ? (
            <Row>
              <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
                <Card style={{ margin: "1rem 1rem 0 1rem" }}>
                  <Descriptions title="Paper Info">
                    <Descriptions.Item label="Name">
                      {selected_tx_paper?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee">
                      {selected_tx_paper?.fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Medium">
                      {selected_tx_paper?.medium}
                    </Descriptions.Item>
                  </Descriptions>
                  <NavLink
                    to={{
                      pathname: "/papers/preview/" + selected_tx?.entity_uid,
                      key: selected_tx?.entity_uid,
                    }}
                    className="waves-effect sidenav-close"
                  >
                    Read more
                  </NavLink>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Transaction"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Transaction is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}
const mapStateToProps = (state, props) => {
  return {
    selected_tx: state.txs.transaction,
    selected_tx_user: state.firestore.data.selected_tx_user,
    selected_tx_session: state.firestore.data.selected_tx_session,
    // selected_tx_session_booking:
    //   state.firestore.data.selected_tx_session_booking,
    selected_tx_paper: state.firestore.data.selected_tx_paper,
    selected_tx_id: props.match.params.id,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
    fetchError: state.txs.fetchError,
  };
};

const getDcoFromEntityId = (entityId) => {
  if (entityId) {
    return entityId.split("_")[1];
  }
  return " ";
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactionById: (transactionId) => dispatch(getTransactionById(transactionId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let out = [];

    // retrieve user only when user_uid from transaction is available
    if (props.selected_tx?.student_uid) {
      out.push({
        collection: "students",
        doc: props.selected_tx?.student_uid,
        storeAs: "selected_tx_user",
      });
    }

    switch (props.selected_tx?.entity_type) {
      case "S":
        out.push({
          collection: "classes",
          doc: props.selected_tx?.entity_uid?.split('_')[0],
          subcollections: [
            {
              collection: "sessions",
              doc: getDcoFromEntityId(props.selected_tx?.entity_uid),
            },
          ],
          storeAs: "selected_tx_session",
        });
        // out.push({
        //   collection: "users",
        //   doc: props.selected_tx?.user_uid,
        //   subcollections: [
        //     {
        //       collection: "booked_sessions",
        //       doc: props.selected_tx?.entity_uid,
        //     },
        //   ],
        //   storeAs: "selected_tx_session_booking",
        // });
        break;
      case "P":
        out.push({
          collection: "exams",
          doc: getDcoFromEntityId(props.selected_tx?.entity_uid),
          storeAs: "selected_tx_paper",
        });
        break;

      default:
        break;
    }
    return out;
  })
)(TxPreview);
