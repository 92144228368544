import React from "react";
import { Space, Badge, Avatar, Typography } from "antd";
import Highlighter from "react-highlight-words";
import {
  CheckOutlined,
  WarningOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const NameWithAvatar = ({
  text,
  record,
  withHighlighter = false,
  searchText,
}) => {
  return (
    <Space>
      {record.profilePic ? (
        <Badge
          style={{ right: 2, top: 2 }}
          count={
            <span
              style={{
                padding: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: record.profilePic.isVerified
                  ? "#21BF73"
                  : "#F05454",
                borderRadius: "100%",
              }}
            >
              {record.profilePic.isVerified ? (
                <CheckOutlined style={{ fontSize: "8px", color: "#fff" }} />
              ) : (
                <WarningOutlined style={{ fontSize: "8px", color: "#fff" }} />
              )}
            </span>
          }
        >
          <Avatar icon={<UserOutlined />} src={record?.profilePic?.url} />
        </Badge>
      ) : (
        <Avatar icon={<UserOutlined />} />
      )}

      {withHighlighter ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        <Typography.Text>{text}</Typography.Text>
      )}
    </Space>
  );
};
