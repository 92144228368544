import React, { Component } from "react";
import TicketTable from "./components/TicketTable";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Row, Col, Layout, PageHeader, Card, Button, Skeleton } from "antd";
import { NavLink } from "react-router-dom";

class Support extends Component {
  render() {
    const { tickets, role } = this.props;
    if (isLoaded(tickets)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Tickets"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All tickets"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <TicketTable data={tickets} role={role} />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Tickets"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All tickets"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tickets: state.firestore.ordered.support_tickets,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "support_tickets",
      orderBy: "createdAt",
    },
  ])
)(Support);
