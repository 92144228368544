import { Button, Descriptions, List, Typography } from "antd";
import React, { Component } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import { NavLink } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";

const {Text} = Typography;

class StudentInfoListItem extends Component {
  getOrderTime = () => {
    const orderTime = moment(this.props.time.replace('Z', ''));
    if( orderTime.isValid() ){
      return orderTime.format('YYYY-MM-DD hh:mm:ss A');
    }
    return this.props.time;
  }

  render() {
    let { student, uid } = this.props;
    if (isLoaded(student)) {
      student = student[0];
      return (
        <List.Item>
          <List.Item.Meta
            title={
              <Typography.Link
                onClick={() => this.props.callback(student?.phoneNumber)}
              >
                {student?.firstName + " " + student?.lastName}
              </Typography.Link>
            }
            description={
              <>
                {this.getOrderTime()}
                <br />
                {
                  this.props.paymentType &&
                  <>
                    <Text mark>{this.props.paymentType}</Text>
                    <br/>
                  </>
                }
                {this.props.orderId ? (
                  <NavLink
                    to={"/transactions?orderid=" + this.props.orderId}
                    target="_blank"
                    key={1}
                  >
                    View Transactions
                  </NavLink>
                ) : (
                  "No Transactions"
                )}
                <Descriptions size="small">
                  <Descriptions.Item label="Phone">
                    <Typography.Link
                      onClick={() => this.props.callback(student?.phoneNumber)}
                    >
                      {student?.phoneNumber}
                    </Typography.Link>
                  </Descriptions.Item>
                  <Descriptions.Item label="Grade">
                    {student?.gradeName}
                  </Descriptions.Item>
                  <Descriptions.Item label="School">
                    {student?.schoolName}
                  </Descriptions.Item>
                </Descriptions>
              </>
            }
          />
        </List.Item>
      );
    } else {
      return (
        <List.Item>
          <List.Item.Meta title={"Loading..."} description={"..."} />
        </List.Item>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    student: state.firestore.ordered["student_" + props.uid],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "students",
      doc: props.uid,
      storeAs: "student_" + props.uid,
    },
  ])
)(StudentInfoListItem);
