import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
  Upload,
  message,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { Component, createRef } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import CardListItem from "./components/CardListItem";
import { ChromePicker } from "react-color";
import ImgCrop from "antd-img-crop";
import { compose } from "redux";
import { connect } from "react-redux";
import { createClassCard } from "../../store/actions/onlineClass";

const { Option } = Select;

class AddClassCard extends Component {
  state = {
    selectedTeacher: null,
    loading: false,
    imageUrl: null,
    imageFile: null,
    card: {
      amount: 500,
      name: "Card preview",
      description: "This will be the description of the monthly class card",
    },
    colorTopLeft: "#3366FF",
    colorBottomRight: "#00CCFF",
  };
  getFlutterHexFromHexColor = (color) => color.replace("#", "0xFF");

  handleTopLeftColorChange = (color) => {
    let oldCard = this.state.card;
    oldCard.gradientTopLeft = this.getFlutterHexFromHexColor(color.hex);
    this.setState({ card: oldCard, colorTopLeft: color.hex });
  };
  handleBottomRightColorChange = (color) => {
    let oldCard = this.state.card;
    oldCard.gradientBottomRight = this.getFlutterHexFromHexColor(color.hex);
    this.setState({ card: oldCard, colorBottomRight: color.hex });
  };

  handleValueChange = (key, val) => {
    let oldCard = this.state.card;
    oldCard[key] = val;
    this.setState({ card: oldCard });
  };

  handleSubmit = async (form) => {
    if (!this.state.imageFile) return message.warn("Select a cover image");

    this.setState({ loading: true });
    console.log(form);

    await this.props.createClassCard({
      imageFile: this.state.imageFile,
      amount: form.amount,
      isAvailableToPurchase: form.isAvailableToPurchase,
      name: form.name,
      description: form.description,
      categories: form.categories,
      teacherUid: form.teacher,
      type: form.type,
      validFrom: form.validTime[0].format("YYYY MM DD"),
      validTill: form.validTime[1].format("YYYY MM DD"),
      gradientTopLeft: this.state.card.gradientTopLeft,
      gradientBottomRight: this.state.card.gradientBottomRight,
      month: form.month,
      institute: form.institute,
    });

    if (!this.props.addError) {
      message.success("Class Card Created Successfully");
      window.location = "/cards";
    } else {
      message.error("There was an error!");
    }

    this.setState({ loading: false });
  };

  formRef = createRef();

  render() {
    const { teacherUid, teachers, userRole, class_card_categories } =
      this.props;
    const { imageUrl } = this.state;

    if (isLoaded(teacherUid, teachers, userRole)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Class cards"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>

          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Create a new class card"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <div className="flex flex-row mb-4 items-center space-x-4">
                  <ChromePicker
                    color={this.state.colorTopLeft}
                    disableAlpha
                    onChange={this.handleTopLeftColorChange}
                  />
                  <div className="w-72">
                    <CardListItem
                      card={this.state.card}
                      cover={this.state.imageUrl}
                    />
                  </div>
                  <ChromePicker
                    color={this.state.colorBottomRight}
                    disableAlpha
                    onChange={this.handleBottomRightColorChange}
                  />
                </div>

                <Form
                  ref={this.formRef}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="create-class-card"
                  onFinish={this.handleSubmit}
                  validateMessages={{ required: "${label} cannot be empty!" }}
                >
                  <Form.Item
                    name={"cover"}
                    label="Cover Image"
                    className={"question-image-list"}
                  >
                    <ImgCrop aspect={16 / 9} rotate>
                      <Upload
                        accept={".jpg"}
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={(e) => {
                          var urlCreator = window.URL || window.webkitURL;
                          this.setState({
                            imageUrl: urlCreator.createObjectURL(e),
                            imageFile: e,
                          });
                          return false;
                        }}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="cover"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div>
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>

                  {(userRole === "TEACHER" ||
                    userRole === "TEACHER_DATA_ENTRY") && (
                    <Form.Item
                      name={"teacher"}
                      initialValue={teacherUid}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                  )}

                  {userRole === "COMPANY_ADMIN" && (
                    <Form.Item
                      label="Teacher"
                      name="teacher"
                      rules={[{ required: true }]}
                    >
                      <Select
                        allowClear
                        onChange={(value) => {
                          this.setState({
                            selectedTeacher: value,
                          });
                          this.formRef.current.resetFields(["categories"]);
                        }}
                        disabled={this.state.loading}
                        placeholder="Select teacher"
                        style={{ width: "100%" }}
                      >
                        {Object.values(teachers).map((teacher) => (
                          <Select.Option
                            key={teacher.user}
                            value={teacher.user}
                          >
                            {teacher.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Name"
                    name="name"
                    disabled={this.state.loading}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="A name for the card"
                      onChange={(e) =>
                        this.handleValueChange("name", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Month (Optional)"
                    name="month"
                    disabled={this.state.loading}
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder="Month Name"
                      onChange={(e) =>
                        this.handleValueChange("month", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Institute (Optional)"
                    name="institute"
                    disabled={this.state.loading}
                    rules={[{ required: false }]}
                  >
                    <Input
                      placeholder="Institute Name"
                      onChange={(e) =>
                        this.handleValueChange("institute", e.target.value)
                      }
                    />
                  </Form.Item>
                  {userRole === "COMPANY_ADMIN" ? (
                    <Form.Item
                      disabled={this.state.loading}
                      name="categories"
                      label="Categories"
                    >
                      <Select mode="multiple" placeholder="Select Categories">
                        {class_card_categories &&
                          (this.state.selectedTeacher
                            ? class_card_categories
                                .filter(
                                  (i) =>
                                    i.teacherUid === this.state.selectedTeacher
                                )
                                .map((item) => {
                                  return (
                                    <Option value={item.id} key={item.id}>
                                      {item.name}
                                    </Option>
                                  );
                                })
                            : class_card_categories.map((item) => {
                                return (
                                  <Option value={item.id} key={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              }))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item
                      disabled={this.state.loading}
                      name="categories"
                      label="Categories"
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        placeholder="Select Categories"
                        disabled={this.state.loading}
                      >
                        {class_card_categories &&
                          class_card_categories.map((item) => {
                            return (
                              <Option value={item.id} key={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Description"
                    name="description"
                    disabled={this.state.loading}
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="A description for the card"
                      onChange={(e) =>
                        this.handleValueChange("description", e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Type"
                    name="type"
                    disabled={this.state.loading}
                    rules={[{ required: true }]}
                    initialValue="FULL"
                  >
                    <Select disabled={this.state.loading}>
                      <Select.Option value="FULL">FULL</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Valid Time (From, Until)"
                    name="validTime"
                    disabled={this.state.loading}
                    rules={[{ required: true }]}
                  >
                    <DatePicker.RangePicker
                      onChange={(e) => {
                        this.handleValueChange("validFrom", e[0]);
                        this.handleValueChange("validTill", e[1]);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Amount"
                    name="amount"
                    disabled={this.state.loading}
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      onChange={(e) => this.handleValueChange("amount", e)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Is Available To Purchase"
                    name="isAvailableToPurchase"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item colon={false}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                    >
                      Create
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Class cards"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Please wait a while..."
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    uid: state.firebase.auth.uid,
    addError: state.onlineClass.addError,
    teacherUid: routeProps.userTeacherUid,
    teachers: state.firestore.ordered.teachers,
    userRole: routeProps.userRole,
    class_card_categories: state.firestore.ordered.class_card_categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createClassCard: (data) => dispatch(createClassCard(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let queries = [{ collection: "teachers", orderBy: "name" }];

    if (props.userRole === "COMPANY_ADMIN") {
      queries.push({ collection: "class_card_categories" });
    } else {
      queries.push({
        collection: "class_card_categories",
        where: [["teacherUid", "==", props.teacherUid]],
      });
    }

    return queries;
  })
)(AddClassCard);