import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Card, Tag, Button, Skeleton, Typography, Tooltip } from "antd";
import { EyeOutlined, OrderedListOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { aws } from "../../../utils/api";

const { Paragraph, Text } = Typography;

class PreviewLiveSessionCard extends Component {
  state = {
    loading: false,
    bookingCount: null,
  };

  fetchBookingCount = async () => {
    this.setState({
      bookingCount: null,
    });

    const classUid = this.props.classId;
    const liveSessionUid = this.props.liveSession?.id;
    const token = this.props.token;

    if( classUid && liveSessionUid && token ){
      const res = await aws.post(
        "/payments/getBookingCount",
        {
          entityType: 'L',
          entityUid: `${classUid}_${liveSessionUid}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // set count
      const count = res?.data?.data?.count;
      this.setState({
        bookingCount: count ? count : 0,
      })
    }
  }

  componentDidMount() {
    this.fetchBookingCount();
  }

  render() {
    const { attachments, liveSession, classId } = this.props;
    if (!isLoaded(attachments)) {
      return <Card loading hoverable></Card>;
    }

    return (
      <Card
        key={liveSession.id}
        actions={[
          <NavLink
            to={
              "/classes/preview/" +
              classId +
              "/live-sessions/" +
              liveSession.id +
              "/bookings"
            }
            key="bookings"
          >
            <Tooltip title="View bookings">
              <Button className="session-action" type="text" key="preview">
                <OrderedListOutlined />
              </Button>
            </Tooltip>
          </NavLink>,
        ]}
        cover={<img alt="Cover" src={liveSession.coverUrl} />}
        hoverable
      >
        <Skeleton loading={this.state.loading} active>
          <Card.Meta
            style={{ textAlign: "center" }}
            title={
              <Tooltip placement="topLeft" title={liveSession.name}>
                <Text>{liveSession.name}</Text>
              </Tooltip>
            }
          />
          <div style={{ textAlign: "center", margin: "10px 0 10px 0" }}>
            {
              this.state.bookingCount == null &&
              <LoadingOutlined />
            }
            {
              this.state.bookingCount == 0 &&
              <Tag style={{ display: "inline-block" }}>No bookings yet</Tag>
            }
            {
              this.state.bookingCount > 0 &&
              <Tag color="blue">
                Students Booked : {this.state.bookingCount}
              </Tag>
            }

            {
              this.state.bookingCount != null && this.state.bookingCount >= 0 &&
              <ReloadOutlined onClick={this.fetchBookingCount} />
            }
          </div>
          <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: "more" }}>
            {liveSession.description}
          </Paragraph>
        </Skeleton>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    sessions: state.firestore.ordered[props.selected.id + "_live_sessions"],
    classId: props.selected.id,
    token: state.firebase.profile.token.token,
    attachments:
      state.firestore.ordered[
        "classes/" +
          props.selected.id +
          "/live_sessions/" +
          props.liveSession.id +
          "/attachments"
      ],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection:
          "classes/" +
          props.selected.id +
          "/live_sessions/" +
          props.liveSession.id +
          "/attachments",
        orderBy: ["name", "asc"],
      },
    ];
  })
)(PreviewLiveSessionCard);
