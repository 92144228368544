import React, { Component } from "react";
import ClassesTable from "./components/ClassesTable";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Button,
  Skeleton,
  Spin,
  Switch,
  Select,
} from "antd";
import {
  PlusOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { addClass } from "./../../store/actions/onlineClass";
import CategoriesList from "./components/CategoriesList";

class Classes extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    coverFile: null,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  handleCoverChange = (val) => {
    //console.log(val);
    this.setState({ imageUid: undefined });
    if ("response" in val.file) {
      if (val.file.response.success) {
        this.setState({
          imageUid: val.file.response.data[0].uid,
          uploading: false,
        });
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      this.setState({ uploading: true });
    }
    this.setState({ coverFile: val.file });
    // this.setState({ fileList: val.fileList });
  };
  onFinish = async (val) => {
    // console.log({ ...val, cover: this.state.imageUid });

    if (!this.state.imageUid) {
      message.warn("Please select a cover image");
      return;
    }
    if (this.state.uploading) {
      message.warn("Please wait for the image to be uploaded");
      return;
    }
    this.setState({
      loading: true,
    });
    //TODO: set teacher id from custom claim
    await this.props.addClass({
      ...val,
      cover: this.state.imageUid,
    });

    if (!this.props.error) {
      message.success("Class created successfully");
    } else {
      message.error("There was an error creating class");
    }
    this.setState({
      visible: false,
      loading: false,
      imageUid: null,
    });
  };

  render() {
    const {
      classes,
      teachers,
      token,
      userRole,
      userTeacherUid,

      teachers_ordered,
    } = this.props;

    const { imageUid } = this.state;
    if (isLoaded(classes, teachers, userRole, userTeacherUid)) {
      const teacherMappedClasses = classes.map((clz) => {
        return Object.assign({}, clz, {
          teacherName: teachers[clz["teacher"]].name,
        });
      });
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Classes"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All classes"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Button type="primary" onClick={() => this.showModal()}>
                    Add new class
                  </Button>
                }
              >
                <ClassesTable
                  data={teacherMappedClasses}
                  showTeacherColumn={userRole === "COMPANY_ADMIN"}
                />
              </Card>
              <Modal
                centered
                title={"Add a class"}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                okButtonProps={{ loading: this.state.loading }}
                cancelButtonProps={{ disabled: this.state.loading }}
              >
                <Form
                  onFinish={this.onFinish}
                  validateMessages={{ required: "${label} is required!" }}
                  ref={this.formRef}
                  layout="vertical"
                >
                  {(userRole === "TEACHER" ||
                    userRole === "TEACHER_DATA_ENTRY") && (
                    <Form.Item
                      name={"teacher"}
                      initialValue={userTeacherUid}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                  )}

                  {userRole === "COMPANY_ADMIN" && (
                    <Form.Item
                      label="Teacher"
                      name="teacher"
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={this.state.loading}
                        placeholder="Select teacher"
                        style={{ width: "100%" }}
                      >
                        {teachers &&
                          Object.values(teachers).map((teacher) => (
                            <Select.Option
                              key={teacher.user}
                              value={teacher.user}
                            >
                              {teacher.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    name={"cover"}
                    label="Cover Image"
                    // rules={[{ required: true }]}
                    // valuePropName="file"
                    className={"question-image-list"}
                  >
                    <ImgCrop rotate>
                      <Upload
                        accept={".jpg"}
                        listType="picture-card"
                        className="class-cover-uploader"
                        onPreview={this.handlePreview}
                        disabled={this.state.loading || this.state.uploading}
                        onChange={this.handleCoverChange}
                        showUploadList={false}
                        action={
                          process.env.REACT_APP_CLOUDFUNCTIONS_URL +
                          "/api/classes/uploadCover"
                        }
                        headers={{
                          Authorization: "Bearer " + token,
                        }}
                        // action="/revision-live-staging/us-central1/classesApi/classes/cover"
                        // beforeUpload={() => false}
                      >
                        {imageUid ? (
                          <CheckCircleOutlined />
                        ) : (
                          <div>
                            {this.state.uploading ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                  <Form.Item
                    name={"name"}
                    label="Class Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={"description"}
                    label="Class Description"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea />
                  </Form.Item>

                  <Form.Item
                    label="Live Sessions Enabled"
                    name="liveSessionsEnabled"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    label="Live Sessions Only"
                    name="liveSessionOnly"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch />
                  </Form.Item>
                </Form>
              </Modal>
            </Col>
          </Row>
          <CategoriesList
            currrentTeacher={userTeacherUid}
            preview={false}
            teachers={teachers_ordered}
            role={userRole}
          />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Classes"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All classes"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  //console.log(state.firestore);
  return {
    classes: state.firestore.ordered.classes,

    teachers: state.firestore.data.teachers,
    teachers_ordered: state.firestore.ordered.teachers,
    // auth: state.firebase.auth,

    token: state.firebase.profile.token.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClass: (data) => dispatch(addClass(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let queries = [{ collection: "teachers" }];

    if (props.userRole === "COMPANY_ADMIN") {
      queries.push({
        collection: "classes",
        orderBy: ["createdAt", "desc"],
      });
    } else {
      queries.push({
        collection: "classes",
        where: [["teacher", "==", props.userTeacherUid]], //TODO: get teacher id from custom claims
      });
    }

    return queries;
  })
)(Classes);
