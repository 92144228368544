import { message } from "antd";

const initState = {
  addError: null,
  passwordResetError: null,
  toggleDisableError: null,
  createIAMUserError: null,
  bucketPolicyUpdateError: null,
  toggleTeacherUploadStatusError: null,
};

const coord = (state = initState, action) => {
  switch (action.type) {
    case "TEACHER_ADD_SUCCESS":
      return {
        ...state,
        addError: null,
      };
    case "TEACHER_ADD_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        addError: "Teacher add failed",
      };
    case "SEND_PASSWORD_RESET_EMAIL_SUCCESS":
        return {
          ...state,
          passwordResetError: null,
        }
    case "SEND_PASSWORD_RESET_EMAIL_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        passwordResetError: "Password Reset email sending failed!",
      };
    case "ACCOUNT_TOGGLE_DISABLE_SUCCESS":
      return {
        ...state,
        toggleDisableError: null,
      }
    case "ACCOUNT_TOGGLE_DISABLE_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        toggleDisableError: "Toggling disable status failed!",
      };
    case "IAM_USER_CREATE_SUCCESS":
      return {
        ...state,
        createIAMUserError: null,
      }
    case "IAM_USER_CREATE_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        createIAMUserError: "Creating IAM User failed!",
      };
    case "BUCKET_POLICY_UPDATE_SUCCESS":
      return {
        ...state,
        bucketPolicyUpdateError: null,
      }
    case "BUCKET_POLICY_UPDATE_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        bucketPolicyUpdateError: "Updating Bucket Policy failed!",
      };
    case "TOGGLE_TEACHER_UPLOAD_STATUS_SUCCESS":
      return {
        ...state,
        toggleTeacherUploadStatusError: null,
      }
    case "TOGGLE_TEACHER_UPLOAD_STATUS_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        toggleTeacherUploadStatusError: "Toggling teacher upload status error!",
      };
    default:
      return state;
  }
};

export default coord;
