/* eslint-disable react/react-in-jsx-scope */
import { Select } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
const { Option } = Select;

const SelectSessions = ({ sessions, onChange, loading }) => {
  if (isLoaded(sessions)) {
    return (
      <>
        <Select
          showSearch
          allowClear
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={onChange}
          disabled={loading}
          placeholder="Select Entity"
          style={{ width: "100%" }}
        >
          {sessions.map((session) => {
            return (
              <Option key={session.id} value={JSON.stringify(session)}>
                {session.name} - LKR{session.fee}
              </Option>
            );
          })}
        </Select>
      </>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    sessions: state.firestore.ordered.sessions,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "classes",
        doc: props.classId,
        subcollections: [{ collection: "sessions" }],
        storeAs: "sessions",
      },
    ];
  })
)(SelectSessions);
