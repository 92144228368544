import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Avatar,
  Button,
  Skeleton,
  Typography,
  Modal,
  Space,
  message,
  Result,
  Spin,
  Popconfirm,
} from "antd";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  resetPassword,
  sendResetPasswordEmail,
  disconnectZoomAccount,
} from "../../store/actions/auth";
import axios from "axios";

const { Title, Text } = Typography;
const { Meta } = Card;

class Profile extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    zoomConnectPending: false,
    visible: false,
    zoomAuthUrl: null,
  };
  showModal = () => {
    this.props.sendEmail(this.props.profile.email);
    message.success("Reset link is sent to " + this.props.profile.email);
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val) => {
    // console.log(val);
    this.setState({
      loading: true,
    });
    await this.props.resetPassword(val.code, val.newPassword);
    if (!this.props.error) {
      message.success("Password updated successfully");
    } else {
      message.error(
        "There was an error updating password, " + this.props.error
      );
    }
    this.setState({
      loading: false,
    });
  };
  changeName = async (val) => {
    // console.log(val);
    this.setState({
      loading: true,
    });
    // await this.props.resetPassword(val.code, val.newPassword);
    if (!this.props.error) {
      message.success("Name updated successfully");
    } else {
      message.error("There was an error updating name, " + this.props.error);
    }
    this.setState({
      loading: false,
    });
  };

  handleConnectZoomAccount = async () => {
    console.log('Connecting zoom account!');
    this.setState({
      zoomConnectPending: true,
    })

    try {
      const url = await this.getZoomAuthUrl(this.props.userId);
      let newWindow = window.open(url, '_blank', 'noopener');

      if(!newWindow || newWindow.closed || typeof newWindow.closed=='undefined'){ 
        //POPUP BLOCKED
        message.warning("Your browser maybe blocking Pop-Ups. Click the link shown below if the authorization window did not open!", 10);
        this.setState({
          zoomAuthUrl: url,
        })
      }

    } catch (error) {
      message.error(error.message);
    }
    
    this.setState({
      zoomConnectPending: false,
    })
    return true;
  }
  getZoomAuthUrl = async (teacherUid) => {
    var res = await axios.post(
      process.env.REACT_APP_CLOUDFUNCTIONS_URL + "/zoom/auth/generateAuthUrl",
      {
        teacherUid: teacherUid
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
    );
    if (res.status === 200 && res.data.success && res.data.data.url) {
      return res.data.data.url;
    } else {
      throw new Error("Error generating auth url for Zoom!");
    }
  };

  handleDisconnectZoomAccount = async () => {
    console.log('Disconnecting zoom account!');

    this.setState({
      zoomConnectPending: true,
    })

    // update profile status
    await this.props.disconnectZoomAccount(this.props.userRole, this.props.profile);

    this.setState({
      zoomConnectPending: false,
    })
    return true;
  }
  getZoomCardExtraButton = () => {
    if( this.state.zoomConnectPending ){
      return (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      );

    }else if( !this.props.profile.isZoomAccountConnected ){
      return (
        <Button onClick={this.handleConnectZoomAccount}>Connect</Button>
      );

    }else if( this.props.profile.isZoomAccountConnected ){
      return (
        <Popconfirm 
          title="Are you sure？You won't be able to perform Zoom related actions!" 
          icon={ <QuestionCircleOutlined style={{ color: 'red' }} /> }
          onConfirm={this.handleDisconnectZoomAccount}
        >
          <Button>Disconnect</Button>
        </Popconfirm>
      );
    }else{
      return <Text>-</Text>
    }
  }

  render() {
    const { profile, userRole, zoomAccount } = this.props;
    if (isLoaded(profile)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Profile"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Update Profile"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Button onClick={this.showModal}>Change Password</Button>
                }
              >
                <Space>
                  <Text
                    disabled={this.state.loading}
                    // editable={
                    //   this.state.loading
                    //     ? false
                    //     : {
                    //         onChange: (newStr) => this.changeName(newStr, "name"),
                    //       }
                    // }
                  >
                    Name: {profile.name}
                  </Text>
                </Space>

                <Modal
                  centered
                  title={"Password reset email has been sent"}
                  visible={this.state.visible}
                  // onOk={this.handleOk}
                  onOk={this.handleCancel}
                  onCancel={this.handleCancel}
                  destroyOnClose={true}
                  okButtonProps={{ loading: this.state.loading }}
                  cancelButtonProps={{ disabled: this.state.loading }}
                >
                  <Result
                    status="success"
                    title={"Email has been sent to " + this.props.profile.email}
                    subTitle="Check the spam/junk folders also, the email might be in there"
                  />
                  {/* <Form
                    onFinish={this.onFinish}
                    validateMessages={{ required: "${label} is required!" }}
                    ref={this.formRef}
                    layout="vertical"
                  >
                    <Form.Item
                      name="code"
                      label="Confirmation Code"
                      rules={[
                        {
                          required: true,
                          message: "Confirmation code cannot be empty",
                        },
                      ]}
                    >
                      <Input
                        allowClear
                        placeholder="Confirmation code received via an email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="newPassword"
                      label="New Password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Password cannot be empty",
                        },
                        {
                          min: 8,
                          message: "Password must be eight characters Long",
                        },
                        {
                          pattern: /(?=.*\d)/,
                          message: "Contain at least one number(0-9)",
                        },
                        {
                          pattern: /(?=.*[a-z])/,
                          message: "Contain at least one lowercase letter(a-z)",
                        },
                        {
                          pattern: /(?=.*[A-Z])/,
                          message: "Contain at least one uppercase letter(A-Z)",
                        },
                      ]}
                    >
                      <Input.Password
                        allowClear
                        placeholder="Type a new password"
                      />
                    </Form.Item>
                  </Form> */}
                </Modal>
              </Card>
              {
                userRole === 'TEACHER' &&
                <Card
                  title="Zoom Integration"
                  style={{ margin: "1rem 1rem 0 1rem" }}
                  extra={
                    this.getZoomCardExtraButton()
                  }
                >
                    <Row>
                      <Space>
                        <Text>
                          Status: 
                            {
                              profile.isZoomAccountConnected && 
                              <Space>
                                <Text>Connected</Text>
                                <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 20 }}/>
                              </Space>
                            }
                            {
                              !profile.isZoomAccountConnected && 
                              <Space>
                                <Text>Not Connected</Text>
                                <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 20 }}/>
                              </Space>
                            }
                        </Text>
                      </Space>
                      {
                        this.state.zoomAuthUrl && !profile.isZoomAccountConnected &&
                        <Space>
                          <Text>
                            Auth URL: <a target="_blank" href={this.state.zoomAuthUrl}>{this.state.zoomAuthUrl}</a>
                          </Text>
                        </Space>
                      }
                    </Row>

                    {
                      profile.isZoomAccountConnected && 
                      <Row>
                        <Space>
                          <Text>
                            Is Licensed Account: 
                              {
                                profile.hasLicensedZoomAccount && 
                                <Space>
                                  <Text>Yes</Text>
                                  <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 20 }}/>
                                </Space>
                              }
                              {
                                !profile.hasLicensedZoomAccount && 
                                <Space>
                                  <Text>No </Text>
                                  <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 20 }}/>
                                  <Text mark>(You need a Licensed Zoom Account to correctly use Zoom with Viduna LIVE)</Text>
                                </Space>
                              }
                          </Text>
                        </Space>
                      </Row>
                    }

                    {
                      isLoaded(zoomAccount) && profile.isZoomAccountConnected && 
                      <Row style={{marginTop: '40px'}}>
                        <Col span={12} offset={6}>
                          <Card>
                            <Meta
                              avatar={
                                <Avatar 
                                  src={zoomAccount.picUrl ? zoomAccount.picUrl : ""} 
                                  size={{ xs: 32, sm: 48, md: 64, lg: 80, xl: 100, xxl: 120 }}
                                />
                              }
                              title={
                                <Col>
                                  <Row>
                                    <Title level={3}>{`${zoomAccount.firstName} ${zoomAccount.lastName}`}</Title>
                                  </Row>
                                  <Row>
                                    <Text code>{`(${zoomAccount.email})`}</Text>
                                  </Row>
                                  <Row>
                                    <Text type={profile.hasLicensedZoomAccount ? "success" : "danger" } code>{`${zoomAccount.typeName} Account`}</Text>
                                  </Row>
                                </Col>
                              }
                            />
                          </Card>
                        </Col>
                      </Row>
                    }
                </Card>
              }
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Profile"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Profile loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  // console.log(state.firestore.data);
  return {
    profile: state.firestore.data.myProfile,
    zoomAccount: state.firestore.data.zoomAccount,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    error: state.auth.authError,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
    userId: state.firebase.auth.uid,
    token: state.firebase.profile.token.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (code, newPassword) =>
      dispatch(resetPassword(code, newPassword)),
    sendEmail: (email) => dispatch(sendResetPasswordEmail(email)),
    disconnectZoomAccount: (role, data) => dispatch(disconnectZoomAccount(role, data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let ret = [
      {
        collection: props.role === 'TEACHER' ? "teachers":  "users",
        doc: props.auth.uid,
        storeAs: "myProfile",
      }
    ];

    if( props.role === 'TEACHER' && props.profile && props.profile.zoomAccountUserId ){
      ret.push(
        {
          collection: "zoom_accounts",
          doc: props.profile.zoomAccountUserId,
          storeAs: "zoomAccount"
        }
      );
    }
    
    return ret;
  })
)(Profile);
