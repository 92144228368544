import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Image,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { draftPost, editPost, publishPost } from "../../store/actions/post";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import ImageEditModal from "../common/CoverImageEdit";
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useState } from "react";

const { Paragraph, Text, Link } = Typography;

const SinglePostPage = ({
  post,
  postId,
  loaded,
  draftPost,
  publishPost,
  editPost,
}) => {
  const [saving, setSaving] = useState(false);
  const [imageEditVisible, setImageEditVisible] = useState(false);

  if (post != null && loaded) {
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Edit post"
          className="site-page-header"
        ></PageHeader>
        {/* <ImageEditModal
          visible={imageEditVisible}
          onCreate={this.updateImage}
          loading={loading}
          onCancel={this.closeImageEditModal}
        /> */}
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title={
                <div className="class-name">
                  <Text
                    editable={{
                      onChange: (val) => editPost("title", val),
                    }}
                  >
                    {post.title}
                  </Text>
                </div>
              }
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={
                <Button
                  onClick={
                    post?.status === "PUBLISHED" ? draftPost : publishPost
                  }
                  type={post?.status === "PUBLISHED" ? "ghost" : "primary"}
                >
                  {post?.status === "PUBLISHED"
                    ? "Change to a Draft"
                    : "Publish post"}
                </Button>
              }
            >
              <Descriptions>
                <Descriptions.Item label="Post Media" span={3}>
                  {post?.contentType === "IMG" ? (
                    <Image width={200} src={post.contentUrl} />
                  ) : null}
                  {post?.contentType === "VID" ? (
                    <Link
                      editable={{
                        onChange: (val) => editPost("contentUrl", val),
                      }}
                    >
                      {post.contentUrl}
                    </Link>
                  ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Post Description" span={3}>
                  <Paragraph
                    editable={{
                      onChange: (val) => editPost("description", val),
                    }}
                  >
                    {post.description}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item label="Post Type" span={3}>
                  <Select
                    defaultValue={post.contentType ?? "IMG"}
                    disabled={saving}
                    style={{ width: 120 }}
                    onChange={(val) => editPost("contentType", val)}
                  >
                    <Select.Option value="IMG">IMG</Select.Option>
                    <Select.Option value="VID">VID</Select.Option>
                  </Select>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Edit post"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Post is loading..."
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
};

const mapStateToProps = (state, routeProps) => {
  return {
    post: state.firestore.data.selected_post ?? [],
    loaded: isLoaded(state.firestore.data.selected_post),
    postId: routeProps.match.params.postId,
    role: state.firebase.profile.token.claims.role,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  const { postId } = routeProps.match.params;
  return {
    editPost: (key, value) => dispatch(editPost(postId, { key, value })),
    publishPost: () => dispatch(publishPost(postId)),
    draftPost: () => dispatch(draftPost(postId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "posts",
      doc: routeProps.match.params.postId,
      storeAs: "selected_post",
    },
  ])
)(SinglePostPage);
