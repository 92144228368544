import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded, isEmpty } from 'react-redux-firebase'
import LoadingScreen from '../layout/LoadingScreen'

import { Layout } from 'antd';
import SideNav from '../layout/SideNav';
import NotAuthorized from "../layout/NotAuthorized ";

const roles = ["TEACHER", "TEACHER_DATA_ENTRY", "COMPANY_ADMIN"]

class PrivateRoute extends Component {

  render() {
    const auth = this.props.auth;
    const profile = this.props.profile;

    if (isLoaded(auth) && !isEmpty(auth)) {
      if (isLoaded(profile)) {
        if("role" in profile.token.claims && roles.includes(profile.token.claims.role)){
          if(profile.token.claims.role === "TEACHER_DATA_ENTRY" && !("teacherUid" in profile.token.claims)){
            return <NotAuthorized />
          }

          // primary data
          const role = profile.token.claims.role;
          const teacherUid = role === "TEACHER" ? auth.uid : ( role === "TEACHER_DATA_ENTRY" ? profile.token.claims.teacherUid : null );

          return <Layout style={{ minHeight: '100vh' }}>
            <SideNav profile={profile} email={auth.email} />
            <Route {...this.props}
              component={() => <this.props.component userRole={role} userTeacherUid={teacherUid} 
                match={this.props.computedMatch} location={this.props.location} />}
            />
            {/* <Route {...this.props} /> */}
          </Layout>;
        }else{
          return <NotAuthorized />
        }
      }
      else {
        return <LoadingScreen />
      }
    } else {
      return <Redirect to={'/signin'} />;
    }
  }
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(PrivateRoute);