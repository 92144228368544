import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Upload,
  message,
} from "antd";
import React, { Component, useState } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import ImgCrop from "antd-img-crop";
import PostTable from "./components/PostTable";
import { addNewPost } from "../../store/actions/post";
import { compose } from "redux";
import { connect } from "react-redux";

class Posts extends Component {
  state = {
    loading: false,
    addModalVisible: false,
  };
  setAddModalVisible = (val) => {
    this.setState({
      addModalVisible: val,
    });
  };
  onAdd = async (val) => {
    this.setState({
      loading: true,
    });
    // console.log(val);
    await this.props.addNewPost(val);
    if (this.props.addError) {
      message.error("There was an error");
    } else {
      message.success("Post added successfully");
    }
    this.setState({
      loading: false,
      addModalVisible: false,
    });
  };
  render() {
    const { posts, role } = this.props;
    if (isLoaded(posts)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Posts"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Posts"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Button
                    onClick={() => this.setAddModalVisible(true)}
                    type="primary"
                    disabled={this.state.loading}
                  >
                    Add new
                  </Button>
                }
              >
                <PostTable data={posts} role={role} />
              </Card>
            </Col>
          </Row>

          <AddNewModal
            visible={this.state.addModalVisible}
            loading={this.state.loading}
            onOk={this.onAdd}
            onCancel={() => {
              this.setAddModalVisible(false);
            }}
          />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Posts"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Posts"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}
const AddNewModal = ({ visible, onOk, onCancel, loading }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState("IMG");
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  return (
    <Modal
      visible={visible}
      title="Add new post"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        if (type === "IMG" && !file) return message.warn("Select an image");
        form
          .validateFields()
          .then((values) => {
            if (type === "IMG") onOk({ ...values, file });
            else onOk(values);
            form.resetFields();
            setImageUrl(null);
            setFile(null);
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description cannot be empty",
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "Type cannot be empty",
            },
          ]}
          initialValue={type}
        >
          <Select onChange={setType}>
            <Select.Option value="IMG">Image</Select.Option>
            <Select.Option value="VID">Video</Select.Option>
          </Select>
        </Form.Item>

        {type === "IMG" ? (
          <ImgCrop aspect={16 / 9} rotate>
            <Upload
              accept={".jpg"}
              listType="picture-card"
              showUploadList={false}
              beforeUpload={(e) => {
                setFile(e);
                var urlCreator = window.URL || window.webkitURL;
                setImageUrl(urlCreator.createObjectURL(e));
                return false;
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="cover" style={{ width: "100%" }} />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        ) : null}

        {type === "VID" ? (
          <Form.Item
            name="vidUrl"
            label="Youtube Url"
            rules={[
              {
                required: true,
                message: "Url cannot be empty",
              },
            ]}
          >
            <Input placeholder="https://youtube.com/watch?v=CFF0mV24WCX" />
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    posts: state.firestore.ordered.posts,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    addError: state.coord.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewPost: (data) => dispatch(addNewPost(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if( props.userRole === 'COMPANY_ADMIN' ){
      return [
        {
          collection: "posts",
          orderBy: ["createdAt", "desc"],
        },
      ];
    }

    return [
      {
        collection: "posts",
        where: ["ownerUid", "==", props.userTeacherUid],
        orderBy: ["createdAt", "desc"],
      },
    ];    
  })
)(Posts);
