import { message } from "antd";

const initState = {
  addError: null,
};

const post = (state = initState, action) => {
  switch (action.type) {
    case "POST_ADD_SUCCESS":
      return {
        ...state,
        addError: null,
      };
    case "POST_ADD_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        addError: "Post add failed",
      };
    default:
      return state;
  }
};

export default post;
