const initState = {
    error: null,
  };
  
  const support = (state = initState, action) => {
    console.log(action.type, action.err);
    switch (action.type) {
      case "CLOSE_SUCCESS":
        return {
          ...state,
          error: null,
        };
      case "CLOSE_ERROR":
        return {
          ...state,
          error: action.err,
        };
      case "REPLY_SUCCESS":
        return {
          ...state,
          error: null,
        };
      case "REPLY_ERROR":
        return {
          ...state,
          error: action.err,
        };
  
      default:
        return state;
    }
  };
  
  export default support;
  