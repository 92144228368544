const initState = {
    txs: null,
    studentUid: null,
    transaction: null,
    fetchError: null,
  };
  
  const transaction = (state = initState, action) => {
    switch (action.type) {
      case "FETCHED_TX":
        return {
          ...state,
          txs: action.res,
          fetchError: null,
        };
      case "FETCH_ERROR":
        return {
          ...state,
          txs: null,
          fetchError: action.err,
        };
      case "GET_STUDENT_SUCCESS":
        return {
          ...state,
          studentUid: action.res,
          fetchError: null,
        };
      case "GET_STUDENT_ERROR":
        return {
          ...state,
          studentUid: null,
          fetchError: action.err,
        };
      case "GET_TRANSACTION_SUCCESS":
        return {
          ...state,
          transaction: action.res,
          fetchError: null,
        };
      case "GET_TRANSACTION_ERROR":
        return {
          ...state,
          transaction: null,
          fetchError: action.err,
        };
      default:
        return state;
    }
  };
  
  export default transaction;