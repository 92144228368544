import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Spin,
} from "antd";
import React, { Component } from "react";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import Discussions from "./components/Discussions";
import { LoadingOutlined } from "@ant-design/icons";
import Questions from "./components/PreviewQuestions";
import { compose } from "redux";
import { connect } from "react-redux";
import { getExamScoreSheet } from "../../store/actions/paper";
import moment from "moment";

class PreviewPaper extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    downloading: false,
  };
  render() {
    const { exam, examId, getExamScoreSheet } = this.props;
    if (exam != null && isLoaded(exam)) {
      let examData = Object.assign({ id: examId }, exam);
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Paper preview"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={"Paper: " + examData.name}
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Button
                    onClick={async () => {
                      this.setState({ downloading: true });
                      await getExamScoreSheet({
                        paper_name: examData?.name,
                        paper_uid: examId,
                      });
                      this.setState({ downloading: false });
                    }}
                    type="primary"
                    loading={this.state.downloading}
                  >
                    Download Score Sheet
                  </Button>
                }
              >
                <Descriptions size="small" column={3}>
                  <Descriptions.Item label="Name">
                    {examData.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date">
                    {examData.date}
                  </Descriptions.Item>
                  <Descriptions.Item label="Time">
                    {moment(examData.start.toDate()).format("hh:mm A")} -{" "}
                    {moment(examData.end.toDate()).format("hh:mm A")}
                  </Descriptions.Item>
                  <Descriptions.Item label="Medium">
                    {examData.medium}
                  </Descriptions.Item>
                  <Descriptions.Item label="Subject">
                    {examData.subjectName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Teacher">
                    {examData.teacherName}
                  </Descriptions.Item>
                  <Descriptions.Item label="No. of question">
                    {examData.noOfQuestions}
                  </Descriptions.Item>
                  <Descriptions.Item label="No. of answers">
                    {examData.noOfAnswers}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created at">
                    {moment(examData.createdAt.toDate()).format(
                      "dddd, MMM Do YY, hh:mm A"
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Questions exam={examData} />
          <Discussions selected={{ ...exam, id: examId }} />
        </Layout>
      );
    } else if (isEmpty(exam) && isLoaded(exam)) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Paper preview"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Paper doesn't exists"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the paper you've selected does not exist."
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Paper preview"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Paper is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    exams: state.firestore.ordered.exams,
    exam: state.firestore.data.exams
      ? state.firestore.data.exams[routeProps.match.params.id]
      : null,
    examId: routeProps.match.params.id,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    getExamScoreSheet: (data) => dispatch(getExamScoreSheet(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "exams",
      orderBy: ["createdAt", "desc"],
    },
  ])
)(PreviewPaper);
