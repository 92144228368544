import {
  Card,
  Col,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Form,
  Input,
  Radio,
  Button,
  Popconfirm,
  Spin,
  Empty,
  Space,
  Tag,
  Modal,
  message,
} from "antd";

import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  bookSession,
  getSessionBookings,
  getUserFromPhone,
  resetVideoPlay,
} from "../../store/actions/booking";
import ClassCardsSelect from "./components/ClassCardsSelect";
import SelectSessions from "./components/SelectSessions";

const { Option } = Select;

const bookingTypes = [
  { label: "Session", value: "S", selected: true },
  // { label: "Zoom Session", value: "L" },
  { label: "Class Card", value: "C" },
  // { label: "Live Streaming", value: "LS" },
];

class ManualBooking extends Component {
  state = {
    searching: false,
    loading: false,
    selectedTeacher: null,
    selectedClass: null,
    selectedType: null,
    selectedSession: null,
    selectedClassCard: null,
    selectedUser: null,
    entityAmount: null,
    query: "+94",
    filter: false,
    currentPage: 1,
    pageSize: 10,
    loadingBookings: false,
    modalBookVisible: false,
    bookConfirmVisible: false,
  };

  setBookVisible = (val) => {
    this.setState({
      modalBookVisible: val,
    });
  };

  setBookConfirmVisible = (val) => {
    this.setState({
      bookConfirmVisible: val,
    });
  };

  onKeyPress = async ({ keyCode }) => {
    switch (keyCode) {
      case 27: //Esc
        if (this.state.filter) this.clearFilter();
        break;
      case 81: //q
        if (
          this.state.filter &&
          !this.state.modalBookVisible &&
          !this.state.modalTimeVisible
        )
          this.setBookVisible(true);
        break;
      case 65: //a
        if (
          this.state.filter &&
          !this.state.modalTimeVisible &&
          !this.state.modalBookVisible
        )
          this.setTimeVisible(true);
        break;
      case 66: //b
        if (
          this.state.filter &&
          !this.state.bookConfirmVisible &&
          !this.state.alreadyBooked
        ) {
          const btn = document.getElementById("bookbtn");
          btn.click(); //FIXME: do this in a better way!!
        }
        break;
      case 89: //y
        if (this.state.filter && this.state.bookConfirmVisible)
          this.addBooking(true);
        break;
      case 78: //n
        if (this.state.filter && this.state.bookConfirmVisible)
          this.setBookConfirmVisible(false);
        break;

      default:
        break;
    }
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyPress);
    if (this.props.teacherUid) {
      this.setState({
        selectedTeacher: this.props.teacherUid,
      });
    }
    this.loadBookings();
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyPress);
  }

  loadBookings = async () => {
    this.setState({
      loadingBookings: true,
    });

    let entityUid;

    // query
    const entityType = this.state.selectedType;
    if (this.state.selectedType === "S") {
      entityUid = `${this.state.selectedClass}_${this.state.selectedSession}`;
    }
    if (this.state.selectedType === "C") {
      entityUid = `${this.state.selectedClassCard}`;
    }

    const query = this.state.query;
    const userUid = this.props.user?.id; // this could exist even when cleared

    const currentPage = this.state.currentPage;
    const pageSize = this.state.pageSize;

    // load bookings
    const hasUser = query && query !== "+94" && userUid;

    await this.props.getSessionBookings({
      entityType: entityType,
      entityUid: entityUid,
      userUid: hasUser ? userUid : null,
      page: currentPage,
      size: pageSize,
    });
    const alreadyBooked = !hasUser ? false : this.props.bookings.length > 0;

    // set filter values
    this.setState({
      filter: hasUser,
      alreadyBooked: alreadyBooked,
      loadingBookings: false,
    });
  };

  updateQuery = (e) => {
    if (e.target.value.trim().includes("+94")) {
      this.setState({ query: e.target.value });
    } else {
      this.setState({ query: "+94" });
    }
  };

  addBooking = async () => {
    this.setBookConfirmVisible(false);
    this.setState({ searching: true, loading: true });

    let orderId;

    const timestamp = new Date().getTime();
    if (this.state.selectedType === "S") {
      orderId = `${this.state.selectedType}_${this.state.selectedClass}_${this.state.selectedSession}_${this.props.user?.id}_${timestamp}`;
    }

    if (this.state.selectedType === "C") {
      orderId = `${this.state.selectedType}_${this.state.selectedClassCard}_${this.props.user?.id}`;
    }

    await this.props.bookSession(
      orderId,
      this.state.entityAmount,
      "Initial Booking"
    );
    if (this.props.bookError) {
      message.error(this.props.bookError);
    } else {
      message.success("Booking was successful");
      this.clearFilter();
    }
    this.setState({ searching: false, loading: false });
  };

  bookAgain = async (values) => {
    this.setState({ searching: true, loading: true });

    const timestamp = new Date().getTime();
    let orderId;

    if (this.state.selectedType === "S") {
      orderId = `${this.state.selectedType}_${this.state.selectedClass}_${this.state.selectedSession}_${this.props.user?.id}_${timestamp}`;
    }

    if (this.state.selectedType === "C") {
      orderId = `${this.state.selectedType}_${this.state.selectedClassCard}_${this.props.user?.id}`;
    }

    await this.props.bookSession(
      orderId,
      this.state.entityAmount,
      "Initial Booking"
    );
    if (this.props.bookError) {
      message.error(this.props.bookError);
    } else {
      message.success("Booking was successful");
      this.clearFilter();
    }
    this.setState({
      searching: false,
      loading: false,
      modalBookVisible: false,
    });
  };

  searchForUser = async (phone) => {
    this.setState({ searching: true });
    await this.props.getUserFromPhone(phone);
    await this.loadBookings();
    this.setState({ searching: false });
  };

  setFilter = (phone) => {
    this.setState({
      query: phone,
    });
    this.searchForUser(phone);
  };

  clearFilter = () => {
    this.setState({
      filter: false,
      alreadyBooked: false,
      query: "+94",
    });
    this.loadBookings();
  };
  render() {
    const { teachers, teacherUid, userRole, classes, user } = this.props;
    const selectedUser = this.state.filter ? user : null;
    if (
      userRole === "COMPANY_ADMIN"
        ? isLoaded(teachers, classes)
        : isLoaded(classes)
    ) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            className="site-page-header"
            title="Manual Booking"
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Create a manual booking"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="create-manual-booking"
                  validateMessages={{ required: "${label} cannot be empty!" }}
                >
                  {(userRole === "TEACHER" ||
                    userRole === "TEACHER_DATA_ENTRY") && (
                    <Form.Item hidden initialValue={teacherUid} name="teacher">
                      <Input hidden />
                    </Form.Item>
                  )}

                  {userRole === "COMPANY_ADMIN" && (
                    <Form.Item
                      label="Teacher"
                      name="teacher"
                      rules={[{ required: true }]}
                    >
                      <Select
                        value={this.state.selectedTeacher}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        onChange={(value) => {
                          this.setState({
                            selectedTeacher: value,
                          });
                        }}
                        disabled={this.state.loading}
                        placeholder="Select teacher"
                        style={{ width: "100%" }}
                      >
                        {Object.values(teachers).map((teacher) => (
                          <Option key={teacher.user} value={teacher.user}>
                            {teacher.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Class Name"
                    name="classUid"
                    rules={[{ required: true }]}
                  >
                    <Select
                      value={this.state.selectedClass}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value) => {
                        this.setState({
                          selectedClass: value,
                        });
                      }}
                      disabled={this.state.loading}
                      placeholder="Select Class"
                      style={{ width: "100%" }}
                    >
                      {this.state.selectedTeacher
                        ? classes
                            .filter(
                              (i) => i.teacher === this.state.selectedTeacher
                            )
                            .map((classItem) => (
                              <Option key={classItem.id} value={classItem.id}>
                                {classItem.name}
                              </Option>
                            ))
                        : teacherUid
                        ? classes
                            .filter((i) => i.teacher === teacherUid)
                            .map((classItem) => (
                              <Option key={classItem.id} value={classItem.id}>
                                {classItem.name}
                              </Option>
                            ))
                        : null}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Select Entity Type">
                    <Space>
                      {bookingTypes.map((item) => {
                        const isActive = item.value === this.state.selectedType;
                        return (
                          <Button
                            type={isActive ? "primary" : "default"}
                            onClick={() => {
                              if (!isActive) {
                                this.setState({
                                  selectedType: item.value,
                                });
                              } else {
                                this.setState({
                                  selectedType: null,
                                });
                              }
                            }}
                          >
                            {item.label}
                          </Button>
                        );
                      })}
                    </Space>
                  </Form.Item>

                  {this.state.selectedClass && this.state.selectedType === "S" && (
                    <Form.Item
                      disabled={this.state.loading}
                      rules={[{ required: true }]}
                      label="Select Entity"
                      name="entityUid"
                    >
                      <SelectSessions
                        loading={this.state.loading}
                        onChange={(value) => {
                          const session = JSON.parse(value);
                          this.setState(
                            {
                              selectedSession: session.id,
                              entityAmount: session.fee,
                            },
                            () => {
                              this.loadBookings();
                            }
                          );
                        }}
                        classId={this.state.selectedClass}
                      />
                    </Form.Item>
                  )}

                  {this.state.selectedClass && this.state.selectedType === "C" && (
                    <Form.Item
                      disabled={this.state.loading}
                      rules={[{ required: true }]}
                      label="Select Class Card"
                      name="entityUid"
                    >
                      <ClassCardsSelect
                        loading={this.state.loading}
                        onChange={(value) => {
                          const classCard = JSON.parse(value);

                          this.setState(
                            {
                              selectedClassCard: classCard.id,
                              entityAmount: classCard.amount,
                            },
                            () => {
                              this.loadBookings();
                            }
                          );
                        }}
                        teacherUid={this.state.selectedTeacher}
                      />
                    </Form.Item>
                  )}

                  <CustomModal
                    loading={this.state.loading}
                    visible={this.state.modalBookVisible}
                    onCreate={this.bookAgain}
                    onCancel={() => {
                      this.setBookVisible(false);
                    }}
                    title={"Booking again"}
                    okText={"Book"}
                    itemName={"reason"}
                    itemLabel={"Provide a reason for this booking"}
                  />

                  <Input.Search
                    placeholder="+94123456789"
                    onChange={this.updateQuery}
                    value={this.state.query}
                    loading={this.state.searching}
                    onSearch={(phone) => this.searchForUser(phone)}
                    maxLength={12}
                    style={{ marginBottom: "10px" }}
                  />
                  {selectedUser !== null && selectedUser !== undefined ? (
                    this.state.searching ? (
                      <Spin>
                        <UserPreview user={selectedUser} />
                      </Spin>
                    ) : (
                      <UserPreview
                        user={selectedUser}
                        btn={
                          this.state.alreadyBooked ? (
                            <Space direction="vertical">
                              <Tag color="green">Already Booked</Tag>
                              <Button
                                onClick={() => {
                                  this.setBookVisible(true);
                                }}
                              >
                                Book Again[Q]
                              </Button>
                            </Space>
                          ) : (
                            <Popconfirm
                              title="Please confirm before adding"
                              onConfirm={this.addBooking}
                              onCancel={() => {
                                this.setBookConfirmVisible(false);
                              }}
                              okText="[Y]es"
                              cancelText="[N]o"
                              style={{ float: "right" }}
                            >
                              <Button
                                id="bookbtn"
                                type="primary"
                                onClick={() => this.setBookConfirmVisible(true)}
                              >
                                [B]ook
                              </Button>
                            </Popconfirm>
                          )
                        }
                      />
                    )
                  ) : this.state.searching ? (
                    <Spin>
                      <Empty description={"Searching for a user"} />
                    </Spin>
                  ) : user !== null ? (
                    <Empty description={"No user found"} />
                  ) : null}
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Manual Booking"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    bookings: state.booking.sessionBookings
      ? state.booking.sessionBookings
      : [],
    teachers: state.firestore.ordered.teachers,
    teacherUid: props.userTeacherUid,
    classes: state.firestore.ordered.classes,
    user: state.booking.users ? state.booking.users.docs[0] : null,
    bookError: state.booking.bookError,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserFromPhone: (phone) => dispatch(getUserFromPhone(phone)),
    getSessionBookings: (data) => dispatch(getSessionBookings(data)),
    bookSession: (orderId, amount, reason) =>
      dispatch(
        bookSession({
          orderId: orderId,
          amount: amount,
          reason: reason,
        })
      ),
    resetVideoPlay: (userUid, note) =>
      dispatch(
        resetVideoPlay({
          class_uid: props.match.params.classId,
          session_uid: props.match.params.sessionId,
          student_uid: userUid,
          note: note,
        })
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    const query = [];
    if (props.userRole === "COMPANY_ADMIN") {
      query.push(
        {
          collection: "teachers",
        },
        {
          collection: "classes",
          where: ["isActive", "==", true],
        }
      );
    } else {
      query.push({
        collection: "classes",
        where: [
          ["teacher", "==", props.userTeacherUid],
          ["isActive", "==", true],
        ],
      });
    }

    return query;
  })
)(ManualBooking);

const UserPreview = (props) => {
  const { user, btn } = props;
  return (
    <Card>
      <Row>
        <Col flex="auto">
          <p style={{ marginBottom: ".1em", fontWeight: "bold" }}>
            {user.data().firstName + " " + user.data().lastName}
          </p>
          <p style={{ marginBottom: ".1em" }}>
            {user.data().gradeName + ", " + user.data().streamName}
          </p>
          <p style={{ marginBottom: ".1em" }}>{user.data().schoolName}</p>
        </Col>
        <Col>{btn}</Col>
      </Row>
    </Card>
  );
};

const CustomModal = ({
  loading,
  visible,
  onCreate,
  onCancel,
  title,
  okText,
  itemName,
  itemLabel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name={itemName}
          label={itemLabel}
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
