import {
  Avatar,
  Card,
  Col,
  Layout,
  PageHeader,
  Radio,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
  Switch,
  Select,
  Descriptions,
  message,
} from "antd";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { Component, useState } from "react";
import { editClassCover, editClass } from "./../../store/actions/onlineClass";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import ImageEditModal from "../common/CoverImageEdit";
import SessionCategoryList from "./components/SessionsCategoryList";
import Sessions from "./components/Sessions";
import LiveSessions from "./components/LiveSessions";
import { compose } from "redux";
import { connect } from "react-redux";

const { Paragraph, Text } = Typography;
const { Option } = Select;

const types = [
  { label: "Video Session", value: "session" },
  { label: "Zoom Sessions", value: "live" },
];

class EditClass extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    updating: false,
    ImageEditVisible: false,
    type: "session", // "session" and "live" are the possible values
    selectedCategoryId: null,
    selectedCategoryName: null,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onTypeChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };
  selectCategory = (id, name) => {
    this.setState({
      selectedCategoryId: id,
      selectedCategoryName: name,
    });
  };

  openImageEditModal = () => {
    this.setState({
      ImageEditVisible: true,
    });
  };
  closeImageEditModal = () => {
    this.setState({
      ImageEditVisible: false,
    });
  };

  updateImage = async (newImageFile) => {
    this.setState({
      loading: true,
    });

    await this.props.editClassCover({ file: newImageFile });

    this.setState({
      loading: false,
    });
    this.closeImageEditModal();
  };

  handleEditClass = async (field, value) => {
    this.setState({
      updating: true,
    });
    await this.props.editClass(field, value);

    if (!this.props.error) {
      message.success("Session updated successfully");
    } else {
      message.error("There was an error updating session");
    }
    this.setState({
      updating: false,
    });
  };

  render() {
    const { selected, classId, role, categories, class_categories } =
      this.props;

    if (
      selected != null &&
      isLoaded(selected, categories) &&
      selected.id === classId
    ) {
      console.log({ class_categories, categories });
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit class"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <ImageEditModal
            visible={this.state.ImageEditVisible}
            onCreate={this.updateImage}
            loading={this.state.loading}
            onCancel={this.closeImageEditModal}
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      editable={{
                        onChange: (value) =>
                          this.handleEditClass("name", value),
                      }}
                    >
                      {selected.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <div onClick={this.openImageEditModal}>
                    <Avatar shape="square" src={selected.cover} />
                  </div>
                }
              >
                <Row>
                  <Col span={8}>
                    <div>
                      Active :{" "}
                      <Switch
                        defaultChecked={selected.isActive}
                        onChange={(checked) =>
                          this.handleEditClass("isActive", checked)
                        }
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      Live Sessions Enabled :{" "}
                      <Switch
                        defaultChecked={selected.liveSessionsEnabled}
                        onChange={(checked) =>
                          this.handleEditClass("liveSessionsEnabled", checked)
                        }
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      Live Sessions Only :{" "}
                      <Switch
                        defaultChecked={selected.liveSessionsOnly}
                        onChange={(checked) =>
                          this.handleEditClass("liveSessionsOnly", checked)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "15px" }}>
                  <Space>
                    <div>
                      <div>Description :</div>
                      <Paragraph
                        editable={{
                          onChange: (value) =>
                            this.handleEditClass("description", value),
                        }}
                      >
                        {selected.description}
                      </Paragraph>
                    </div>
                  </Space>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Space direction="vertical">
                    <div>
                      Class Categories:
                      <Select
                        disabled={this.state.updating}
                        style={{ width: 300, marginLeft: 5 }}
                        placeholder={"Select Categories"}
                        mode="multiple"
                        allowClear={false}
                        value={selected.categories ?? []}
                        onChange={(val) => {
                          this.handleEditClass("categories", val);
                        }}
                      >
                        {class_categories.map((item) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Space>
                </Row>
              </Card>

              <Row>
                <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
                  <div style={{ margin: "1rem 1rem 0 1rem" }}>
                    <Radio.Group
                      options={types}
                      onChange={this.onTypeChange}
                      optionType="button"
                      buttonStyle="solid"
                      value={this.state.type}
                    />
                  </div>
                </Col>
              </Row>

              {this.state.type === "session" ? (
                <>
                  <SessionCategoryList
                    data={categories}
                    onCategorySelect={this.selectCategory}
                    selectedCategoryId={this.state.selectedCategoryId}
                    classId={classId}
                    preview={false}
                  />
                  <Sessions
                    selectedCategory={{
                      name: this.state.selectedCategoryName,
                      id: this.state.selectedCategoryId,
                    }}
                    selected={{ ...selected, id: classId }}
                  />
                </>
              ) : null}

              {this.state.type === "live" ? (
                <LiveSessions selected={{ ...selected, id: classId }} />
              ) : null}
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit class"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Class is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    categories: state.firestore.ordered.selected_class_categories ?? [],
    class_categories: state.firestore.ordered.class_categories ?? [],
    selected: state.firestore.ordered.selected_class
      ? state.firestore.ordered.selected_class[0]
      : state.firestore.data.selected_class,
    classId: routeProps.match.params.id,
    role: routeProps.userRole,
    error: state.onlineClass.error,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  var classId = routeProps.match.params.id;
  return {
    editClassCover: (data) => dispatch(editClassCover(data, classId)),
    editClass: (field, value) => dispatch(editClass(classId, field, value)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => {
    const out = [
      {
        collection: "classes",
        doc: routeProps.match.params.id,
        subcollections: [{ collection: "categories" }],
        storeAs: "selected_class_categories",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.id,
        storeAs: "selected_class",
      },
    ];

    if (routeProps.selected) {
      out.push({
        collection: "class_categories",
        where: [["teacherUid", "==", routeProps.selected.teacher]],
      });
    }

    return out;
  })
)(EditClass);
