import {
  Button,
  Image,
  Input,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { DeleteOutlined, KeyOutlined } from "@ant-design/icons";
import React, { Component, useState } from "react";
import { deleteFaq, deleteFaqField, editFaq } from "../../../store/actions/faq";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sortAlphabetically } from "../../../utils/helpers";

class FaqTable extends Component {
  render() {
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        width: "90%",
        sorter: (a, b) => sortAlphabetically(a.title, b.title),
        render: (_, record) => (
          <Typography.Text
            editable={{
              onChange: (newStr) => {
                this.props.editFaq(record.id, "title", newStr);
              },
            }}
          >
            {record.title}
          </Typography.Text>
        ),
      },
      {
        title: "Action",
        key: "operation",
        render: (_, record) => {
          return (
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => this.props.deleteFaq(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          );
        },
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.data}
          rowKey="id"
          expandable={{
            expandedRowRender: (record) => (
              <>
                <Typography.Text
                  style={{ display: "block", marginBottom: "1rem" }}
                  editable={{
                    onChange: (newStr) => {
                      this.props.editFaq(record.id, "body", newStr);
                    },
                  }}
                >
                  {record.body}
                </Typography.Text>
                {record.imageUrl ? (
                  <Image
                    style={{ display: "block" }}
                    src={record.imageUrl}
                    width={100}
                  />
                ) : null}
                <br />
                {record.imageUrl ? (
                  <Space style={{ marginBottom: "1rem" }}>
                    <Typography.Text
                      editable={{
                        onChange: (newStr) => {
                          this.props.editFaq(record.id, "imageUrl", newStr);
                        },
                      }}
                    >
                      {record.imageUrl}
                    </Typography.Text>
                    <Popconfirm
                      title="Are you sure to delete?"
                      onConfirm={() =>
                        this.props.deleteFaqField(record.id, "imageUrl")
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Typography.Link>
                        <DeleteOutlined />
                      </Typography.Link>
                    </Popconfirm>
                  </Space>
                ) : (
                  <NewUrlInput
                    onClickAdd={(newStr) => {
                      this.props.editFaq(record.id, "imageUrl", newStr);
                    }}
                    onClickDelete={() => {
                      this.props.deleteFaqField(record.id, "imageUrl");
                    }}
                    placeholder="Image URL"
                  />
                )}
                <br />
                {record.ytUrl ? (
                  <Space style={{ marginBottom: "1rem" }}>
                    <Typography.Text
                      style={{ display: "block" }}
                      editable={{
                        onChange: (newStr) => {
                          this.props.editFaq(record.id, "ytUrl", newStr);
                        },
                      }}
                    >
                      {record.ytUrl}
                    </Typography.Text>
                    <Popconfirm
                      title="Are you sure to delete?"
                      onConfirm={() =>
                        this.props.deleteFaqField(record.id, "ytUrl")
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Typography.Link>
                        <DeleteOutlined />
                      </Typography.Link>
                    </Popconfirm>
                  </Space>
                ) : (
                  <NewUrlInput
                    style={{ display: "block" }}
                    onClickAdd={(newStr) => {
                      this.props.editFaq(record.id, "ytUrl", newStr);
                    }}
                    placeholder="Youtube video URL"
                  />
                )}
              </>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editFaq: (docId, key, value) => dispatch(editFaq(docId, key, value)),
    deleteFaq: (docId) => dispatch(deleteFaq(docId)),
    deleteFaqField: (docId, key) => dispatch(deleteFaqField(docId, key)),
  };
};

const NewUrlInput = ({ placeholder, onClickAdd }) => {
  const [newUrl, setNewUrl] = useState("");
  return (
    <Space style={{ marginBottom: "1rem", width: "100%" }}>
      <Input
        onChange={(e) => setNewUrl(e.target.value)}
        type="url"
        placeholder={placeholder}
      />
      <Button
        disabled={!newUrl}
        type="default"
        onClick={() => onClickAdd(newUrl)}
      >
        Add
      </Button>
    </Space>
  );
};

export default connect(null, mapDispatchToProps)(FaqTable);
