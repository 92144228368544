import { removeEmpty } from "../../utils/helpers";

export const addNewFaq = (data) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();

    // cleans object by removing null
    let cleanData = removeEmpty(data);

    try {
      const res = await firestore.collection("faq").add({
        ...cleanData,
        createdBy: state.firebase.auth.uid,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });

      dispatch({ type: "FAQ_ADD_SUCCESS", res: "" });
    } catch (err) {
      dispatch({ type: "FAQ_ADD_ERROR", err });
    }
  };
};

export const editFaq = (docId, key, value) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    try {
      const res = await firestore
        .collection("faq")
        .doc(docId)
        .update({
          [key]: value,
          updatedBy: state.firebase.auth.uid,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        });

      dispatch({ type: "FAQ_EDIT_SUCCESS", res: "" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "FAQ_EDIT_ERROR", err });
    }
  };
};

export const deleteFaq = (docId, key) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    try {
      const res = await firestore.collection("faq").doc(docId).delete();

      dispatch({ type: "FAQ_EDIT_SUCCESS", res: "" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "FAQ_EDIT_ERROR", err });
    }
  };
};

export const deleteFaqField = (docId, key) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    try {
      const res = await firestore
        .collection("faq")
        .doc(docId)
        .update({
          [key]: firestore.FieldValue.delete(),
          updatedBy: state.firebase.auth.uid,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        });

      dispatch({ type: "FAQ_EDIT_SUCCESS", res: "" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "FAQ_EDIT_ERROR", err });
    }
  };
};