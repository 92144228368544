import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const OnlyLoggedOut = props => {
  const { uid } = props.auth;
  if (uid) {
    return <Redirect to={'/'} />
  } else {
    return <Route {...props} />
  }
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(OnlyLoggedOut);

