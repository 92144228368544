import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Skeleton,
  Typography,
  Avatar,
  Result,
  Table,
  Button,
  message,
  Spin,
  BackTop,
  Affix,
  Tooltip,
} from "antd";
import { bookExam, getUserFromPhone } from "../../store/actions/booking";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import * as XLSX from "xlsx";

const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const errorIcon = (
  <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 24 }} />
);
const successIcon = (
  <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />
);
const warnIcon = (
  <ExclamationCircleTwoTone twoToneColor="#faad14" style={{ fontSize: 24 }} />
);

class PaperBookingsImport extends Component {
  state = {
    data: [],
    columns: [],
    importStarted: false,
    lastRecord: -1,
  };

  // process CSV data
  processData = (dataString) => {
    this.setState({
      data: [],
      columns: [],
      importStarted: false,
      lastRecord: -1,
    });
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    let isPhoneColumnAvailable = false;
    // prepare columns list from headers
    const columns = headers.map((c) => {
      if (c === "PhoneNumber") isPhoneColumnAvailable = true;
      return {
        title: c,
        dataIndex: c,
      };
    });

    if (!isPhoneColumnAvailable) {
      message.error(
        "The csv file doesn't contain a column named 'PhoneNumber'"
      );
      return;
    }
    columns.push({
      title: "Status",
      dataIndex: "status_b_rl",
    });
    this.setState({ data: list, columns });
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  checkForExistingBooking(bookings, user) {
    bookings.map((booking) => {
      if (this.props.user.id === booking.student_uid) {
        return true;
      }
    });
    return false;
  }

  startImport = async () => {
    await this.setState({ importStarted: true });

    let { data, lastRecord } = this.state;
    let { bookings } = this.props;
    let len = data.length;

    for (let i = lastRecord + 1; i < len; i++) {
      //console.log(data[i]);
      data[i]["status_b_rl"] = <Spin indicator={spinIcon} />;
      await this.setState({ data });
      await this.props.getUserFromPhone(data[i].PhoneNumber);
      if (this.props.user && isLoaded(bookings)) {
        // if (this.checkForExistingBooking(bookings, this.props.user)) {
        //   data[i]["status_b_rl"] = (
        //     <Tooltip title="Already has a booking">{warnIcon}</Tooltip>
        //   );
        //   continue;
        // }

        await this.props.bookExam(this.props.user.id, this.props.exam.fee);
        if (this.props.bookError) {
          data[i]["status_b_rl"] = (
            <Tooltip title={this.props.bookError.message}>{errorIcon}</Tooltip>
          );
        } else {
          data[i]["status_b_rl"] = successIcon;
        }
      } else {
        data[i]["status_b_rl"] = (
          <Tooltip title="User not found!">{errorIcon}</Tooltip>
        );
      }

      await this.setState({ data });
      if (!this.state.importStarted) {
        await this.setState({ lastRecord: i, importStarted: false });
        break;
      }
    }
  };

  // handle file upload
  handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      this.processData(data);
    };
    try {
      reader.readAsBinaryString(file);
    } catch (error) {
      //console.log(error);
    }
  };
  render() {
    const { exam, bookings } = this.props;
    const { data, columns, importStarted } = this.state;

    if (
      bookings != null &&
      isLoaded(bookings) &&
      exam != null &&
      isLoaded(exam)
    ) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Import bookings"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={"Import from csv"}
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Affix offsetTop={20}>
                    <>
                      <Button
                        onClick={this.startImport}
                        disabled={data.length === 0 || importStarted}
                        key={1}
                        type="primary"
                      >
                        Start
                      </Button>
                      <Button
                        disabled={data.length === 0 || !importStarted}
                        onClick={() => this.setState({ importStarted: false })}
                        key={2}
                      >
                        Stop
                      </Button>
                    </>
                  </Affix>
                }
              >
                <input
                  type="file"
                  accept=".csv"
                  onChange={this.handleFileUpload}
                />
                <BackTop />
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey={"PhoneNumber"}
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Import bookings"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Data is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    exam: state.firestore.ordered.selected_exam
      ? state.firestore.ordered.selected_exam[0]
      : null,
    bookings: state.firestore.ordered.selected_exam_bookings,
    user: state.booking.users ? state.booking.users.docs[0] : null,
    bookError: state.booking.bookError,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserFromPhone: (phone) => dispatch(getUserFromPhone(phone)),
    bookExam: (userUid, amount) =>
      dispatch(
        bookExam({
          examUid: props.match.params.id,
          userUid: userUid,
          amount: amount,
          reason: "Bulk import booking!",
        })
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "exams",
      doc: routeProps.match.params.id,
      storeAs: "selected_exam",
    },
    {
      collection: "exams",
      doc: routeProps.match.params.id,
      subcollections: [
        {
          collection: "booked_students",
          orderBy: ["booked_time", "desc"],
        },
      ],
      storeAs: "selected_exam_bookings",
    },
  ])
)(PaperBookingsImport);
