import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Button,
  Skeleton,
  List,
  Pagination,
  Typography,
  Result,
  Descriptions,
  message,
  Modal,
  Form,
  Input,
} from "antd";
import { NavLink } from "react-router-dom";
import TeacherListItem from "./components/TeacherListItem";
import CoordsListItem from "./components/CoordsListItem";
import { addNewCoord } from "../../store/actions/coord";

class TeacherSingle extends Component {
  state = {
    loading: false,
    addModalVisible: false,
  };
  setAddModalVisible = (val) => {
    this.setState({
      addModalVisible: val,
    });
  };
  onAdd = async (val) => {
    this.setState({
      loading: true,
    });
    await this.props.addNewCoord({
      email: val.email,
      name: val.name,
      password: val.password,
      teacherUid: this.props.uid,
    });
    if (this.props.addError) {
      message.error("There was an error");
    } else {
      message.success("User added successfully");
    }
    this.setState({
      loading: false,
      addModalVisible: false,
    });
  };
  render() {
    let { selected, coords } = this.props;
    if (isLoaded(selected, coords) && selected.length > 0) {
      selected = selected[0];
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Teacher"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card title="Teacher info" style={{ margin: "1rem 1rem 0 1rem" }}>
                <Descriptions size="small" column={3}>
                  <Descriptions.Item label="Name">
                    {selected.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Subject">
                    {selected.subjectName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tagline">
                    {selected.tagline}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All Coordinators"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Button
                    onClick={() => this.setAddModalVisible(true)}
                    type="primary"
                    disabled={this.state.loading}
                  >
                    Add new
                  </Button>
                }
              >
                <List
                  itemLayout="horizontal"
                  dataSource={coords}
                  renderItem={(coord) => (
                    <CoordsListItem uid={coord.id} coord={coord} />
                  )}
                />
              </Card>
            </Col>
          </Row>
          <AddNewModal
            visible={this.state.addModalVisible}
            onOk={this.onAdd}
            onCancel={() => {
              this.setAddModalVisible(false);
            }}
          />
        </Layout>
      );
    } else if (isLoaded(selected, coords) && selected.length === 0) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="404"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Teacher not found"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the teacher you've selected does not exist."
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Teacher"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card title="Loading..." style={{ margin: "1rem 1rem 0 1rem" }}>
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}
const AddNewModal = ({ visible, onOk, onCancel, loading }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Add new coordinator"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      destroyOnClose={true}
      okButtonProps={{ loading: loading, disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onOk(values);
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="email"
          label="Email address"
          rules={[
            {
              required: true,
              message: "Email cannot be empty",
            },
            { type: "email", message: "Invalid email" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password cannot be empty",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state, props) => {
  return {
    coords: state.firestore.ordered["coords_" + props.match.params.uid],
    selected: state.firestore.ordered.selected,
    auth: state.firebase.auth,
    uid: props.match.params.uid,
    addError: state.coord.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { addNewCoord: (data) => dispatch(addNewCoord(data)) };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "teachers",
      doc: props.match.params.uid,
      storeAs: "selected",
    },
    {
      collection: "users",
      where: ["teacherUid", "==", props.match.params.uid],
      storeAs: "coords_" + props.match.params.uid,
    },
  ])
)(TeacherSingle);
