import fileDownload from "js-file-download";

export const getLiveSessionBookings = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post("/payments/getBookings", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      const records = res?.data?.data?.records;
      // console.log(res);
      dispatch({ type: "GET_LIVE_BOOKINGS_SUCCESS", res: records });
    } catch (err) {
      dispatch({ type: "GET_LIVE_BOOKINGS_ERROR", err });
    }
  };
};

export const getSessionBookings = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post("/payments/getBookings", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      const records = res?.data?.data?.records;
      // console.log(res);
      dispatch({ type: "GET_SESSION_BOOKINGS_SUCCESS", res: records });
    } catch (err) {
      dispatch({ type: "GET_SESSION_BOOKINGS_ERROR", err });
    }
  };
};

export const getClassCardBookings = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post("/payments/getBookings", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      const records = res?.data?.data?.records;
      // console.log(res);
      dispatch({ type: "GET_CLASS_BOOKINGS_SUCCESS", res: records });
    } catch (err) {
      dispatch({ type: "GET_CLASS_BOOKINGS_ERROR", err });
    }
  };
};

export const bookLiveSession = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post(
        "/payments/createManualPayment",
        {
          orderId: data.orderId,
          amount: data.amount,
          reason: data.reason,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );

      console.log(res);
      if (res.data.success) {
        dispatch({ type: "BOOK_SUCCESS", res });
      } else {
        dispatch({ type: "BOOK_ERROR", err: res.data.message });
      }
      // console.log(res);
      // dispatch({ type: "BOOK_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "BOOK_ERROR", err });
    }
  };
};

export const bookSession = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post(
        "/payments/createManualPayment",
        {
          orderId: data.orderId,
          amount: data.amount,
          reason: data.reason,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      // console.log(res);
      dispatch({ type: "BOOK_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "BOOK_ERROR", err });
    }
  };
};

export const bookClassCard = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post(
        "/payments/createManualPayment",
        {
          orderId: data.orderId,
          amount: data.amount,
          reason: data.reason,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "BOOK_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "BOOK_ERROR", err });
    }
  };
};

export const getTeachersClasses = (teacher_uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      const res = await firestore
        .collection("classes")
        .where("teacher", "==", teacher_uid)
        .get();
      dispatch({ type: "FETCHED_CLASSES", res });
    } catch (err) {
      dispatch({ type: "FETCH_ERROR", err });
    }
  };
};

export const getUserFromPhone = (phone) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      const res = await firestore
        .collection("students")
        .where("phoneNumber", "==", phone)
        .get();
      dispatch({ type: "FETCHED_USERS", res });
    } catch (err) {
      dispatch({ type: "FETCH_ERROR", err });
    }
  };
};
export const exportLiveSessionBookedStudentDetails = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/students/exportLiveSessionBookedStudentDetails",
        Object.assign(
          {},
          { class_uid: data.class_uid },
          { session_uid: data.session_uid },
          { type: data.type },
          data.from ? { from: data.from } : null,
          data.to ? { to: data.to } : null
        ),
        {
          headers: {
            Authorization: "Bearer " + state.booking.adminToken,
          },
        }
      );

      var fileName = `${data.class_name}_${data.session_name}_${
        data.type
      }_${new Date().getTime()}`;
      if (data.from) {
        fileName = `${fileName}_${data.from
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }
      if (data.to) {
        fileName = `${fileName}_${data.to
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }

      fileDownload(res.data, `${fileName}.csv`);
      dispatch({ type: "EXPORT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EXPORT_ERROR", err });
    }
  };
};

export const resetVideoPlay = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/students/clearSessionPlayStarted",
        {
          class_uid: data.class_uid,
          session_uid: data.session_uid,
          student_uid: data.student_uid,
          note: data.note,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "RESET_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "RESET_ERROR", err });
    }
  };
};

export const exportSessionBookedStudentDetails = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/students/exportSessionBookedStudentDetails",
        Object.assign(
          {},
          { class_uid: data.class_uid },
          { session_uid: data.session_uid },
          { type: data.type },
          data.from ? { from: data.from } : null,
          data.to ? { to: data.to } : null
        ),
        {
          headers: {
            Authorization: "Bearer " + state.booking.adminToken,
          },
        }
      );

      var fileName = `${data.class_name}_${data.session_name}_${
        data.type
      }_${new Date().getTime()}`;
      if (data.from) {
        fileName = `${fileName}_${data.from
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }
      if (data.to) {
        fileName = `${fileName}_${data.to
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }

      fileDownload(res.data, `${fileName}.csv`);
      dispatch({ type: "EXPORT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EXPORT_ERROR", err });
    }
  };
};

export const cancelManualBooking = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/payments/cancelManualBooking",
        {
          transaction_uid: data.class_uid,
          reason: data.session_uid,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "CANCEL_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "CANCEL_ERROR", err });
    }
  };
};

export const exportClassCardBookedStudentDetails = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/students/exportClassCardBookedStudentDetails",
        Object.assign(
          {},
          { class_card_uid: data.class_card_uid },
          { type: data.type },
          data.from ? { from: data.from } : null,
          data.to ? { to: data.to } : null
        ),
        {
          headers: {
            Authorization: "Bearer " + state.booking.adminToken,
          },
        }
      );

      var fileName = `${data.class_card_name}_${
        data.type
      }_${new Date().getTime()}`;
      if (data.from) {
        fileName = `${fileName}_${data.from
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }
      if (data.to) {
        fileName = `${fileName}_${data.to
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }

      fileDownload(res.data, `${fileName}.csv`);
      dispatch({ type: "EXPORT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EXPORT_ERROR", err });
    }
  };
};

export const bookExam = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/payments/bookExamManual",
        {
          examUid: data.examUid,
          userUid: data.userUid,
          amount: data.amount,
        },
        {
          headers: {
            Authorization: "Bearer " + state.booking.adminToken,
          },
        }
      );
      dispatch({ type: "BOOK_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "BOOK_ERROR", err });
    }
  };
};

export const exportExamBookedStudentDetails = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/students/exportExamBookedStudentDetails",
        Object.assign(
          {},
          { paper_uid: data.paper_uid },
          { type: data.type },
          data.from ? { from: data.from } : null,
          data.to ? { to: data.to } : null
        ),
        {
          headers: {
            Authorization: "Bearer " + state.booking.adminToken,
          },
        }
      );

      var fileName = `${data.paper_name}_${data.type}_${new Date().getTime()}`;
      if (data.from) {
        fileName = `${fileName}_${data.from
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }
      if (data.to) {
        fileName = `${fileName}_${data.to
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }

      fileDownload(res.data, `${fileName}.csv`);
      dispatch({ type: "EXPORT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EXPORT_ERROR", err });
    }
  };
};
