import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Upload,
  message,
} from "antd";
import React, { Component, useState } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import FaqTable from "./components/FaqTable";
import ImgCrop from "antd-img-crop";
import { addNewFaq } from "../../store/actions/faq";
import { compose } from "redux";
import { connect } from "react-redux";

class Faq extends Component {
  state = {
    loading: false,
    addModalVisible: false,
  };
  setAddModalVisible = (val) => {
    this.setState({
      addModalVisible: val,
    });
  };
  onAdd = async (val) => {
    this.setState({
      loading: true,
    });

    await this.props.addNewFaq(val);
    if (this.props.addError) {
      message.error("There was an error");
    } else {
      message.success("New FAQ added successfully");
    }
    this.setState({
      loading: false,
      addModalVisible: false,
    });
  };
  render() {
    const { faq, role } = this.props;
    if (isLoaded(faq)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Frequently asked questions"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Frequently asked questions"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Button
                    onClick={() => this.setAddModalVisible(true)}
                    type="primary"
                    disabled={this.state.loading}
                  >
                    Add new
                  </Button>
                }
              >
                <FaqTable data={faq} role={role} />
              </Card>
            </Col>
          </Row>

          <AddNewModal
            visible={this.state.addModalVisible}
            loading={this.state.loading}
            onOk={this.onAdd}
            onCancel={() => {
              this.setAddModalVisible(false);
            }}
          />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Frequently asked questions"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Frequently asked questions"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}
const AddNewModal = ({ visible, onOk, onCancel, loading }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState("IMG");
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  return (
    <Modal
      visible={visible}
      title="Add new faq"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
            form.resetFields();
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Title cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="body"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description cannot be empty",
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        {/* {type === "IMG" ? (
          <ImgCrop aspect={16 / 9} rotate>
            <Upload
              accept={".jpg"}
              listType="picture-card"
              showUploadList={false}
              beforeUpload={(e) => {
                setFile(e);
                var urlCreator = window.URL || window.webkitURL;
                setImageUrl(urlCreator.createObjectURL(e));
                return false;
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="cover" style={{ width: "100%" }} />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        ) : null} */}

        <Form.Item name="imageUrl" label="Image Url">
          <Input placeholder="https://example.com/photo.jpg" />
        </Form.Item>
        <Form.Item name="ytUrl" label="Youtube Video Url">
          <Input placeholder="https://youtube.com/watch?v=CFF0mV24WCX" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  return {
    faq: state.firestore.ordered.faq,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    addError: state.coord.addError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewFaq: (data) => dispatch(addNewFaq(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => {
    return [
      {
        collection: "faq",
        orderBy: "title",
      },
    ];
  })
)(Faq);
