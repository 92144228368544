import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
  Upload,
  message,
} from "antd";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import Question from "./Question";
import { addQuestion } from "../../../store/actions/paper";
import { compose } from "redux";
import { connect } from "react-redux";

class Questions extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    file: null,
    curIndex: -1,
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  showModal = (idx) => {
    this.setState({
      curIndex: idx ? idx : 1,
      visible: true,
    });
  };
  handleOk = (e) => {
    this.formRef.current.submit();
  };

  handleCancel = (e) => {
    if (!this.state.loading)
      this.setState({
        visible: false,
        curIndex: -1,
      });
  };
  onFinish = async (val) => {
    //  console.log(val);
    this.setState({
      loading: true,
    });
    await this.props.addQuestion(this.props.exam.id, val);
    if (!this.props.error) {
      message.success("Question added successfully");
    } else {
      message.error("There was an error adding question");
    }
    this.setState({
      visible: false,
      loading: false,
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  render() {
    const { questions, exam } = this.props;
    let qHolder = [];
    const modal = (
      <>
        <Modal
          centered
          title={"Add question " + this.state.curIndex}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          okButtonProps={{ loading: this.state.loading }}
          cancelButtonProps={{ disabled: this.state.loading }}
        >
          <Form
            onFinish={this.onFinish}
            validateMessages={{ required: "${label} is required!" }}
            ref={this.formRef}
            layout="vertical"
          >
            <Form.Item
              name={"index"}
              label="Question Index"
              initialValue={this.state.curIndex}
              rules={[{ required: true }]}
              hidden
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name={"elements"}
              label="Question Image(s)"
              rules={[{ required: true }]}
              className={"question-image-list"}
              valuePropName="file"
            >
              <Upload.Dragger
                beforeUpload={() => false}
                listType="picture"
                onPreview={this.handlePreview}
                disabled={this.state.loading}
                accept={".jpg,.jpeg,.png"}
              >
                <p className="ant-upload-drag-icon">
                  <PlusOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Questions with multiple images, add them in order
                </p>
              </Upload.Dragger>
            </Form.Item>
            <Divider orientation="left" plain>
              Answers
            </Divider>
            <Form.List name="answers">
              {(fields, { add, remove }) => {
                let size = this.props.exam.noOfAnswers
                  ? this.props.exam.noOfAnswers
                  : 1;
                if (fields.length < size) {
                  for (let i = 0; i < size; i++) {
                    fields.push({
                      fieldKey: i,
                      isListField: true,
                      key: i,
                      name: i,
                    });
                  }
                }
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Space
                        key={field.key}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 4,
                        }}
                        align="center"
                        className="question-answers"
                      >
                        <Form.Item
                          {...field}
                          style={{ width: 320 }}
                          name={[field.name, "value"]}
                          label={"Answer " + (index + 1)}
                          fieldKey={[field.fieldKey, "value"]}
                          initialValue={
                            "<rev-sin>ms<s;=r " + (index + 1) + "</rev-sin>"
                          }
                          rules={[{ required: true }]}
                        >
                          <Input placeholder="Answer" disabled={true} />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "isCorrect"]}
                          label="Is Correct"
                          fieldKey={[field.fieldKey, "isCorrect"]}
                          rules={[{ required: true }]}
                          initialValue={false}
                          valuePropName="checked"
                        >
                          <Switch
                            disabled={this.state.loading}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                          />
                        </Form.Item>
                      </Space>
                    ))}
                  </div>
                );
              }}
            </Form.List>
          </Form>
        </Modal>
        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={() =>
            this.setState({
              previewVisible: false,
            })
          }
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </>
    );
    if (!isLoaded(questions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Questions"
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      );
    }
    if (isEmpty(questions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Questions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Empty
                description={<span>There are no questions to be found</span>}
              >
                <Button type="primary" onClick={() => this.showModal()}>
                  Add new
                </Button>
              </Empty>
            </Card>
          </Col>
          {modal}
        </Row>
      );
    }
    for (let index = 0; index < exam.noOfQuestions; index++) {
      qHolder.push({
        id: -1,
        index: index + 1,
      });
    }
    for (let question of questions) {
      qHolder[question.index - 1] = question;
    }
    return (
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
          <Card title="Questions" style={{ margin: "1rem 1rem 0 1rem" }}>
            <Row gutter={[16, 16]}>
              {qHolder.map((question) => {
                return (
                  <Col span={4} key={question.index}>
                    {question.id !== -1 ? (
                      <Question question={question} exam={this.props.exam} />
                    ) : (
                      <Card
                        className={"empty-question-box"}
                        onClick={() => this.showModal(question.index)}
                      >
                        <div>
                          <PlusOutlined style={{ fontSize: 32 }} />
                        </div>
                        <Typography.Text type="secondary">
                          {"Question " + question.index}
                        </Typography.Text>
                      </Card>
                    )}
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
        {modal}
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    questions: state.firestore.ordered[props.exam.id + "_questions"],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addQuestion: (paperId, question) =>
      dispatch(addQuestion(paperId, question)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "exams",
        doc: props.exam.id,
        subcollections: [
          { collection: "questions", orderBy: ["index", "asc"] },
        ],
        storeAs: props.exam.id + "_questions",
      },
    ];
  })
)(Questions);
