import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import {
  Layout,
  PageHeader,
  Spin,
  Row,
  Col,
  Card,
  Typography,
  Result,
  Skeleton,
  Tooltip,
  Descriptions,
  Button,
  Avatar,
  List,
  Form,
  Input,
  Divider,
  message,
  Tag,
  Space,
  Modal,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { closeTicket, reply } from "../../../store/actions/support";

const { Paragraph, Text } = Typography;
const { TextArea } = Input;

class TicketSingle extends Component {
  formRef = React.createRef();
  formClosedReason = React.createRef();
  state = {
    visible: false,
    loading: false,
    closing: false,
    visibleCloseConfirmModal: false,
  };
  closeTicket = async () => {
    this.setState({
      closing: true,
    });
    await this.props.closeTicket(this.props.match.params.id);
    this.setState({
      closing: false,
    });
  };
  handleSubmit = async (val) => {
    this.setState({
      loading: true,
    });
    await this.props.reply(
      this.props.match.params.id,
      val.message,
      this.props.role
    );
    if (!this.props.error) {
      //clear the text box
    }

    document.getElementById("ticket-reply").reset();
    this.setState({
      loading: false,
    });
  };

  handleSubmitOfClosedReason = async (val) => {
    this.setState({
      loading: true,
    });
    await this.props.closeTicket(this.props.match.params.id, val.reason);
    this.setState({
      loading: false,
      visibleCloseConfirmModal: false,
    });
  };

  openCloseConfirmModal = () => {
    this.setState({
      visibleCloseConfirmModal: true,
    });
  };

  handleOk = (e) => {
    this.formClosedReason.current.submit();
  };

  handleCancel = (e) => {
    if (this.state.loading || this.state.closing) return;
    this.setState({
      visibleCloseConfirmModal: false,
    });
  };
  render() {
    const { selected, topic, user, conversation } = this.props;
    const ticketId = this.props.match.params.id;
    if (
      selected != null &&
      isLoaded(selected, topic, user, conversation) &&
      selected.id === ticketId &&
      topic.id === selected.topic &&
      user.id === selected.userUid
    ) {
      console.log(user);
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Support ticket"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <Space>
                    <Text>{topic.title}</Text>
                    <Tag
                      color={selected.status === "CLOSED" ? "green" : "blue"}
                    >
                      {selected.status}
                    </Tag>
                  </Space>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Tooltip title={user.firstName + " " + user.lastName}>
                    <Avatar
                      shape="square"
                      style={{
                        backgroundColor: "#7265e6",
                      }}
                    >
                      {user.firstName[0] + user.lastName[0]}
                    </Avatar>
                  </Tooltip>
                }
              >
                <Descriptions title="User Info">
                  <Descriptions.Item label="UserName">
                    {user.firstName + " " + user.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {user.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone">
                    {user.phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="Grade">
                    {user.gradeName}
                  </Descriptions.Item>
                  {user.streamName ? (
                    <Descriptions.Item label="Stream">
                      {user.streamName}
                    </Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label="Medium">
                    {user.medium}
                  </Descriptions.Item>
                  <Descriptions.Item label="District">
                    {user.district}
                  </Descriptions.Item>
                  <Descriptions.Item label="School">
                    {user.schoolName}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text>Conversation</Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={
                  <Button
                    type="primary"
                    loading={this.state.closing}
                    disabled={selected.status === "CLOSED"}
                    onClick={this.openCloseConfirmModal}
                  >
                    Close Ticket
                  </Button>
                }
              >
                <Modal
                  title="Give the reason for closing the ticket"
                  visible={this.state.visibleCloseConfirmModal}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  destroyOnClose={true}
                  okButtonProps={{ loading: this.state.loading }}
                  cancelButtonProps={{ disabled: this.state.loading }}
                >
                  <Form
                    onFinish={this.handleSubmitOfClosedReason}
                    validateMessages={{ required: "${label} is required!" }}
                    ref={this.formClosedReason}
                    layout="vertical"
                  >
                    <Form.Item
                      name="reason"
                      rules={[{ required: true }]}
                      autoFocus={true}
                    >
                      <TextArea
                        rows={4}
                        disabled={selected.status === "CLOSED"}
                      />
                    </Form.Item>
                  </Form>
                </Modal>
                <List.Item
                  style={{
                    borderBottom: isEmpty(conversation)
                      ? "0px"
                      : "1px solid #f0f0f0",
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        shape="square"
                        style={{
                          backgroundColor: "#7265e6",
                        }}
                      >
                        {user.firstName[0] + user.lastName[0]}
                      </Avatar>
                    }
                    title={user.firstName + " " + user.lastName}
                    description={
                      <Paragraph
                        ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                      >
                        {selected.message}
                      </Paragraph>
                    }
                  />
                  <Text type="secondary">
                    {moment(selected.createdAt.toDate()).fromNow()}
                  </Text>
                </List.Item>
                <List
                  locale={{ emptyText: "No messages after initial request" }}
                  itemLayout="horizontal"
                  dataSource={conversation}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            shape="square"
                            style={{
                              backgroundColor:
                                item.sender === "STAFF" ? "grey" : "#7265e6",
                            }}
                          >
                            {item.sender === "STAFF"
                              ? "Staff"
                              : user.firstName[0] + user.lastName[0]}
                          </Avatar>
                        }
                        title={
                          item.sender === "STAFF"
                            ? "Support Staff"
                            : user.firstName + " " + user.lastName
                        }
                        description={item.message}
                      />
                      <Text type="secondary">
                        {moment(item.createdAt.toDate()).fromNow()}
                      </Text>
                    </List.Item>
                  )}
                />
                <Divider />
                <Form
                  name="ticket-reply"
                  id="ticket-reply"
                  onFinish={this.handleSubmit}
                  key={this.formRef}
                >
                  <Form.Item
                    name="message"
                    rules={[{ required: true }]}
                    autoFocus={true}
                  >
                    <TextArea
                      rows={4}
                      disabled={selected.status === "CLOSED"}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      loading={this.loading}
                      type="primary"
                      disabled={selected.status === "CLOSED"}
                    >
                      Reply
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Support ticket"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Ticket is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.firestore.ordered.selected_ticket
      ? state.firestore.ordered.selected_ticket[0]
      : state.firestore.data.selected_ticket,
    topic: state.firestore.ordered.selected_topic
      ? state.firestore.ordered.selected_topic[0]
      : state.firestore.data.selected_topic,
    user: state.firestore.ordered.selected_user
      ? state.firestore.ordered.selected_user[0]
      : state.firestore.data.selected_user,
    conversation: state.firestore.ordered.selected_ticket_conversation,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    error: state.support.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeTicket: (ticketId, reason) => dispatch(closeTicket(ticketId, reason)),
    reply: (ticketId, message, role) =>
      dispatch(reply(ticketId, message, role)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "support_tickets",
      doc: props.match.params.id,
      storeAs: "selected_ticket",
    },
    {
      collection: "support_topics",
      doc: props.selected ? props.selected.topic : "",
      storeAs: "selected_topic",
    },
    {
      collection: "students",
      doc: props.selected ? props.selected.userUid : "",
      storeAs: "selected_user",
    },
    {
      collection: "support_tickets",
      doc: props.match.params.id,
      subcollections: [
        {
          collection: "conversation",
          orderBy: ["createdAt", "asc"],
        },
      ],
      storeAs: "selected_ticket_conversation",
    },
  ])
)(TicketSingle);
