import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Layout,
  PageHeader,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
  Upload,
  message,
  Tag,
  Tooltip,
  Popconfirm,
  Tabs,
} from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  UploadOutlined,
  PlayCircleOutlined,
  KeyOutlined,
  DeleteOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";
import {
  approveVideo,
  delAttachment,
  editSession,
  delSession,
  editSessionCover,
} from "../../store/actions/onlineClass";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import ImageEditModal from "../common/CoverImageEdit";

import axios from "axios";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { getVideoStatus } from "../../utils/getStatus";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Gallery from "react-fine-uploader";

// ...or load this specific CSS file using a <link> tag in your document
import "react-fine-uploader/gallery/gallery.css";
import VideoPlayer from "../player/VDOPlayer";
import { withRouter } from "react-router-dom";
import ImportUrl from "./components/ImportUrl";

const { Paragraph, Text } = Typography;

class EditSession extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    approving: false,
    videoPreviewLoading: false,
    showPreview: false,
    previewUrl: "",
    previewKey: "",
    month: null,
    uploadCred: null,
    importUrlData: null,
    statusLoading: false,
    otp: null,
    playbackInfo: null,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  deleteSession = async () => {
    this.setState({
      loading: true,
    });
    await this.props.delSession(this.props.classId, this.props.sessionId);
    this.setState({
      loading: false,
    });
    this.props.history.goBack();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val, field) => {
    this.setState({
      loading: true,
    });
    if (this.props.session[field] !== val) {
      let data = {
        classUid: this.props.classId,
        sessionUid: this.props.sessionId,
      };
      data[field] = val;
      await this.props.editSession(data);

      if (!this.props.error) {
        message.success("Session updated successfully");
      } else {
        message.error("There was an error updating session");
      }
    }
    this.setState({
      visible: false,
      loading: false,
    });
  };

  deleteAttachment = async (file) => {
    this.setState({
      uploading: true,
    });
    if (!file.url) {
      message.warning("Something isn't right, please reload the page.");
      return;
    }
    await this.props.delAttachment({
      classUid: this.props.classId,
      sessionUid: this.props.sessionId,
      attachmentUid: file.uid,
    });
    message.success("Attachment deleted successfully");
    this.setState({
      uploading: false,
    });
  };

  approveVideo = async () => {
    this.setState({
      approving: true,
    });

    await this.props.approveVideo(
      this.props.session.teacherUid,
      this.props.classId,
      this.props.sessionId
    );
    message.success("Successfully publisheds the video");

    this.setState({
      approving: false,
    });
  };

  initiatePreview = async () => {
    var res = await axios.post(
      process.env.REACT_APP_CLOUDFUNCTIONS_URL +
        "/api/vdo/getVideoPlaybackAdmin",
      {
        id: this.props.session.videoId,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
    );
    if (res.status === 200 && res.data?.success) {
      this.setState({
        showPreview: true,
        otp: res.data?.data?.otp,
        playbackInfo: res.data?.data?.playbackInfo,
      });
    } else {
      message.error("Error retrieving media");
    }
  };

  openImageEditModal = () => {
    this.setState({
      ImageEditVisible: true,
    });
  };
  closeImageEditModal = () => {
    this.setState({
      ImageEditVisible: false,
    });
  };

  updateImage = (newImageFile) => {
    this.props.editSessionCover({
      classUid: this.props.classId,
      sessionUid: this.props.sessionId,
      file: newImageFile,
    });
    this.closeImageEditModal();
  };

  onChangeMonth = (val) => {
    this.setState({
      month: val,
    });
  };

  getClassCard = () => {
    const classCards = this.props.class_cards;
    if (!classCards) {
      return [];
    }

    if (!this.state.month) {
      return classCards;
    }

    let firstDayOfMonth = this.state.month.clone().startOf("month");
    let endOfMonth = this.state.month.clone().endOf("month");

    var filtered = classCards.filter((classCard) => {
      var validFrom = moment(classCard.validFrom?.toDate());
      var validTill = moment(classCard.validTill?.toDate());

      return (
        validFrom.isSameOrAfter(firstDayOfMonth) &&
        validTill.isSameOrBefore(endOfMonth)
      );
    });

    return filtered;
  };

  getContentId = () => {
    // <base64("<VIDEOTYPE>_<CLASS_ID>_<SESSION_ID>")>
    const contentId = `SESSION_${this.props.classId}_${this.props.sessionId}`;

    if (window.btoa) {
      return btoa(contentId);
    }

    return "";
  };

  obtainUploadCredentials = async (id) => {
    this.setState({
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_CLOUDFUNCTIONS_URL +
          `/api/vdo/obtainUploadCredentials/${id}`,
        {
          headers: {
            Authorization: "Bearer " + this.props.profile.token.token,
          },
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
          uploadCred: res.data.data,
        });
        message.success("Successfully obtained upload credentials!");
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        message.error("Error occured, obtaining credentials");
      });
  };

  uploadVideo = async (
    type,
    classId,
    sessionId,
    { title = null, url = null }
  ) => {
    this.setState({
      loading: true,
    });

    if (!title && !url) {
      return message.error("Invalid upload type!");
    }

    axios
      .post(
        process.env.REACT_APP_CLOUDFUNCTIONS_URL + `/api/vdo/upload`,
        {
          type,
          title: title,
          url: url,
          classId,
          sessionId,
        },
        {
          headers: {
            Authorization: "Bearer " + this.props.profile.token.token,
          },
        }
      )
      .then((res) => {
        if (type === "OBTAIN_UPLOAD") {
          this.setState({
            loading: false,
            uploadCred: res.data.data.uploadCred?.clientPayload,
            type: res.data.data.type,
          });
          message.success("Successfully obtained upload credentials!");
        } else if (type === "IMPORT_URL") {
          this.setState({
            loading: false,
            importUrlData: res.data.data.importUrlData,
            type: res.data.data.type,
          });
          message.success("Successfully url imported!");
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
        message.error("Error occured");
      });
  };

  onClickPreviewVideo = async () => {
    this.setState({
      videoPreviewLoading: true,
    });

    await this.initiatePreview();

    this.setState({
      videoPreviewLoading: false,
    });
  };

  render() {
    let fileList = [];
    const {
      session,
      classId,
      sessionId,
      attachments,
      class_cards,
      categories,
    } = this.props;

    if (
      session != null &&
      isLoaded(session, attachments, class_cards, categories)
    ) {
      for (let attachment of attachments) {
        fileList.push({
          uid: attachment.id,
          name: attachment.name,
          url: attachment.url,
          status: "done",
        });
      }

      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit session"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <ImageEditModal
            visible={this.state.ImageEditVisible}
            onCreate={this.updateImage}
            loading={this.state.loading}
            onCancel={this.closeImageEditModal}
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "name"),
                            }
                      }
                    >
                      {session.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <Space>
                    <Avatar shape="square" src={session.coverUrl} />
                    <EditOutlined onClick={this.openImageEditModal} />
                  </Space>
                }
              >
                {session.type === "YOUTUBE" ? (
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Typography.Title level={4}>
                        Session Details
                      </Typography.Title>
                      <div className="session-video-container">
                        <iframe
                          className="session-video-iframe"
                          src={
                            "https://www.youtube.com/embed/" +
                            session.videoUrl?.split("?v=")[1]
                          }
                          title="Video"
                        ></iframe>
                      </div>
                    </Col>
                  </Row>
                ) : null}
                {!session.type || session.type === "DEFAULT" ? (
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Typography.Title level={4}>
                        Session Details
                      </Typography.Title>

                      <Space style={{ margin: "20px 0px" }}>
                        <p>Preview: </p>

                        {session.videoStatus === "pending" && (
                          <p>
                            Video has not been uploaded yet{" "}
                            <a
                              style={{ color: "#1890ff" }}
                              href="#upload-video"
                            >
                              Upload now
                            </a>
                          </p>
                        )}
                        {!this.state.videoPreviewLoading &&
                          session.videoStatus === "ready" &&
                          !this.state.showPreview && (
                            <PlayCircleOutlined
                              onClick={this.onClickPreviewVideo}
                              style={{
                                fontSize: "80px",
                                color: "#08c",
                                margin: "20px 10px",
                              }}
                            />
                          )}

                        {this.state.videoPreviewLoading && (
                          <LoadingOutlined
                            style={{ fontSize: "50px", margin: "20px 10px" }}
                          />
                        )}

                        {this.state.showPreview && (
                          <div style={{ minWidth: 600, position: "relative" }}>
                            <VideoPlayer
                              otp={this.state.otp}
                              playbackInfo={this.state.playbackInfo}
                            />
                          </div>
                        )}
                      </Space>
                    </Col>
                  </Row>
                ) : null}
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Descriptions>
                      {!session.type ||
                      session.type === "DEFAULT" ||
                      session.type === "YOUTUBE" ? (
                        <Descriptions.Item
                          label={
                            session.isPublished ? "Status" : "Publish session"
                          }
                          span={3}
                        >
                          {session.videoStatus === "ready" ? (
                            <Button
                              disabled={
                                session.isPublished || this.state.approving
                              }
                              type="primary"
                              onClick={() =>
                                !session.isPublished &&
                                session.videoStatus === "ready"
                                  ? this.approveVideo()
                                  : null
                              }
                            >
                              {session.videoStatus === "processing"
                                ? "Processing..."
                                : "Publish"}
                              {this.state.approving && (
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{ fontSize: 24 }}
                                      spin
                                    />
                                  }
                                />
                              )}
                            </Button>
                          ) : (
                            <p>Video not ready for publish</p>
                          )}
                        </Descriptions.Item>
                      ) : null}

                      <Descriptions.Item span={3} label="Video Status">
                        <Tag color={getVideoStatus(session.videoStatus).color}>
                          {getVideoStatus(session.videoStatus).title}
                        </Tag>
                      </Descriptions.Item>

                      <Descriptions.Item label="Session Type" span={3}>
                        <Select
                          defaultValue={session.type ? session.type : "DEFAULT"}
                          disabled={true}
                          style={{ width: 120 }}
                          onChange={(val) => this.onFinish(val, "type")}
                        >
                          <Select.Option value="DEFAULT">DEFAULT</Select.Option>
                          <Select.Option value="YOUTUBE">YOUTUBE</Select.Option>
                        </Select>
                      </Descriptions.Item>

                      {session.type === "YOUTUBE" && (
                        <Descriptions.Item
                          label={"Session Video Url:"}
                          span={3}
                        >
                          <Text
                            copyable
                            disabled={this.state.loading}
                            editable={
                              this.state.loading
                                ? false
                                : {
                                    onChange: (newStr) =>
                                      this.onFinish(newStr, "videoUrl"),
                                  }
                            }
                          >
                            {session.videoUrl}
                          </Text>
                        </Descriptions.Item>
                      )}

                      <Descriptions.Item label="Sub Title" span={3}>
                        <Text
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "subHeading"),
                                }
                          }
                        >
                          {session.subHeading}
                        </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Fee" span={3}>
                        <Text
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(parseInt(newStr), "fee"),
                                }
                          }
                        >
                          {session.fee.toString()}
                        </Text>
                      </Descriptions.Item>

                      <Descriptions.Item label="Class Cards" span={3}>
                        <Form layout="inline">
                          <Form.Item style={{ width: "300px" }}>
                            <DatePicker
                              onChange={this.onChangeMonth}
                              picker="month"
                            />
                          </Form.Item>
                          <Form.Item
                            name="class_cards"
                            style={{ width: "300px" }}
                            initialValue={session.classCards}
                          >
                            <Select
                              showSearch
                              placeholder="Select Class Cards"
                              mode="multiple"
                              allowClear
                              onChange={(val) => {
                                this.onFinish(val, "classCards");
                              }}
                              disabled={this.state.loading}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.getClassCard().map((card) => {
                                return (
                                  <Select.Option value={card.id} key={card.id}>
                                    {card.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Session Categories" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="categories"
                            style={{ width: "300px" }}
                            initialValue={session.categories}
                          >
                            <Select
                              showSearch
                              placeholder="Select Categories"
                              mode="multiple"
                              allowClear
                              onChange={(val) => {
                                this.onFinish(val, "categories");
                              }}
                              disabled={this.state.loading}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {categories.map((cat) => {
                                return (
                                  <Select.Option value={cat.id} key={cat.id}>
                                    {cat.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Video Start" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="vidStart"
                            initialValue={moment(session.vidStart?.toDate())}
                          >
                            <DatePicker
                              disabled={this.state.loading}
                              showTime
                              allowClear={false}
                              value={moment(session.vidStart?.toDate())}
                              onChange={(val) =>
                                this.onFinish(val.toDate(), "vidStart")
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Duration" span={3}>
                        <Text
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(parseInt(newStr), "duration"),
                                }
                          }
                        >
                          {session.duration.toString()}
                        </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Description" span={3}>
                        <Paragraph
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "description"),
                                }
                          }
                        >
                          {session.description}
                        </Paragraph>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <Descriptions>
                      <Descriptions.Item label={"Video ID"} span={3}>
                        <Text copyable>{session.videoId}</Text>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card id="upload-video" style={{ margin: "1rem 1rem 1rem 1rem" }}>
                {session.videoStatus === "ready" && (
                  <>
                    <Typography>
                      Video has uploaded{" "}
                      <Typography.Link
                        href={`https://www.vdocipher.com/dashboard/video/${session.videoId}/tab/settings`}
                      >
                        <LinkOutlined /> view
                      </Typography.Link>
                    </Typography>
                  </>
                )}
                {session.videoStatus === "processing" && (
                  <p>Video is processing...</p>
                )}
                {session.videoStatus === "pending" ||
                session.videoStatus === "obtained" ||
                session.videoStatus === "error" ||
                session.videoStatus === undefined ? (
                  <>
                    <Tabs type="card">
                      <Tabs.TabPane tab="Upload Video" key="item-1">
                        {!this.state.uploadCred ? (
                          <Button
                            loading={this.state.loading}
                            disabled={this.state.loading}
                            onClick={() => {
                              if (session.videoStatus === "obtained") {
                                this.obtainUploadCredentials(session.videoId);
                              } else {
                                this.uploadVideo(
                                  "OBTAIN_UPLOAD",
                                  classId,
                                  sessionId,
                                  {
                                    title: `${session.teacherUid}_${classId}_${sessionId}`,
                                  }
                                );
                              }
                            }}
                            icon={<KeyOutlined />}
                            type="primary"
                          >
                            {session.videoStatus === "obtained"
                              ? "Upload Video"
                              : "Obtain Upload"}
                          </Button>
                        ) : (
                          <Form>
                            <Form.Item className="attachment-file-list">
                              {(!this.state.statusLoading ||
                                session.videoStatus === "pending") && (
                                <Gallery
                                  uploader={
                                    new FineUploaderTraditional({
                                      options: {
                                        multiple: false,
                                        request: {
                                          omitDefaultParams: true,
                                          inputName: "file",
                                          params: {
                                            key: this.state.uploadCred["key"],
                                            policy:
                                              this.state.uploadCred["policy"],
                                            success_action_status: 201,
                                            success_action_redirect: "",
                                            "x-amz-credential":
                                              this.state.uploadCred[
                                                "x-amz-credential"
                                              ],
                                            "x-amz-algorithm":
                                              this.state.uploadCred[
                                                "x-amz-algorithm"
                                              ],
                                            "x-amz-date":
                                              this.state.uploadCred[
                                                "x-amz-date"
                                              ],
                                            "x-amz-signature":
                                              this.state.uploadCred[
                                                "x-amz-signature"
                                              ],
                                          },
                                          endpoint:
                                            this.state.uploadCred?.uploadLink ||
                                            null,
                                        },
                                        callbacks: {
                                          onComplete: () => {
                                            this.setState({
                                              statusLoading: true,
                                            });

                                            axios
                                              .post(
                                                process.env
                                                  .REACT_APP_CLOUDFUNCTIONS_URL +
                                                  "/api/vdo/updateVideoStatus",
                                                {
                                                  videoId: session.videoId,
                                                  status: "processing",
                                                  classId: classId,
                                                  sessionId: sessionId,
                                                },
                                                {
                                                  headers: {
                                                    Authorization:
                                                      "Bearer " +
                                                      this.props.token,
                                                  },
                                                }
                                              )
                                              .then((res) => {
                                                if (res.data.success) {
                                                  message.success(
                                                    "Status updated!"
                                                  );
                                                } else {
                                                  message.error(
                                                    "Status update failed!"
                                                  );
                                                }
                                              });
                                          },
                                        },
                                      },
                                    })
                                  }
                                />
                              )}
                              {(this.state.statusLoading ||
                                session.videoStatus === "processing") && (
                                <Spin size="large" />
                              )}
                            </Form.Item>
                          </Form>
                        )}
                      </Tabs.TabPane>

                      <Tabs.TabPane
                        disabled={session.videoStatus === "obtained"}
                        tab="Import URL"
                        key="item-2"
                      >
                        <ImportUrl
                          loading={this.state.loading}
                          callback={(url) =>
                            this.uploadVideo("IMPORT_URL", classId, sessionId, {
                              url,
                            })
                          }
                        />
                      </Tabs.TabPane>
                    </Tabs>
                  </>
                ) : null}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={"Attachments"}
                style={{ margin: "1rem 1rem 1rem 1rem" }}
              >
                <Form>
                  <Form.Item className="attachment-file-list">
                    <Upload.Dragger
                      accept=".pdf"
                      listType="picture"
                      defaultFileList={fileList}
                      onRemove={this.deleteAttachment}
                      disabled={this.state.uploading}
                      action={
                        process.env.REACT_APP_CLOUDFUNCTIONS_URL +
                        "/api/sessions/uploadAttachment/" +
                        classId +
                        "/" +
                        sessionId
                      }
                      headers={{
                        Authorization: `Bearer ${this.props.token}`,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Only .pdf files are allowed to upload, make sure the
                        filename is correct before uploading
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                id="delete-session"
                title={"Delete Session"}
                style={{ margin: "1rem 1rem 1rem 1rem", borderColor: "red" }}
              >
                <Row justify="space-between">
                  <Col flex={"auto"}>
                    <Typography>
                      Once you delete a session, there is no going back. Please
                      be certain and always have a backup of your source file.
                    </Typography>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="Are you sure you want to delete this session?"
                      onConfirm={this.deleteSession}
                      okText="Yes"
                      cancelText="No"
                      disabled={this.state.deleting}
                      key="delete"
                    >
                      <Tooltip title="Delete session">
                        <Button
                          loading={this.state.deleting}
                          icon={<DeleteOutlined />}
                          type="primary"
                          danger
                        >
                          Delete session
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
    //  else if (isEmpty(session)) {
    //   return (
    //     <Layout>
    //       <PageHeader
    //         onBack={() => window.history.back()}
    //         ghost={false}
    //         title="Edit session"
    //         className="site-page-header"
    //       ></PageHeader>
    //       <Row>
    //         <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
    //           <Card
    //             title="Session doesn't exists"
    //             style={{ margin: "1rem 1rem 0 1rem" }}
    //           >
    //             <Result
    //               status="404"
    //               title="404"
    //               subTitle="Sorry, the session you've selected does not exist."
    //             />
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Layout>
    //   );
    // }
    else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit session"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Session is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    profile: state.firebase.profile,
    attachments: state.firestore.ordered.selected_session_attachments,
    class_cards: state.firestore.ordered.class_cards,
    categories: state.firestore.ordered.selected_class_categories,
    session: state.firestore.ordered.selected_session
      ? state.firestore.ordered.selected_session[0]
      : null,
    classId: routeProps.match.params.classId,
    sessionId: routeProps.match.params.sessionId,
    sessionVideoUrl: state.onlineClass.sessionVideoUrl,
    token: state.firebase.profile.token.token,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  return {
    editSessionCover: (data) => dispatch(editSessionCover(data)),
    editSession: (data) => dispatch(editSession(data)),
    delSession: (classId, sessionId) =>
      dispatch(delSession(classId, sessionId)),
    delAttachment: (data) => dispatch(delAttachment(data)),
    approveVideo: (teacherUid, classUid, sessionUid) =>
      dispatch(approveVideo(teacherUid, classUid, sessionUid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => {
    let query = [
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          {
            collection: "categories",
            where: ["active", "==", true],
            // orderBy: "name",
          },
        ],
        storeAs: "selected_class_categories",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          { collection: "sessions", doc: routeProps.match.params.sessionId },
        ],
        storeAs: "selected_session",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          {
            collection: "sessions",
            doc: routeProps.match.params.sessionId,
            subcollections: [{ collection: "attachments", orderBy: ["name"] }],
          },
        ],
        storeAs: "selected_session_attachments",
      },
    ];

    if (routeProps.session) {
      query.push({
        collection: "class_cards",
        where: ["teacherUid", "==", routeProps.session.teacherUid],
      });
    }

    return query;
  })
)(withRouter(EditSession));
