import React, { Component } from "react";
import CoordTable from "./components/CoordTable";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Button,
  Skeleton,
  Modal,
  Form,
  Input,
  message,
  Select,
} from "antd";
import { addNewCoord } from "../../store/actions/coord";
class Coordinators extends Component {
  state = {
    loading: false,
    addModalVisible: false,
  };
  setAddModalVisible = (val) => {
    this.setState({
      addModalVisible: val,
    });
  };
  onAdd = async (val) => {
    this.setState({
      loading: true,
    });
    await this.props.addNewCoord({
      email: val.email,
      name: val.name,
      password: val.password,
      teacherUid: this.props.userRole === "COMPANY_ADMIN" 
        ? val.teacher 
        : this.props.teacherUid,
    });
    if (this.props.addError) {
      message.error("There was an error");
    } else {
      message.success("User added successfully");
    }
    this.setState({
      loading: false,
      addModalVisible: false,
    });
  };
  render() {
    const { users, teachers, role } = this.props;
    if (isLoaded(users, teachers, role)) {
      
      let teacherMappedUsers = users.map((user) => {
        let teacherName = (role === 'COMPANY_ADMIN' && teachers.length > 0 
            && (teachers.filter((i) => i.user === user.teacherUid ).length > 0) )
          ? teachers.filter((i) => i.user === user.teacherUid )[0].name
          : '';
        
        return Object.assign({}, user, {teacherName: teacherName}); 
      });

      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Coordinators"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All coordinators"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  this.props.role === "TEACHER" || this.props.role === "COMPANY_ADMIN" ? (
                    <Button
                      onClick={() => this.setAddModalVisible(true)}
                      type="primary"
                      disabled={this.state.loading}
                    >
                      Add new
                    </Button>
                  ) : null
                }
              >
                <CoordTable data={teacherMappedUsers} role={role} />
              </Card>
            </Col>
          </Row>

          <AddNewModal
            visible={this.state.addModalVisible}
            onOk={this.onAdd}
            onCancel={() => {
              this.setAddModalVisible(false);
            }}
            teachers={this.props.teachers}
            userRole={this.props.userRole}
          />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Coordinators"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All coordinators"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}
const AddNewModal = ({ visible, onOk, onCancel, loading, teachers, userRole }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Add new coordinator"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onOk(values);
          })
          .catch(() => {});
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        {
          userRole === "COMPANY_ADMIN" &&
          <Form.Item
            label="Teacher"
            name="teacher"
            rules={[{ required: true }]}
          >
            <Select
              disabled={loading}
              placeholder="Select teacher"
              style={{ width: "100%" }}
            >
              {teachers.map((teacher) => (
                <Select.Option key={teacher.user} value={teacher.user}>
                  {teacher.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Form.Item
          name="email"
          label="Email address"
          rules={[
            {
              required: true,
              message: "Email cannot be empty",
            },
            { type: "email", message: "Invalid email" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password cannot be empty",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state, routeProps) => {
  return {
    users: state.firestore.ordered.users,
    auth: state.firebase.auth,
    role: routeProps.userRole,
    addError: state.coord.addError,
    teacherUid: routeProps.userTeacherUid,
    teachers: state.firestore.ordered.teachers ? state.firestore.ordered.teachers : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewCoord: (data) => dispatch(addNewCoord(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let queries = [];

    if( props.userRole === 'COMPANY_ADMIN' ){
      queries.push(
        {
          collection: "teachers"
        },
        {
          collection: "users",
          where: [
            ["type", "==", "TEACHER_DATA_ENTRY"],
          ],
        }
      );
    }else if( props.userRole === 'TEACHER' ){
      queries.push(
        {
          collection: "users",
          where: [
            ["teacherUid", "==", props.teacherUid],
            ["type", "==", "TEACHER_DATA_ENTRY"],
          ],
        }
      );
    }

    return queries;
  })
)(Coordinators);
