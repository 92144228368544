import React, { Component } from "react";
import PapersTable from "./components/PapersTable";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Row, Col, Layout, PageHeader, Card, Button, Skeleton } from "antd";
import { NavLink } from "react-router-dom";

class Papers extends Component {
  render() {
    const { exams, role } = this.props;
    if (isLoaded(exams)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Papers"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All papers"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  <NavLink to="/papers/add">
                    <Button type="primary">Add new paper</Button>
                  </NavLink>
                }
              >
                <PapersTable data={exams} role={role} />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Papers"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All papers"
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    exams: state.firestore.ordered.exams,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "exams",
        orderBy: ["createdAt", "desc"],
      },
    ];
  })
)(Papers);
