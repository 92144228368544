export const closeTicket = (ticketId, reason) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      const firebase = getFirebase();
      const admin = await firebase.auth().currentUser;
      try {
        var res = await firestore
          .collection("support_tickets")
          .doc(ticketId)
          .update({
            status: "CLOSED",
            closedBy: admin.uid,
            closedReason: reason,
          });
        dispatch({ type: "CLOSE_SUCCESS", res });
      } catch (err) {
        dispatch({ type: "CLOSE_ERROR", err });
      }
    };
  };
  export const reply = (ticketId, message, role) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      const firebase = getFirebase();
      const admin = await firebase.auth().currentUser;
      try {
        var res = await firestore
          .collection("support_tickets")
          .doc(ticketId)
          .collection("conversation")
          .add({
            createdAt: new Date(),
            message,
            sender: "STAFF",
            staffRole: role,
            staffUid: admin.uid,
          });
        dispatch({ type: "REPLY_SUCCESS", res });
      } catch (err) {
        dispatch({ type: "REPLY_ERROR", err });
      }
    };
  };
  