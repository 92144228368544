const initState = {
  adminToken:
    "ytL0qlT8TxRPEto5eoPpIsccii3X6csuJz9DIYLLx0xV1BogowG1s5g7aQchLzwM",
  bookError: null,
  resetError: null,
  exams: null,
  classes: null,
  users: null,
  sessionBookings: null,
  liveSessionBookings: null,
  classCardBookings: null,
  fetchError: null,
  exportError: null,
  cancelError: null,
  getBookingsError: null,
};

const booking = (state = initState, action) => {
  switch (action.type) {
    case "GET_SESSION_BOOKINGS_SUCCESS":
      return {
        ...state,
        sessionBookings: action.res,
        getBookingsError: null,
      };
    case "GET_SESSION_BOOKINGS_ERROR":
      return {
        ...state,
        sessionBookings: null,
        getBookingsError: action.err,
      };
    case "GET_LIVE_BOOKINGS_SUCCESS":
      return {
        ...state,
        liveSessionBookings: action.res,
        getBookingsError: null,
      };
    case "GET_LIVE_BOOKINGS_ERROR":
      return {
        ...state,
        liveSessionBookings: null,
        getBookingsError: action.err,
      };
    case "GET_CLASS_BOOKINGS_SUCCESS":
      return {
        ...state,
        classCardBookings: action.res,
        getBookingsError: null,
      };
    case "GET_CLASS_BOOKINGS_ERROR":
      return {
        ...state,
        classCardBookings: null,
        getBookingsError: action.err,
      };
    case "BOOK_SUCCESS":
      return {
        ...state,
        bookError: null,
      };
    case "BOOK_ERROR":
      return {
        ...state,
        bookError: action.err,
      };
    case "CANCEL_SUCCESS":
      return {
        ...state,
        cancelError: null,
      };
    case "CANCEL_ERROR":
      return {
        ...state,
        cancelError: action.err,
      };
    case "EXPORT_SUCCESS":
      return {
        ...state,
        resetError: null,
      };
    case "EXPORT_ERROR":
      return {
        ...state,
        exportError: action.err,
      };
    case "FETCHED_CLASSES":
      return {
        ...state,
        classes: action.res,
        fetchError: null,
      };
    case "FETCHED_USERS":
      return {
        ...state,
        users: action.res,
        fetchError: null,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        exams: null,
        fetchError: action.err,
      };
    default:
      return state;
  }
};

export default booking;
