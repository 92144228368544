export const addNewCoord = (data) => {
  return async (dispatch, getState, { api }) => {
    try {
      var res = await api.post("/api/teachers/addDataEntryUser", {
        email: data.email,
        password: data.password,
        name: data.name,
        teacherUid: data.teacherUid,
      });
      console.log(res);
      dispatch({ type: "COORD_ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "COORD_ADD_ERROR", err });
    }
  };
};