import auth from "./auth";
import booking from "./booking";
import { combineReducers } from "redux";
import coord from "./coord";
import teacher from "./teacher";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import onlineClass from "./onlineClass";
import support from "./support";
import txs from "./transaction";
import post from "./post";
import paper from "./paper";
import verifications from "./verifications";

const root = combineReducers({
  txs,
  auth,
  onlineClass,
  support,
  booking,
  coord,
  teacher,
  post,
  paper,
  verifications,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default root;
