import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { message, Table, Space, Tooltip, Button } from "antd";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import TxPreview from "./TxPreview";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

const sortByTimeB = (a, b) => {
  var A = moment(a.created_at.toDate());
  var B = moment(b.created_at.toDate());

  if (A.isBefore(B)) {
    return -1;
  }
  if (A.isAfter(B)) {
    return 1;
  }
  return 0;
};
class TxsTable extends Component {
  state = {
    visible: false,
    editing: null,
    loading: false,
    selected: null,
  };

  render() {
    const columns = [
      {
        title: "Payment Id",
        dataIndex: "payment_no",
      },
      {
        title: "Amount(Rs.)",
        dataIndex: "amount",
      },
      {
        title: "Gateway",
        dataIndex: "gateway",
      },
      {
        title: "Created At",
        key: "created_at",
        render: (_, record) => {
          return (
            <label>
              {record.created_at
                ? moment
                    .unix(record.created_at.seconds)
                    .format("YYYY-MM-DD HH:mm:ss")
                : "-"}
            </label>
          );
        },
      },
      {
        title: "Type",
        dataIndex: "type",
      },
      // {
      //   title: "Date Added",
      //   dataIndex: "created_at",
      //   render: (_, record) => (
      //     <div>
      //       {/* {moment(record.created_at).format("MMMM Do YYYY, h:mm A")} */}
      //     </div>
      //   ),
      //   sorter: sortByTimeB,
      //   sortDirections: ["descend", "ascend"],
      // },
      {
        title: "Action",
        key: "operation",
        render: (_, record) => {
          return (
            <NavLink
              to={{
                pathname: "/transactions/" + record.id,
                key: record.id,
              }}
              className="waves-effect sidenav-close"
            >
              <Tooltip title="Edit">
                <EyeOutlined />
              </Tooltip>
            </NavLink>
          );
        },
      },
    ];
    // console.log(this.props.data);
    return (
      <div>
        <Table columns={columns} dataSource={this.props.data} rowKey="id" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    transactions: state.firestore.ordered.transactions,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "transactions",
      where: [["teacher_uid", "==", props.teacherUid]],
      orderBy: ["created_at", "desc"],
      limit: 10,
      // startAfter: props.lastDoc
    },
  ])
)(TxsTable);
