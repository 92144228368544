import React, { Component } from "react";
import TxsTable from "./components/TxsTable";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import qs from "qs";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  List,
  message,
  DatePicker,
  Spin,
  Tag,
  Skeleton,
  Button,
  Tooltip,
  Space,
  Modal,
  Form,
  Input,
  Pagination,
} from "antd";
import { cancelManualBooking } from "../../store/actions/booking";
import { getStudentByPhoneNumber } from "../../store/actions/transaction";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { aws } from "../../utils/api";
const count = 3;

class Transactions extends Component {
  state = {
    visible: false,
    cancelVisible: false,
    initLoading: true,
    loading: false,
    fetching: false,
    data: [],
    list: [],
    filter: {
      phone: " ",
      from: moment().subtract(3, "months"),
      to: moment(),
      orderId: " ",
    },
    page: 1,
    totalCount: 0,
    selectedForCancel: null,
    cancelling: false,
  };

  componentDidMount() {
    this.updateStateFromQueryString();
    this.getData();
  }

  updateStateFromQueryString = () => {
    let qParams = this.props.queryStrings;
    if (qParams) {
      //here orderid cannot to be camel case!!!
      let { from, to, phone, orderid } = qParams;
      if (from && to) {
        this.setFilter({
          from,
          to,
        });
      }

      if (phone) {
        this.setFilter({
          phone,
        });
      }
      if (orderid) {
        this.setFilter({
          orderId: orderid,
        });
      }
    }
  };

  modalVisible = (val) => {
    this.setState({
      visible: val,
    });
  };
  cancelVisible = (val) => {
    this.setState({
      cancelVisible: val,
    });
  };
  clearSelectedForCancel = () => {
    this.setState({
      selectedForCancel: null,
    });
  };

  filterData = async ({ query }) => {
    this.setFilter({
      phone: query,
    });
    this.setState({ visible: false });
  };
  clearFilter = async () => {
    this.setFilter({
      phone: " ",
      from: moment().subtract(3, "months"),
      to: moment(),
    });
    this.setState({
      page: 1,
    });
    this.getData();
  };

  cancelManualBooking = async (val) => {
    this.setState({
      cancelling: true,
    });
    if (val) {
      await this.props.cancelManualBooking({
        transaction_uid: this.state.selectedForCancel,
        reason: val.reason,
      });

      if (!this.props.cancelError) {
        message.success("Transaction cancelled");
      } else {
        message.error("There was an error");
      }
    }
    this.setState({
      cancelVisible: false,
      cancelling: false,
    });
  };

  getData = async (page) => {
    this.setState({
      fetching: true,
    });

    if (this.state.filter.phone) {
      await this.props.getStudentByPhoneNumber(this.state.filter.phone);
    }

    const token = this.props.token;
    if (token) {
      const res = await aws.post(
        "/payments/getTransactions",
        {
          studentUid: this.props.studentUid ? this.props.studentUid : null,
          from: this.state.filter.from.toDate().getTime(),
          to: this.state.filter.to.toDate().getTime(),
          page: page ? page : this.state.page,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res && res.data.success) {
        const transactions = res.data.data.data;
        const txCreatedAtTimeZoneRemoved = transactions.map((item) =>
          Object.assign({}, item, {
            created_at: item.created_at.replace(".000Z", ""),
          })
        );
        this.setState({
          data: txCreatedAtTimeZoneRemoved,
          list: txCreatedAtTimeZoneRemoved,
          totalCount: res.data.data.total,
        });
      } else {
        this.setState({
          data: [],
          list: [],
          totalCount: 0,
        });
      }
    }

    this.setState({
      initLoading: false,
      fetching: false,
    });
  };

  getEntityTypeName = (entityType) => {
    if (entityType === "P") {
      return "PAPER";
    }
    if (entityType === "S") {
      return "SESSION";
    }
    if (entityType === "L") {
      return "LIVE SESSION";
    }
    if (entityType === "C") {
      return "CLASS CARD";
    }
    if (entityType === "T") {
      return "TOPUP";
    }
  };

  setFilter = async (data) => {
    let { phone, from, to, orderId } = data;
    let qParams = this.props.queryStrings;
    await this.setState({
      data: [],
      list: [],
    });
    await this.setState({
      filter: {
        phone: phone ? phone : this.state.filter.phone,
        from: from ? from : this.state.filter.from,
        to: to ? to : this.state.filter.to,
        orderId: orderId ? orderId : this.state.filter.orderId,
      },
    });

    this.getData();
  };

  onPaginate = (page) => {
    this.setState({
      page: page,
    });
    this.getData(page);
  };

  onDateRangeChange = async (dates) => {
    if (dates) {
      this.setFilter({
        from: dates[0],
        to: dates[1],
      });
    }
    //this.openPageWithQueryParams();
  };

  openPageWithQueryParams = () => {
    let newQParams;
    //TODO: fix state param value workarounds
    newQParams = {
      phone:
        this.state.filter.phone === " " ? undefined : this.state.filter.phone,
      from: this.state.filter.from?.format("YYYY-MM-DD"),
      to: this.state.filter.to?.format("YYYY-MM-DD"),
      orderid:
        this.state.filter.orderId === " "
          ? undefined
          : this.state.filter.orderId,
    };
    this.props.history.push("/transactions?" + qs.stringify(newQParams));
  };
  render() {
    const { transactions, role } = this.props;
    const { data, filter } = this.state;
    const {
      initLoading,
      loading,
      list,
      fetching,
      visible,
      cancelVisible,
      cancelling,
    } = this.state;
    return (
      <Layout>
        <PageHeader
          ghost={false}
          title="Transactions"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title={
                this.state.filter.phone !== " "
                  ? "Filtered by " + this.state.filter.phone
                  : "All transactions"
              }
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={[
                <DatePicker.RangePicker
                  allowClear={false}
                  defaultValue={[filter.from, filter.to]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Week": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Last Week": [
                      moment().subtract(1, "week").startOf("week"),
                      moment().subtract(1, "week").endOf("week"),
                    ],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Last Month": [
                      moment().subtract(1, "months").startOf("month"),
                      moment().subtract(1, "months").endOf("month"),
                    ],
                    "Last 3 Months": [
                      moment().subtract(3, "months").startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  onChange={this.onDateRangeChange}
                  key={1}
                />,
                this.state.filter.phone !== " " ? (
                  <Button key="2" onClick={this.clearFilter}>
                    Clear Filter
                  </Button>
                ) : (
                  <Button
                    key="2"
                    onClick={() => {
                      this.modalVisible(true);
                    }}
                  >
                    Filter by phone
                  </Button>
                ),
              ]}
            >
              <FilterModal
                visible={visible}
                onCreate={this.filterData}
                onCancel={() => {
                  this.modalVisible(false);
                }}
              />
              <CancelModal
                visible={cancelVisible}
                onCreate={this.cancelManualBooking}
                loading={cancelling}
                onCancel={() => {
                  this.cancelVisible(false);
                  this.clearSelectedForCancel();
                }}
              />

              <List
                loading={initLoading || fetching}
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <NavLink
                        to={{
                          pathname: "/transactions/" + item.id,
                          key: item.id,
                        }}
                        className="waves-effect sidenav-close"
                        key="preview"
                        target="_blank"
                      >
                        <Tooltip title="Open transaction">
                          <EyeOutlined />
                        </Tooltip>
                      </NavLink>,

                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      // <a
                      //   onClick={() => {
                      //     this.setState({
                      //       selectedForCancel: item.id,
                      //       cancelVisible: true,
                      //     });
                      //   }}
                      // >
                      //   <DeleteOutlined />
                      // </a>,
                    ]}
                  >
                    <Skeleton avatar title={false} loading={false} active>
                      <List.Item.Meta
                        title={
                          <>
                            {item.type +
                              " | " +
                              moment(item.created_at).fromNow() ??
                              "STATUS NOT AVAILABLE"}
                            &nbsp;
                            {item.entity_type === "P" ? (
                              <Tag color="cyan">PAPER</Tag>
                            ) : (
                              <Tag color="purple">
                                {this.getEntityTypeName(item.entity_type)}
                              </Tag>
                            )}
                            &nbsp;
                            {item.is_manual_payment ? (
                              <Tag color="default">Manual</Tag>
                            ) : (
                              <Tag color="blue">Online</Tag>
                            )}
                          </>
                        }
                        description={"Order Id: " + (item.order_id ?? "N/A")}
                      />
                      <Space direction="vertical">
                        <div>
                          {this.getEntityTypeName(item.entity_type) ?? "N/A"}
                        </div>

                        <div>
                          {item.created_at
                            ? moment(item.created_at.replace(".000Z")).format(
                                "D-MM-yy"
                              )
                            : null}
                        </div>
                      </Space>
                    </Skeleton>
                  </List.Item>
                )}
              />
              <div style={{ textAlign: "right" }}>
                <Pagination
                  current={this.state.page}
                  onChange={this.onPaginate}
                  total={this.state.totalCount}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Layout>
    );
    // if (isLoaded(transactions)) {
    //   return (
    // <Layout>
    //   <PageHeader
    //     ghost={false}
    //     title="Transactions"
    //     className="site-page-header"
    //   ></PageHeader>
    //   <Row>
    //     <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
    //       <Card
    //         title="All transactions"
    //         style={{ margin: "1rem 1rem 0 1rem" }}
    //       >
    //         <TxsTable data={transactions} role={role} />
    //       </Card>
    //     </Col>
    //   </Row>
    // </Layout>
    //   );
    // } else {
    //   return (
    //     <Layout>
    //       <PageHeader
    //         ghost={false}
    //         title="Transactions"
    //         className="site-page-header"
    //       ></PageHeader>
    //       <Row>
    //         <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
    //           <Card
    //             title="All transactions"
    //             style={{ margin: "1rem 1rem 0 1rem" }}
    //           >
    //             <Skeleton active />
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Layout>
    //   );
    // }
  }
}
const FilterModal = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Filter by phone number"
      okText="Filter"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="query"
          label="Phone number"
          rules={[
            {
              required: true,
              message: "Phone number cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const CancelModal = ({ visible, onCreate, onCancel, loading }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Reason for the cancellation"
      okText="Ok"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="reason"
          label="Reason"
          rules={[
            {
              required: true,
              message: "A reason must be provided",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const mapStateToProps = (state, props) => {
  return {
    transactions: state.firestore.ordered.transactions,
    auth: state.firebase.auth,
    txs: state.txs.txs ? state.txs.txs.docs : [],
    role: state.firebase.profile.token.claims.role,
    token: state.firebase.profile.token.token,
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
    queryStrings: qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }),
    cancelError: state.booking.cancelError,
    studentUid: state.txs.studentUid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentByPhoneNumber: (phoneNumber) =>
      dispatch(getStudentByPhoneNumber(phoneNumber)),
    cancelManualBooking: (data) => dispatch(cancelManualBooking(data)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Transactions
);
