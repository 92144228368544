import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import axios from "axios";
import { compose } from "redux";
import ImgCrop from "antd-img-crop";
import { addPaper } from "../../store/actions/paper";

import {
  message,
  Card,
  Row,
  Col,
  Layout,
  PageHeader,
  Button,
  TimePicker,
  DatePicker,
  Form,
  Input,
  Select,
  InputNumber,
  Skeleton,
  Spin,
  Upload,
} from "antd";
import {
  LoadingOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import LoadingScreen from "../layout/LoadingScreen";
const { REACT_APP_CLOUDFUNCTIONS_URL } = process.env;

class AddPaper extends Component {
  state = {
    loading: false,
    gradeUid: null,
    streams: [],
    image: null,
    coverUrl: null,
    type: 1,
    imageUrl: "",
    file: "",
  };
  fileReader = new FileReader();
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  setGrade = (value) => {
    this.setState({
      gradeUid: value,
    });
  };
  setStreams = (value) => {
    this.setState({
      streams: value,
    });
  };
  onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  onChange = (info) => {
    if (!this.fileReader.onloadend) {
      this.fileReader.onloadend = (obj) => {
        this.setState({
          image: obj.srcElement.result, //will be used for knowing load is finished.
        });
      };
      // can be any other read function ( any reading function from
      // previously created instance can be used )
      this.fileReader.readAsArrayBuffer(info.file.originFileObj);
    }
  };

  customRequest = async (option) => {
    const { onSuccess, onError, file, action, onProgress } = option;
    const url = action;

    await new Promise((resolve) => this.waitUntilImageLoaded(resolve)); //in the next section
    const { image } = this.state; // from onChange function above
    const formData = new FormData();
    formData.append("image", image);
    axios
      .post(url, formData, {
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 });
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response);
        if (response.body.status) {
          onSuccess(response.body.data.fileName);
        } else {
          onError(response.body.message);
        }
      })
      .catch((err) => {
        onError(err);
      });
  };

  waitUntilImageLoaded = (resolve) => {
    setTimeout(() => {
      this.state.image
        ? resolve() // from onChange method
        : this.waitUntilImageLoaded(resolve);
    }, 10);
  };

  handleSubmit = async (form) => {
    // console.log(form);
    this.setState({ loading: true });

    if (this.state.file === "") {
      message.warn("select a cover image");
      this.setState({ loading: false });
      return;
    }

    const data = {
      file: this.state.file,
      ...form,
    };

    await this.props.addPaper(data);

    if (!this.props.createError) {
      message.success("Paper Added Successfully");
      window.location = "/papers";
    } else {
      message.error("There was an error adding new paper");
    }

    this.setState({ loading: false });
  };
  handleCoverChange = (val) => {
    //console.log(val);
    this.setState({ imageUrl: undefined });
    if ("response" in val.file) {
      if (val.file.response.status) {
        this.setState({
          imageUrl: val.file.response.data.url,
          uploading: false,
        });
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
        this.setState({
          uploading: false,
        });
      }
    }
    if (val.file.status === "uploading") {
      this.setState({ uploading: true });
    }
    this.setState({ coverFile: val.file });
    // this.setState({ fileList: val.fileList });
  };
  componentDidUpdate() {}

  render() {
    const { imageUrl } = this.state;
    const { grades, streams, teachers, subjects, userRole, userTeacherUid } =
      this.props;
    if (isLoaded(grades, streams, teachers, subjects)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Add Paper"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Add a new paper"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="create-post"
                  layout="vertical"
                  onFinish={this.handleSubmit}
                  validateMessages={{ required: "${label} cannot be empty!" }}
                >
                  <Form.Item
                    label="Paper Name"
                    name="name"
                    rules={[{ required: true }]}
                    autoFocus={true}
                  >
                    <Input
                      placeholder="A title for the paper"
                      disabled={this.state.loading}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Paper Date"
                    name="date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker disabled={this.state.loading} />
                  </Form.Item>
                  <Form.Item
                    label="Paper Time"
                    name="time"
                    rules={[{ required: true }]}
                  >
                    <TimePicker.RangePicker disabled={this.state.loading} />
                  </Form.Item>
                  <Form.Item
                    label="Fee (Rs.)"
                    name="fee"
                    rules={[{ required: true }]}
                    initialValue={0}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  <Form.Item
                    label="No of questions"
                    name="noOfQuestions"
                    rules={[{ required: true }]}
                    initialValue={50}
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                  <Form.Item
                    label="No of answers per question"
                    name="noOfAnswers"
                    rules={[{ required: true }]}
                    initialValue={5}
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                  <Form.Item
                    label="Paper Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "You must enter a description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Description about the paper"
                      rows={3}
                      disabled={this.state.loading}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Grade"
                    name="gradeUid"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a Grade"
                      onChange={this.setGrade}
                      disabled={this.state.loading}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {grades.map((grade) => {
                        return (
                          <Select.Option value={grade.id} key={grade.id}>
                            {grade.display}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Paper Instructions"
                    name="instructions"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Instructions on how to answer"
                      rows={3}
                      disabled={this.state.loading}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Medium"
                    name="medium"
                    initialValue="Sinhala"
                    rules={[{ required: true }]}
                  >
                    <Select disabled={this.state.loading}>
                      <Select.Option value={"Sinhala"}>Sinhala</Select.Option>
                      <Select.Option value={"English"}>English</Select.Option>
                      <Select.Option value={"Tamil"}>Tamil</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Streams"
                    name="streams"
                    rules={[{ required: true }]}
                  >
                    <Select
                      disabled={this.state.loading}
                      mode="multiple"
                      placeholder="Select streams"
                      value={this.state.streams}
                      onChange={this.setStreams}
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {streams.map((stream) => (
                        <Select.Option key={stream.id} value={stream.id}>
                          {stream.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {(userRole === "TEACHER" ||
                    userRole === "TEACHER_DATA_ENTRY") && (
                    <Form.Item
                      name={"teacherUid"}
                      initialValue={userTeacherUid}
                      hidden
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {userRole === "COMPANY_ADMIN" && (
                    <Form.Item
                      label="Teacher"
                      name="teacherUid"
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={this.state.loading}
                        placeholder="Select teacher"
                        onChange={this.setTeacher}
                        style={{ width: "100%" }}
                      >
                        {teachers.map((teacher) => (
                          <Select.Option key={teacher.id} value={teacher.id}>
                            {teacher.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Subject"
                    name="subjectUid"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a subject"
                      onChange={this.setSubject}
                      disabled={this.state.loading}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {subjects.map((subject) => {
                        return (
                          <Select.Option value={subject.id} key={subject.id}>
                            {subject.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"cover"}
                    label="Cover Image"
                    // rules={[{ required: true }]}
                    // valuePropName="file"
                    className={"question-image-list"}
                  >
                    <ImgCrop rotate>
                      <Upload
                        accept={".jpg"}
                        listType="picture-card"
                        className="class-cover-uploader"
                        onPreview={this.handlePreview}
                        disabled={this.state.loading || this.state.uploading}
                        beforeUpload={(e) => {
                          this.setState({
                            file: e,
                          });
                          var urlCreator = window.URL || window.webkitURL;
                          this.setState({
                            imageUrl: urlCreator.createObjectURL(e),
                          });
                          return false;
                        }}
                        showUploadList={false}
                      >
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div>
                            {this.state.uploading ? (
                              <LoadingOutlined />
                            ) : (
                              <PlusOutlined />
                            )}
                            <div className="ant-upload-text">Upload</div>
                          </div>
                        )}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                  <Form.Item colon={false}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={this.state.loading}
                    >
                      Add paper
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              {/* <PostList posts={this.props.posts} limit={5} /> */}
            </Col>
          </Row>
        </Layout>
      );
    }
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          title="Add paper"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Please wait a while..."
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    grades: state.firestore.ordered.grades,
    streams: state.firestore.ordered.streams,
    teachers: state.firestore.ordered.teachers,
    subjects: state.firestore.ordered.subjects,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addPaper: (paper) => dispatch(addPaper(paper)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: "grades", orderBy: ["index", "desc"] },
    { collection: "streams" },
    { collection: "teachers", orderBy: "name" },
    { collection: "subjects" },
  ])
)(AddPaper);
