import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Popconfirm, Button, Skeleton, Typography } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { delDiscussion } from "../../../store/actions/paper";
import { NavLink } from "react-router-dom";

const { Paragraph, Text } = Typography;

class Discussion extends Component {
  formRef = React.createRef();
  state = {
    loading: false,
    deleting: false,
  };

  deleteDiscussion = async () => {
    this.setState({
      deleting: true,
    });
    await this.props.delDiscussion();
  };

  render() {
    const { discussion, selected } = this.props;
    return (
      <Card
        key={discussion.id}
        actions={[
          <NavLink
            to={"/papers/edit/" + selected.id + "/discussions/" + discussion.id}
            disabled={this.state.deleting}
            key="edit"
          >
            <Button
              className="session-action"
              type="text"
              key="edit"
              disabled={this.state.deleting}
            >
              <EditOutlined />
            </Button>
          </NavLink>,
          <Popconfirm
            title="Are you sure you want to delete this discussion?"
            onConfirm={this.deleteDiscussion}
            okText="Yes"
            cancelText="No"
            disabled={this.state.deleting}
            key="delete"
          >
            <Button
              className="session-action"
              type="text"
              loading={this.state.deleting}
              disabled={this.state.deleting}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>,
        ]}
        cover={<img alt="Cover" src={discussion.cover} />}
        hoverable
      >
        <Skeleton loading={this.state.loading} active>
          <Card.Meta
            style={{ textAlign: "center" }}
            title={
              <Text
                ellipsis={{
                  rows: 1,
                  expandable: true,
                }}
              >
                {discussion.name}
              </Text>
            }
          />
          <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: "more" }}>
            {discussion.description}
          </Paragraph>
        </Skeleton>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    delDiscussion: () =>
      dispatch(delDiscussion(props.selected.id, props.discussion.id)),
  };
};

export default connect(null, mapDispatchToProps)(Discussion);
