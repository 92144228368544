const initialState = {
  count: 0,
  loading: false,
  data: null,
  error: null,
};

const verifications = (state = initialState, action) => {
  switch (action.type) {
    case "VERIFICATION_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "GET_VERIFICATIONS":
      return {
        ...state,
        data: action.res.verifications,
        count: action.res.count,
        error: null,
      };
    case "GET_VERIFICATIONS_ERROR":
      return {
        ...state,
        error: action.err,
      };
    case "APPROVE_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "APPROVE_ERROR":
      return {
        ...state,
        error: action.err,
      };
    case "DISAPPROVE_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "DISAPPROVE_ERROR":
      return {
        ...state,
        error: action.err,
      };
    case "REJECT_VERIFICATION_SUCCESS":
      return {
        ...state,
      };

    case "REJECT_VERIFICATION_ERROR":
      return {
        ...state,
        error: action.err,
      };
    default:
      return state;
  }
};

export default verifications;
