/* eslint-disable react/react-in-jsx-scope */
import { Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
const { Option } = Select;

const ClassCardSelect = ({ classCards, onChange, loading }) => {
  if (isLoaded(classCards)) {
    return (
      <>
        <Select
          showSearch
          allowClear
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={onChange}
          disabled={loading}
          placeholder="Select Entity"
          style={{ width: "100%" }}
        >
          {classCards.map((classCard) => {
            return (
              <Option key={classCard.id} value={JSON.stringify(classCard)}>
                {classCard.name} - LKR{classCard.amount}
              </Option>
            );
          })}
        </Select>
      </>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    classCards: state.firestore.ordered.classCards,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "class_cards",
        where: ["teacherUid", "==", props.teacherUid],
        storeAs: "classCards",
      },
    ];
  })
)(ClassCardSelect);
