import React from "react";
import {
  Modal,
  Form,
  Typography,
} from "antd";

const { Text } = Typography;

const ToggleAccountDisableModal = ({
    loading,
    visible,
    onOk,
    onCancel,
    title,
    okText,
    name,
    email,
    isActive,
    accountType = 'TEACHER'
  }) => {
    const [form] = Form.useForm();
    
    return (
      <Modal
        destroyOnClose={true}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
        maskClosable={false}
        visible={visible}
        title={title}
        okText={okText}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          onOk();
        }}
      >
        <div>
            {isActive ? "Disable" : "Activate"} the account for <Text mark>{name}({email})</Text>? 
            {
              accountType !== 'TEACHER'
                ? ''
                : (
                    isActive
                      ? "This will disable all the Coordinator accounts as well."
                      : "This will activate all the Coordinator accounts except the ones manually disabled previously"
                  )
            }
        </div>
      </Modal>
    );
};

export default ToggleAccountDisableModal;