import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Skeleton,
  Typography,
  Avatar,
  Descriptions,
  Space,
  Form,
  Tabs,
  List,
  Input,
  Spin,
  Empty,
  Button,
  Result,
  Popconfirm,
  Pagination,
  Tag,
  message,
  Radio,
  Modal,
  DatePicker,
  Select,
  Table,
} from "antd";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import StudentInfoListItem from "./components/StudentInfoListItem";
import {
  getUserFromPhone,
  getSessionBookings,
  bookSession,
  resetVideoPlay,
  exportSessionBookedStudentDetails,
} from "../../store/actions/booking";
import { exportTransactions } from "../../store/actions/transaction";
import { NavLink } from "react-router-dom";
const { Paragraph, Text, Link } = Typography;

class SessionBookings extends Component {
  state = {
    modalTimeVisible: false,
    modalBookVisible: false,
    modalExportVisible: false,
    modalTxExportVisible: false,
    loading: false,
    searching: false,
    loadingBookings: false,
    currentPage: 1,
    pageSize: 10,
    filter: false,
    alreadyBooked: false,
    query: "+94",
    bookConfirmVisible: false,
  };

  setTimeVisible = (val) => {
    this.setState({
      modalTimeVisible: val,
    });
  };
  setBookVisible = (val) => {
    this.setState({
      modalBookVisible: val,
    });
  };
  setExportVisible = (val) => {
    this.setState({
      modalExportVisible: val,
    });
  };
  setTxExportVisible = (val) => {
    this.setState({
      modalTxExportVisible: val,
    });
  };
  setBookConfirmVisible = (val) => {
    this.setState({
      bookConfirmVisible: val,
    });
  };

  searchForUser = async (phone) => {
    this.setState({ searching: true });
    await this.props.getUserFromPhone(phone);
    this.loadBookings();
    this.setState({ searching: false });
  };

  updateQuery = (e) => {
    if (e.target.value.trim().includes("+94")) {
      this.setState({ query: e.target.value });
    } else {
      this.setState({ query: "+94" });
    }
  };

  setFilter = (phone) => {
    this.setState({
      query: phone,
    });
    this.searchForUser(phone);
  };

  clearFilter = () => {
    this.setState({
      filter: false,
      alreadyBooked: false,
      query: "+94",
    });
    this.loadBookings();
  };

  handleOnChangePagination = (page) => {
    this.setState({
      currentPage: page,
    });
    this.loadBookings();
  };

  loadBookings = async () => {
    this.setState({
      loadingBookings: true,
    });

    // query
    const entityUid = `${this.props.classId}_${this.props.sessionId}`;
    const query = this.state.query;
    const userUid = this.props.user?.id; // this could exist even when cleared

    const currentPage = this.state.currentPage;
    const pageSize = this.state.pageSize;

    // load bookings
    const hasUser = query && query != "+94" && userUid;

    await this.props.getSessionBookings({
      entityType: "S",
      entityUid: entityUid,
      userUid: hasUser ? userUid : null,
      page: currentPage,
      size: pageSize,
    });
    const alreadyBooked = !hasUser ? false : this.props.bookings.length > 0;

    // set filter values
    this.setState({
      filter: hasUser,
      alreadyBooked: alreadyBooked,
      loadingBookings: false,
    });
  };

  addBooking = async () => {
    this.setBookConfirmVisible(false);
    this.setState({ searching: true, loading: true });

    const timestamp = new Date().getTime();
    const orderId = `S_${this.props.classId}_${this.props.sessionId}_${this.props.user.id}_${timestamp}`;
    await this.props.bookSession(
      orderId,
      this.props.session.fee,
      "Initial Booking"
    );
    if (this.props.bookError) {
      message.error("There was an error: ", this.props.bookError);
    } else {
      message.success("Booking was successful");
      this.clearFilter();
    }
    this.setState({ searching: false, loading: false });
  };

  //   exportDetails = async (values) => {
  //     this.setState({ loading: true });
  //     let from = values.range
  //       ? values.range[0].format("YYYY-MM-DD HH:mm:ss")
  //       : null;
  //     let to = values.range
  //       ? values.range[1].format("YYYY-MM-DD HH:mm:ss")
  //       : null;
  //     await this.props.exportStudentDetails(
  //       values.type,
  //       from,
  //       to,
  //       this.props.selected_class.name,
  //       this.props.session.name
  //     );
  //     if (this.props.exportError) {
  //       message.error("There was an error");
  //     } else {
  //       message.success("Export successful");
  //     }
  //     this.setState({
  //       loading: false,
  //       modalExportVisible: false,
  //     });
  //   };

  //   exportTx = async (values) => {
  //     this.setState({ loading: true });
  //     let from = values.range
  //       ? values.range[0].format("YYYY-MM-DD HH:mm:ss")
  //       : null;
  //     let to = values.range
  //       ? values.range[1].format("YYYY-MM-DD HH:mm:ss")
  //       : null;
  //     await this.props.exportTransactions(
  //       values.type,
  //       from,
  //       to,
  //       this.props.selected_class.name,
  //       this.props.session.name
  //     );
  //     if (this.props.exportError) {
  //       message.error("There was an error");
  //     } else {
  //       message.success("Export successful");
  //     }
  //     this.setState({
  //       loading: false,
  //       modalTxExportVisible: false,
  //     });
  //   };

  bookAgain = async (values) => {
    this.setState({ searching: true, loading: true });

    const timestamp = new Date().getTime();
    const orderId = `S_${this.props.classId}_${this.props.sessionId}_${this.props.user.id}_${timestamp}`;
    await this.props.bookSession(
      orderId,
      this.props.session.fee,
      "Initial Booking"
    );
    if (this.props.bookError) {
      message.error("There was an error");
    } else {
      message.success("Booking was successful");
    }
    this.setState({
      searching: false,
      loading: false,
      modalBookVisible: false,
    });
  };
  resetTime = async (values) => {
    this.setState({ searching: true, loading: true });
    await this.props.resetVideoPlay(this.props.user.id, values.note);
    if (this.props.resetError) {
      message.error("There was an error");
    } else {
      message.success("Time reset was successful");
    }
    this.setState({
      searching: false,
      loading: false,
      modalTimeVisible: false,
    });
  };

  onKeyPress = async ({ keyCode }) => {
    switch (keyCode) {
      case 27: //Esc
        if (this.state.filter) this.clearFilter();
        break;
      case 81: //q
        if (
          this.state.filter &&
          !this.state.modalBookVisible &&
          !this.state.modalTimeVisible
        )
          this.setBookVisible(true);
        break;
      case 65: //a
        if (
          this.state.filter &&
          !this.state.modalTimeVisible &&
          !this.state.modalBookVisible
        )
          this.setTimeVisible(true);
        break;
      case 66: //b
        if (
          this.state.filter &&
          !this.state.bookConfirmVisible &&
          !this.state.alreadyBooked
        ) {
          const btn = document.getElementById("bookbtn");
          btn.click(); //FIXME: do this in a better way!!
        }
        break;
      case 89: //y
        if (this.state.filter && this.state.bookConfirmVisible)
          this.addBooking(true);
        break;
      case 78: //n
        if (this.state.filter && this.state.bookConfirmVisible)
          this.setBookConfirmVisible(false);
        break;

      default:
        break;
    }
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyPress);
    this.loadBookings();
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyPress);
  }
  render() {
    const { session, sessionId, bookings, user, classId, selected_class } =
      this.props;
    let listData = bookings;
    let selectedUser = this.state.filter ? user : null;

    if (
      session != null &&
      isLoaded(session, bookings) &&
      session.id === sessionId
    ) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Session Bookings"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text>{session.name}</Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Avatar shape="square" src={session.coverUrl} />}
              >
                <Row>
                  <Col span={24} justify="center">
                    <CustomModal
                      loading={this.state.loading}
                      visible={this.state.modalBookVisible}
                      onCreate={this.bookAgain}
                      onCancel={() => {
                        this.setBookVisible(false);
                      }}
                      title={"Booking again"}
                      okText={"Book"}
                      itemName={"reason"}
                      itemLabel={"Provide a reason for this booking"}
                    />
                    <CustomModal
                      loading={this.state.loading}
                      visible={this.state.modalTimeVisible}
                      onCreate={this.resetTime}
                      onCancel={() => {
                        this.setTimeVisible(false);
                      }}
                      title={"Reset video play started time"}
                      okText={"Reset Time"}
                      itemName={"note"}
                      itemLabel={"Please provide a reason"}
                    />
                    <Card
                      title={"Quick Book"}
                      hoverable
                      extra={
                        this.state.filter
                          ? [
                              <Button
                                key={1}
                                onClick={this.clearFilter}
                                disabled={this.state.loading}
                              >
                                Clear Filter
                              </Button>,
                            ]
                          : []
                      }
                    >
                      <Input.Search
                        placeholder="+94123456789"
                        onSearch={(phone) => this.searchForUser(phone)}
                        onChange={this.updateQuery}
                        value={this.state.query}
                        loading={this.state.searching}
                        maxLength={12}
                        style={{ marginBottom: "10px" }}
                      />
                      {selectedUser !== null && selectedUser !== undefined ? (
                        this.state.searching ? (
                          <Spin>
                            <UserPreview user={selectedUser} />
                          </Spin>
                        ) : (
                          <UserPreview
                            user={selectedUser}
                            btn={
                              this.state.alreadyBooked ? (
                                <Space direction="vertical">
                                  <Tag color="green">Already Booked</Tag>
                                  <Button
                                    onClick={() => {
                                      this.setBookVisible(true);
                                    }}
                                  >
                                    Book Again[Q]
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      this.setTimeVisible(true);
                                    }}
                                  >
                                    Reset Time[A]
                                  </Button>
                                </Space>
                              ) : (
                                <Popconfirm
                                  title="Please confirm before adding"
                                  onConfirm={this.addBooking}
                                  onCancel={() => {
                                    this.setBookConfirmVisible(false);
                                  }}
                                  okText="[Y]es"
                                  cancelText="[N]o"
                                  style={{ float: "right" }}
                                >
                                  <Button
                                    id="bookbtn"
                                    type="primary"
                                    onClick={() =>
                                      this.setBookConfirmVisible(true)
                                    }
                                  >
                                    [B]ook
                                  </Button>
                                </Popconfirm>
                              )
                            }
                          />
                        )
                      ) : this.state.searching ? (
                        <Spin>
                          <Empty description={"Searching for a user"} />
                        </Spin>
                      ) : user !== null ? (
                        <Empty description={"No user found"} />
                      ) : null}
                    </Card>
                  </Col>
                  <Col span={24} justify="center">
                    {/* <ExportModal
                      loading={this.state.loading}
                      visible={this.state.modalExportVisible}
                      onOk={this.exportDetails}
                      onCancel={() => {
                        this.setExportVisible(false);
                      }}
                      title={"Export Bookings"}
                      okText={"Export"}
                    />
                    <TxExportModal
                      loading={this.state.loading}
                      visible={this.state.modalTxExportVisible}
                      onOk={this.exportTx}
                      onCancel={() => {
                        this.setTxExportVisible(false);
                      }}
                      title={"Export Transactions"}
                      okText={"Export"}
                    /> */}
                    <Card
                      title={"Bookings"}
                      style={{ margin: "1rem 0 0 0 " }}
                      extra={
                        <Space>
                          <Button
                            onClick={this.loadBookings}
                            disabled={this.state.loadingBookings}
                          >
                            {this.state.loadingBookings ? (
                              <LoadingOutlined />
                            ) : (
                              <ReloadOutlined />
                            )}
                          </Button>
                          <NavLink
                            to="./bookings/import"
                            target="_blank"
                            key={1}
                          >
                            <Button>Import</Button>
                          </NavLink>
                          {/* <Button
                            onClick={() => {
                              this.setExportVisible(true);
                            }}
                            key={2}
                          >
                            Export Bookings
                          </Button>
                          <Button
                            onClick={() => {
                              this.setTxExportVisible(true);
                            }}
                            key={3}
                          >
                            Export Transactions
                          </Button> */}
                        </Space>
                      }
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={listData}
                        pagination={
                          <Pagination
                            current={this.state.currentPage}
                            defaultPageSize={this.state.pageSize}
                            onChange={this.handleOnChangePagination}
                          />
                        }
                        renderItem={(booking) => (
                          <StudentInfoListItem
                            uid={booking.student_uid}
                            time={booking.booked_time}
                            orderId={`S_${classId}_${sessionId}_${booking.student_uid}`}
                            paymentType={
                              booking.is_manual_booking
                                ? "Manual Booking"
                                : "Paid Booking"
                            }
                            callback={this.setFilter}
                          />
                        )}
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Session Bookings"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Session is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const UserPreview = (props) => {
  const { user, btn } = props;
  return (
    <Card>
      <Row>
        <Col flex="auto">
          <p style={{ marginBottom: ".1em", fontWeight: "bold" }}>
            {user.data().firstName + " " + user.data().lastName}
          </p>
          <p style={{ marginBottom: ".1em" }}>
            {user.data().gradeName + ", " + user.data().streamName}
          </p>
          <p style={{ marginBottom: ".1em" }}>{user.data().schoolName}</p>
        </Col>
        <Col>{btn}</Col>
      </Row>
    </Card>
  );
};

const CustomModal = ({
  loading,
  visible,
  onCreate,
  onCancel,
  title,
  okText,
  itemName,
  itemLabel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name={itemName}
          label={itemLabel}
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ExportModal = ({ loading, visible, onOk, onCancel, title, okText }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name="range"
          label="Date Range"
          rules={[
            {
              required: false,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <DatePicker.RangePicker showTime />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
          initialValue={"ALL"}
        >
          <Select>
            <Select.Option value="ALL">ALL</Select.Option>
            <Select.Option value="MANUAL">MANUAL</Select.Option>
            <Select.Option value="ONLINE">ONLINE</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TxExportModal = ({ loading, visible, onOk, onCancel, title, okText }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name="range"
          label="Date Range"
          rules={[
            {
              required: false,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <DatePicker.RangePicker showTime />
        </Form.Item>
        <Form.Item
          name="payment_type"
          label="Type"
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
          initialValue={"ALL"}
        >
          <Select>
            <Select.Option value="ALL">ALL</Select.Option>
            <Select.Option value="MANUAL">MANUAL</Select.Option>
            <Select.Option value="ONLINE">ONLINE</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state, routeProps) => {
  return {
    bookings: state.booking.sessionBookings
      ? state.booking.sessionBookings
      : [],
    session: state.firestore.ordered.selected_session
      ? state.firestore.ordered.selected_session[0]
      : null,
    selected_class: state.firestore.ordered.selected_class
      ? state.firestore.ordered.selected_class[0]
      : null,
    classId: routeProps.match.params.classId,
    sessionId: routeProps.match.params.sessionId,
    user: state.booking.users ? state.booking.users.docs[0] : null,
    bookError: state.booking.bookError,
    resetError: state.booking.resetError,
    exportError: state.booking.exportError,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserFromPhone: (phone) => dispatch(getUserFromPhone(phone)),
    getSessionBookings: (data) => dispatch(getSessionBookings(data)),
    bookSession: (orderId, amount, reason) =>
      dispatch(
        bookSession({
          orderId: orderId,
          amount: amount,
          reason: reason,
        })
      ),
    resetVideoPlay: (userUid, note) =>
      dispatch(
        resetVideoPlay({
          class_uid: props.match.params.classId,
          session_uid: props.match.params.sessionId,
          student_uid: userUid,
          note: note,
        })
      ),
    exportStudentDetails: (type, from, to, class_name, session_name) =>
      dispatch(
        exportSessionBookedStudentDetails({
          class_uid: props.match.params.classId,
          session_uid: props.match.params.sessionId,
          type: type,
          from: from,
          to: to,
          class_name,
          session_name,
        })
      ),
    exportTransactions: (payment_type, from, to, class_name, session_name) =>
      dispatch(
        exportTransactions({
          type: "SESSION",
          class_uid: props.match.params.classId,
          session_uid: props.match.params.sessionId,
          payment_type,
          from,
          to,
          class_name,
          session_name,
        })
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "classes",
      doc: routeProps.match.params.classId,
      storeAs: "selected_class",
    },
    {
      collection: "classes",
      doc: routeProps.match.params.classId,
      subcollections: [
        { collection: "sessions", doc: routeProps.match.params.sessionId },
      ],
      storeAs: "selected_session",
    },
  ])
)(SessionBookings);