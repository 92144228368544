export const addNewPost = (data) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();
      var res;
      console.log(data);
      if (data.type === "IMG") {
        var formData = new FormData();
        formData.append("image", data.file);
        res = await api.post("/api/posts/uploadCover", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        });
      }
      var out = {
        title: data.title,
        description: data.description,
        contentType: data.type,
        contentUrl: data.type === "VID" ? data.vidUrl : res.data?.data[0]['uid'],
      };
      console.log(out);
      var created = await api.post("/api/posts", out, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });

      dispatch({ type: "POST_ADD_SUCCESS", res: created });
    } catch (err) {
      dispatch({ type: "POST_ADD_ERROR", err });
    }
  };
};

export const editPost = (postId, { key, value }) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();
      var res = await api.patch("/api/posts", {
        postUid: postId,
        [key]: value,
      },{
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      console.log(err);
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const publishPost = (postId) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    try {
      const res = await firestore.collection("posts").doc(postId).update({
        status: "PUBLISHED",
        publishedAt: firestore.FieldValue.serverTimestamp(),
      });

      dispatch({ type: "EDIT_SUCCESS", res: "" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const draftPost = (postId) => {
  return async (dispatch, getState, { api, getFirestore }) => {
    const firestore = getFirestore();
    try {
      const res = await firestore.collection("posts").doc(postId).update({
        status: "DRAFT",
      });

      dispatch({ type: "EDIT_SUCCESS", res: "" });
    } catch (err) {
      console.log(err);
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};
