import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Skeleton,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message,
  Badge,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  OrderedListOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";

import ImgCrop from "antd-img-crop";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { delLiveSession } from "../../../store/actions/onlineClass";
import moment from "moment";
import { aws } from "../../../utils/api";

const { Paragraph, Text } = Typography;

class Session extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    deleting: false,
    saving: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    imageUrl: null,
    bookingCount: null,
  };
  showModal = () => {
    this.setState({
      imageUrl: this.props.session.cover,
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };
  handleChange = (val) => {
    console.log(val);
    this.state.fileList.push({
      uid: val.file.uid,
      status: "done",
      name: val.file.name,
      thumbUrl: val.file,
      url: val.file,
    });
  };
  handleCancel = () => {
    if (!this.state.saving)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val) => {
    console.log(val);
    if (!this.state.imageUrl) {
      message.error("Select a cover image");
      return;
    }
    this.setState({
      saving: true,
    });
    await this.props.addSession({
      ...val,
      vidStart: val.startEnd[0].format(),
      vidEnd: val.startEnd[1].format(),
      cover: this.state.imageUrl,
    });
    this.setState({
      saving: false,
    });
  };

  deleteSession = async () => {
    this.setState({
      deleting: true,
    });
    await this.props.delSession();
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleCoverChange = (val) => {
    console.log(val);
    this.setState({ imageUrl: undefined });
    if ("response" in val.file) {
      if (val.file.response.status) {
        this.setState({
          imageUrl: val.file.response.data.url,
          uploading: false,
        });
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      this.setState({ uploading: true });
    }
    this.setState({ coverFile: val.file });
  };

  fetchBookingCount = async () => {
    this.setState({
      bookingCount: null,
    });

    const classUid = this.props.selected?.id;
    const liveSessionUid = this.props.session?.id;
    const token = this.props.token;

    if (classUid && liveSessionUid && token) {
      const res = await aws.post(
        "/payments/getBookingCount",
        {
          entityType: "L",
          entityUid: `${classUid}_${liveSessionUid}`,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // set count
      const count = res?.data?.data?.count;
      this.setState({
        bookingCount: count ? count : 0,
      });
    }
  };

  componentDidMount() {
    this.fetchBookingCount();
  }

  render() {
    const classId = this.props.selected.id;
    const { session } = this.props;
    const { imageUrl } = this.state;
    return (
      <Badge.Ribbon
        color={session.isPublished ? "green" : "volcano"}
        text={session.isPublished ? "Published" : "NOT PUBLISHED"}
      >
        <Card
          key={session.id}
          actions={[
            <NavLink
              to={"/classes/edit/" + classId + "/live-sessions/" + session.id}
              disabled={this.state.deleting}
              key="edit"
            >
              <Button className="session-action" type="text" key="edit">
                <EditOutlined />
              </Button>
            </NavLink>,
            <NavLink
              to={
                "/classes/preview/" +
                classId +
                "/live-sessions/" +
                session.id +
                "/bookings"
              }
              disabled={this.state.deleting}
              key="bookings"
            >
              <Tooltip title="View bookings">
                <Button className="session-action" type="text" key="bookings">
                  <OrderedListOutlined />
                </Button>
              </Tooltip>
            </NavLink>,
            <Popconfirm
              title="Are you sure you want to delete this session?"
              onConfirm={this.deleteSession}
              okText="Yes"
              cancelText="No"
              disabled={this.state.deleting}
              key="delete"
            >
              <Button
                className="session-action"
                type="text"
                loading={this.state.deleting}
                disabled={this.state.deleting}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>,
          ]}
          cover={<img alt="Cover" src={session.coverUrl} />}
          hoverable
        >
          <Skeleton loading={this.state.loading} active>
            <Card.Meta
              style={{ textAlign: "center" }}
              title={
                <Text
                  ellipsis={{
                    rows: 1,
                    expandable: true,
                  }}
                >
                  {session.name}
                </Text>
              }
            />
            <div style={{ textAlign: "center", margin: "10px 0 10px 0" }}>
              {this.state.bookingCount == null && <LoadingOutlined />}
              {this.state.bookingCount == 0 && (
                <Tag style={{ display: "inline-block" }}>No bookings yet</Tag>
              )}
              {this.state.bookingCount > 0 && (
                <Tag color="blue">
                  Students Booked : {this.state.bookingCount}
                </Tag>
              )}

              {this.state.bookingCount != null &&
                this.state.bookingCount >= 0 && (
                  <ReloadOutlined onClick={this.fetchBookingCount} />
                )}
            </div>
            <Paragraph
              ellipsis={{ rows: 2, expandable: false, symbol: "more" }}
            >
              {session.description}
            </Paragraph>
            <Modal
              centered
              title={"Edit session"}
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              destroyOnClose={true}
              okButtonProps={{ loading: this.state.saving }}
              cancelButtonProps={{ disabled: this.state.saving }}
              width={720}
            >
              <Form
                onFinish={this.onFinish}
                validateMessages={{ required: "${label} is required!" }}
                ref={this.formRef}
                layout="vertical"
              >
                <Form.Item
                  name={"cover"}
                  label="Cover Image"
                  className={"question-image-list"}
                >
                  <ImgCrop rotate>
                    <Upload
                      accept={".jpg"}
                      listType="picture-card"
                      className="class-cover-uploader"
                      onPreview={this.handlePreview}
                      disabled={this.state.loading || this.state.uploading}
                      onChange={this.handleCoverChange}
                      showUploadList={false}
                      action={
                        process.env.REACT_APP_CLOUDFUNCTIONS_URL +
                        "/api/sessions/uploadCover"
                      }
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <div>
                          {this.state.uploading ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div className="ant-upload-text">Upload</div>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
                <Form.Item
                  name={"name"}
                  label="Session Name"
                  rules={[{ required: true }]}
                  initialValue={session.name}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"subHeading"}
                  label="Session Subtitle"
                  rules={[{ required: true }]}
                  initialValue={session.subHeading}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"description"}
                  label="Description"
                  rules={[{ required: true }]}
                  initialValue={session.description}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  name={"fee"}
                  label="Fee (Rs.)"
                  rules={[{ required: true }]}
                  initialValue={session.fee}
                >
                  <InputNumber min={0} />
                </Form.Item>

                <Form.Item
                  name={"videoUrl"}
                  label="Video URL"
                  rules={[{ required: true }]}
                  initialValue={session.videoUrl}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Session Time"
                  name="startEnd"
                  rules={[{ required: true }]}
                  initialValue={[
                    moment(session.vidStart),
                    moment(session.vidEnd),
                  ]}
                >
                  <DatePicker.RangePicker
                    disabled={this.state.loading}
                    showTime
                  />
                </Form.Item>
                <Form.Item
                  name={"should_be_booked_before"}
                  label="Booked before(Minutes)"
                  rules={[{ required: true }]}
                  initialValue={session.should_be_booked_before}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name={"extensionFee"}
                  label="Extension Fee (Rs.)"
                  rules={[{ required: true }]}
                  initialValue={session.extensionFee}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name={"extensionTime"}
                  label="Extension Time (Minutes)"
                  rules={[{ required: true }]}
                  initialValue={session.extensionTime}
                >
                  <InputNumber min={0} />
                </Form.Item>
                <Form.Item
                  name={"extensionUrl"}
                  label="Extension Video URL"
                  rules={[{ required: true }]}
                  initialValue={session.extensionUrl}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              visible={this.state.previewVisible}
              title={this.state.previewTitle}
              footer={null}
              onCancel={() =>
                this.setState({
                  previewVisible: false,
                })
              }
            >
              <img
                alt="classple"
                style={{ width: "100%" }}
                src={this.state.previewImage}
              />
            </Modal>
          </Skeleton>
        </Card>
      </Badge.Ribbon>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.firebase.profile.token.token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    delSession: () =>
      dispatch(delLiveSession(props.selected.id, props.session.id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Session);