import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Skeleton,
  Typography,
  Avatar,
  Descriptions,
  Space,
  Form,
  Tabs,
  List,
  Input,
  Spin,
  Empty,
  Button,
  Result,
  Popconfirm,
  Pagination,
  Tag,
  message,
  Radio,
  Modal,
  DatePicker,
  Select,
  Table,
} from "antd";
import moment from "moment";
import StudentInfoListItem from "../classes/components/StudentInfoListItem";
import {
  getUserFromPhone,
  bookExam,
  exportExamBookedStudentDetails,
} from "../../store/actions/booking";
import { exportTransactions } from "../../store/actions/transaction";
import { NavLink } from "react-router-dom";
const { Paragraph, Text, Link } = Typography;

class PaperBookings extends Component {
  state = {
    modalTimeVisible: false,
    modalBookVisible: false,
    modalBookingExportVisible: false,
    modalTxExportVisible: false,
    loading: false,
    searching: false,
    filteredData: [],
    filter: false,
    alreadyBooked: false,
    query: "+94",
    bookConfirmVisible: false,
  };

  setTimeVisible = (val) => {
    this.setState({
      modalTimeVisible: val,
    });
  };
  setBookVisible = (val) => {
    this.setState({
      modalBookVisible: val,
    });
  };
  setExportVisible = (val) => {
    this.setState({
      modalBookingExportVisible: val,
    });
  };
  setTxExportVisible = (val) => {
    this.setState({
      modalTxExportVisible: val,
    });
  };
  setBookConfirmVisible = (val) => {
    this.setState({
      bookConfirmVisible: val,
    });
  };

  searchForUser = async (phone) => {
    this.setState({ searching: true });
    await this.props.getUserFromPhone(phone);
    this.updateFilter();
    this.setState({ searching: false });
  };

  updateQuery = (e) => {
    if (e.target.value.trim().includes("+94")) {
      this.setState({ query: e.target.value });
    } else {
      this.setState({ query: "+94" });
    }
  };

  setFilter = (phone) => {
    this.setState({
      query: phone,
    });
    this.searchForUser(phone);
  };

  clearFilter = () => {
    this.setState({
      filteredData: this.props.bookings,
      filter: false,
      alreadyBooked: false,
      query: "+94",
    });
  };

  updateFilter = async () => {
    let { user, bookings } = this.props;
    let filteredBookings = [];
    if (user !== null && user !== undefined && isLoaded(bookings)) {
      bookings.map((booking) => {
        if (user.id === booking.student_uid) {
          filteredBookings.push(booking);
        }
      });
      if (filteredBookings.length > 0) {
        this.setState({
          alreadyBooked: true,
        });
      } else {
        this.setState({
          alreadyBooked: false,
        });
      }
      this.setState({
        filteredData: filteredBookings,
        filter: true,
      });
    } else {
      this.setState({
        filteredData: this.props.bookings,
        filter: false,
        alreadyBooked: false,
      });
    }
  };

  addBooking = async () => {
    this.setBookConfirmVisible(false);
    this.setState({ searching: true, loading: true });
    await this.props.bookExam(
      this.props.user.id,
      this.props.exam.fee,
      "Initial Booking"
    );
    if (this.props.bookError) {
      message.error("There was an error: ", this.props.bookError);
    } else {
      message.success("Booking was successful");
      this.clearFilter();
    }
    this.setState({ searching: false, loading: false });
  };

  exportBookings = async (values) => {
    this.setState({ loading: true });
    let from = values.range
      ? values.range[0].format("YYYY-MM-DD HH:mm:ss")
      : null;
    let to = values.range
      ? values.range[1].format("YYYY-MM-DD HH:mm:ss")
      : null;
    await this.props.exportStudentDetails(values.type, from, to);
    if (this.props.exportError) {
      message.error("There was an error");
    } else {
      message.success("Export successful");
    }
    this.setState({
      loading: false,
      modalBookingExportVisible: false,
    });
  };
  exportTx = async (values) => {
    this.setState({ loading: true });
    let from = values.range
      ? values.range[0].format("YYYY-MM-DD HH:mm:ss")
      : null;
    let to = values.range
      ? values.range[1].format("YYYY-MM-DD HH:mm:ss")
      : null;
    await this.props.exportTransactions(values.type, from, to);
    if (this.props.exportError) {
      message.error("There was an error");
    } else {
      message.success("Export successful");
    }
    this.setState({
      loading: false,
      modalTxExportVisible: false,
    });
  };

  bookAgain = async (values) => {
    this.setState({ searching: true, loading: true });
    await this.props.bookExam(
      this.props.user.id,
      this.props.exam.fee,
      values.reason
    );
    if (this.props.bookError) {
      message.error("There was an error");
    } else {
      message.success("Booking was successful");
    }
    this.setState({
      searching: false,
      loading: false,
      modalBookVisible: false,
    });
  };
  resetTime = async (values) => {
    this.setState({ searching: true, loading: true });
    await this.props.resetVideoPlay(this.props.user.id, values.note);
    if (this.props.resetError) {
      message.error("There was an error");
    } else {
      message.success("Time reset was successful");
    }
    this.setState({
      searching: false,
      loading: false,
      modalTimeVisible: false,
    });
  };

  onKeyPress = async ({ keyCode }) => {
    switch (keyCode) {
      case 27: //Esc
        if (this.state.filter) this.clearFilter();
        break;
      case 81: //q
        if (
          this.state.filter &&
          !this.state.modalBookVisible &&
          !this.state.modalTimeVisible
        )
          this.setBookVisible(true);
        break;
      case 65: //a
        if (
          this.state.filter &&
          !this.state.modalTimeVisible &&
          !this.state.modalBookVisible
        )
          this.setTimeVisible(true);
        break;
      case 66: //b
        if (
          this.state.filter &&
          !this.state.bookConfirmVisible &&
          !this.state.alreadyBooked
        ) {
          const btn = document.getElementById("bookbtn");
          btn.click(); //FIXME: do this in a better way!!
        }
        break;
      case 89: //y
        if (this.state.filter && this.state.bookConfirmVisible)
          this.addBooking(true);
        break;
      case 78: //n
        if (this.state.filter && this.state.bookConfirmVisible)
          this.setBookConfirmVisible(false);
        break;

      default:
        break;
    }
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyPress);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyPress);
  }
  render() {
    const { exam, examId, bookings, user } = this.props;
    let listData = this.state.filter ? this.state.filteredData : bookings;
    let selectedUser = this.state.filter ? user : null;

    if (exam != null && isLoaded(exam, bookings) && exam.id === examId) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Exam Bookings"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text>{exam.name}</Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Avatar shape="square" src={exam.cover} />}
              >
                <Row>
                  <Col span={24} justify="center">
                    <CustomModal
                      loading={this.state.loading}
                      visible={this.state.modalBookVisible}
                      onCreate={this.bookAgain}
                      onCancel={() => {
                        this.setBookVisible(false);
                      }}
                      title={"Booking again"}
                      okText={"Book"}
                      itemName={"reason"}
                      itemLabel={"Provide a reason for this booking"}
                    />
                    <CustomModal
                      loading={this.state.loading}
                      visible={this.state.modalTimeVisible}
                      onCreate={this.resetTime}
                      onCancel={() => {
                        this.setTimeVisible(false);
                      }}
                      title={"Reset video play started time"}
                      okText={"Reset Time"}
                      itemName={"note"}
                      itemLabel={"Please provide a reason"}
                    />
                    <Card
                      title={"Quick Book"}
                      hoverable
                      extra={
                        this.state.filter
                          ? [
                              <Button
                                key={1}
                                onClick={this.clearFilter}
                                disabled={this.state.loading}
                              >
                                Clear Filter
                              </Button>,
                            ]
                          : []
                      }
                    >
                      <Input.Search
                        placeholder="+94123456789"
                        onSearch={(phone) => this.searchForUser(phone)}
                        onChange={this.updateQuery}
                        value={this.state.query}
                        loading={this.state.searching}
                        maxLength={12}
                        style={{ marginBottom: "10px" }}
                      />
                      {selectedUser !== null && selectedUser !== undefined ? (
                        this.state.searching ? (
                          <Spin>
                            <UserPreview user={selectedUser} />
                          </Spin>
                        ) : (
                          <UserPreview
                            user={selectedUser}
                            btn={
                              this.state.alreadyBooked ? (
                                <Space direction="vertical">
                                  <Tag color="green">Already Booked</Tag>
                                  <Button
                                    onClick={() => {
                                      this.setBookVisible(true);
                                    }}
                                  >
                                    Book Again[Q]
                                  </Button>
                                  {/* <Button
                                    onClick={() => {
                                      this.setTimeVisible(true);
                                    }}
                                  >
                                    Reset Time[A]
                                  </Button> */}
                                </Space>
                              ) : (
                                <Popconfirm
                                  title="Please confirm before adding"
                                  onConfirm={this.addBooking}
                                  onCancel={() => {
                                    this.setBookConfirmVisible(false);
                                  }}
                                  okText="[Y]es"
                                  cancelText="[N]o"
                                  style={{ float: "right" }}
                                >
                                  <Button
                                    id="bookbtn"
                                    type="primary"
                                    onClick={() =>
                                      this.setBookConfirmVisible(true)
                                    }
                                  >
                                    [B]ook
                                  </Button>
                                </Popconfirm>
                              )
                            }
                          />
                        )
                      ) : this.state.searching ? (
                        <Spin>
                          <Empty description={"Searching for a user"} />
                        </Spin>
                      ) : user !== null ? (
                        <Empty description={"No user found"} />
                      ) : null}
                    </Card>
                  </Col>
                  <Col span={24} justify="center">
                    <BookingExportModal
                      loading={this.state.loading}
                      visible={this.state.modalBookingExportVisible}
                      onOk={this.exportBookings}
                      onCancel={() => {
                        this.setExportVisible(false);
                      }}
                      title={"Export Bookings"}
                      okText={"Export"}
                    />
                    <TxExportModal
                      loading={this.state.loading}
                      visible={this.state.modalTxExportVisible}
                      onOk={this.exportTx}
                      onCancel={() => {
                        this.setTxExportVisible(false);
                      }}
                      title={"Export Transactions"}
                      okText={"Export"}
                    />
                    <Card
                      title={"Bookings"}
                      style={{ margin: "1rem 0 0 0 " }}
                      extra={
                        <Space>
                          <NavLink
                            to={
                              "/papers/preview/" +
                              this.props.examId +
                              "/bookings/import"
                            }
                            target="_blank"
                            key={1}
                          >
                            <Button>Import</Button>
                          </NavLink>
                          <Button
                            onClick={() => {
                              this.setExportVisible(true);
                            }}
                            key={2}
                          >
                            Export Bookings
                          </Button>
                          <Button
                            onClick={() => {
                              this.setTxExportVisible(true);
                            }}
                            key={3}
                          >
                            Export Transactions
                          </Button>
                        </Space>
                      }
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={listData}
                        pagination={
                          <Pagination showSizeChanger defaultPageSize={10} />
                        }
                        renderItem={(booking) => {
                          return (
                            <StudentInfoListItem
                              uid={booking.student_uid}
                              time={booking.booked_time}
                              orderId={`P_${examId}_${booking.student_uid}`}
                              callback={this.setFilter}
                            />
                          );
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Exam Bookings"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Loading please wait..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const UserPreview = (props) => {
  const { user, btn } = props;
  return (
    <Card>
      <Row>
        <Col flex="auto">
          <p style={{ marginBottom: ".1em", fontWeight: "bold" }}>
            {user.data().firstName + " " + user.data().lastName}
          </p>
          <p style={{ marginBottom: ".1em" }}>
            {user.data().gradeName + ", " + user.data().streamName}
          </p>
          <p style={{ marginBottom: ".1em" }}>{user.data().schoolName}</p>
        </Col>
        <Col>{btn}</Col>
      </Row>
    </Card>
  );
};

const CustomModal = ({
  loading,
  visible,
  onCreate,
  onCancel,
  title,
  okText,
  itemName,
  itemLabel,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name={itemName}
          label={itemLabel}
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const BookingExportModal = ({
  loading,
  visible,
  onOk,
  onCancel,
  title,
  okText,
}) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name="range"
          label="Date Range"
          rules={[
            {
              required: false,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <DatePicker.RangePicker showTime />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
          initialValue={"ALL"}
        >
          <Select>
            <Select.Option value="ALL">ALL</Select.Option>
            <Select.Option value="MANUAL">MANUAL</Select.Option>
            <Select.Option value="ONLINE">ONLINE</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TxExportModal = ({ loading, visible, onOk, onCancel, title, okText }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onOk(values);
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name="range"
          label="Date Range"
          rules={[
            {
              required: false,
              message: "This field cannot be empty!",
            },
          ]}
        >
          <DatePicker.RangePicker showTime />
        </Form.Item>
        <Form.Item
          name="payment_type"
          label="Type"
          rules={[
            {
              required: true,
              message: "This field cannot be empty!",
            },
          ]}
          initialValue={"ALL"}
        >
          <Select>
            <Select.Option value="ALL">ALL</Select.Option>
            <Select.Option value="MANUAL">MANUAL</Select.Option>
            <Select.Option value="ONLINE">ONLINE</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state, routeProps) => {
  return {
    bookings: state.firestore.ordered.selected_exam_bookings,
    exam: state.firestore.ordered.selected_exam
      ? state.firestore.ordered.selected_exam[0]
      : null,
    examId: routeProps.match.params.id,
    user: state.booking.users ? state.booking.users.docs[0] : null,
    bookError: state.booking.bookError,
    resetError: state.booking.resetError,
    exportError: state.booking.exportError,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserFromPhone: (phone) => dispatch(getUserFromPhone(phone)),
    bookExam: (userUid, amount, reason) =>
      dispatch(
        bookExam({
          examUid: props.match.params.id,
          userUid: userUid,
          amount: amount,
          reason: reason,
        })
      ),
    exportStudentDetails: (type, from, to, paper_name) =>
      dispatch(
        exportExamBookedStudentDetails({
          paper_uid: props.match.params.id,
          type,
          from,
          to,
          paper_name,
        })
      ),
    exportTransactions: (payment_type, from, to, paper_name) =>
      dispatch(
        exportTransactions({
          type: "PAPER",
          paper_uid: props.match.params.id,
          payment_type,
          from,
          to,
          paper_name,
        })
      ),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "exams",
      doc: routeProps.match.params.id,
      storeAs: "selected_exam",
    },
    {
      collection: "exams",
      doc: routeProps.match.params.id,
      subcollections: [
        {
          collection: "booked_students",
          orderBy: ["booked_time", "desc"],
        },
      ],
      storeAs: "selected_exam_bookings",
    },
  ])
)(PaperBookings);
