import {
  Button,
  Card,
  Form,
  Input,
  List,
  Modal,
  Typography,
  Upload,
  message,
  Select,
  Space,
  Tooltip,
} from "antd";
import { LoadingOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { addClassCardCategory } from "../../../store/actions/onlineClass";
import { connect } from "react-redux";

const { Text } = Typography;
const { Option } = Select;

const CategoriesList = (props) => {
  const {
    data,
    onCategorySelect,
    selectedCategoryId,
    addClassCardCategory,
    preview = true,
    role,
    teachers,
    currrentTeacher,
    filterTeacher,
    onChangeFilterTeacher,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCreate = async (data) => {
    setLoading(true);

    await addClassCardCategory({
      description: data.description,
      name: data.name,
      teacherUid: data.teacherUid,
      file: data.file,
    });
    setLoading(false);
    setShowModal(false);
  };

  return (
    <Card
      title={<Text strong>Categories</Text>}
      extra={[
        <Space>
          {role === "COMPANY_ADMIN" && (
            <Select
              placeholder="Teacher"
              value={filterTeacher}
              onChange={onChangeFilterTeacher}
              style={{ width: 200, marginRight: 10 }}
              allowClear
            >
              {teachers.map((teacher, index) => (
                <Option value={teacher.user} key={index}>
                  {teacher.name}
                </Option>
              ))}
            </Select>
          )}
        </Space>,
        <Space>
          {preview ? null : (
            <Button type="primary" onClick={() => setShowModal(true)}>
              Add new category
            </Button>
          )}
        </Space>,
      ]}
      style={{ margin: "1rem 1rem 0 1rem" }}
    >
      <List
        grid={{
          gutter: 3,
          column: 8,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Card
              onClick={() =>
                item.id === selectedCategoryId
                  ? onCategorySelect(null, null)
                  : onCategorySelect(item.id, item.name)
              }
              style={{ height: "100%" }}
              bodyStyle={{
                textAlign: "center",
                padding: "10px",
                backgroundColor:
                  item.id === selectedCategoryId ? "#1890ff" : "white",
              }}
              cover={<img alt="example" src={item.coverUrl} />}
              hoverable
              // actions={[
              //   <NavLink to={"/"} key="edit">
              //     <Tooltip title="Edit session">
              //       <Button className="session-action" type="text" key="edit">
              //         <EditOutlined />
              //       </Button>
              //     </Tooltip>
              //   </NavLink>,
              // ]}
            >
              <Text
                style={{
                  color: item.id === selectedCategoryId ? "white" : "",
                }}
                strong={item.id === selectedCategoryId}
              >
                {item.name}
              </Text>
            </Card>
          </List.Item>
        )}
      />
      <CategoryAdd
        visible={showModal}
        onCreate={onCreate}
        onCancel={() => setShowModal(false)}
        loading={loading}
        teacherUid={currrentTeacher}
        userRole={role}
        teachers={teachers}
      />
    </Card>
  );
};

const CategoryAdd = ({
  visible,
  onCreate,
  onCancel,
  loading,
  teacherUid,
  userRole,
  teachers,
}) => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  return (
    <Modal
      visible={visible}
      title="Add new category"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        if (!imageUrl) return message.warn("select a cover image");
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate({ ...values, file });
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <ImgCrop rotate>
          <Upload
            accept={".jpg"}
            listType="picture-card"
            className="cover-edit"
            disabled={uploading}
            showUploadList={false}
            beforeUpload={(e) => {
              setFile(e);
              var urlCreator = window.URL || window.webkitURL;
              setImageUrl(urlCreator.createObjectURL(e));
              return false;
            }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="cover" style={{ width: "100%" }} />
            ) : (
              <div>
                {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
              </div>
            )}
          </Upload>
        </ImgCrop>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {(userRole === "TEACHER" || userRole === "TEACHER_DATA_ENTRY") && (
          <Form.Item name={"teacherUid"} initialValue={teacherUid} hidden>
            <Input />
          </Form.Item>
        )}

        {userRole === "COMPANY_ADMIN" && (
          <Form.Item
            label="Teacher"
            name="teacherUid"
            rules={[{ required: true }]}
          >
            <Select
              disabled={loading}
              placeholder="Select teacher"
              style={{ width: "100%" }}
            >
              {Object.values(teachers).map((teacher) => (
                <Select.Option key={teacher.user} value={teacher.user}>
                  {teacher.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Description cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addClassCardCategory: (data) => dispatch(addClassCardCategory(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);