import React from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Row } from "antd";

const LoadingScreen = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Spin indicator={antIcon} />
    </Row>
  );
};

export default LoadingScreen;
