import fileDownload from "js-file-download";

export const getTxs = (data, filter, teacher_uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      const pageSize = 10;
      let user = await firestore
        .collection("users")
        .where("phoneNumber", "==", filter.phone)
        .get();
      let from = filter.from.toDate();
      let to = filter.to.toDate();

      let res;

      if (filter.orderId !== " ") {
        if (data.length > 0) {
          res = await firestore
            .collection("transactions")
            .where("teacher_uid", "==", teacher_uid)
            .where("order_id", "==", filter.orderId)
            .startAfter(data[data.length - 1])
            .get();
        } else {
          res = await firestore
            .collection("transactions")
            .where("teacher_uid", "==", teacher_uid)
            .where("order_id", "==", filter.orderId)
            .get();
        }
      } else {
        if (data.length > 0) {
          if (user.docs.length > 0 && user.docs[0].id !== undefined) {
            if (data.length >= pageSize) {
              res = await firestore
                .collection("transactions")
                .where("user_uid", "==", user.docs[0].id)
                .where("teacher_uid", "==", teacher_uid)
                .limit(pageSize)
                .orderBy("created_at", "desc")
                .where("created_at", ">=", from)
                .where("created_at", "<=", to)
                .startAfter(data[data.length - 1])
                .get();
            }
            // console.log(user.docs[0].id, teacher_uid, filter, res.docs);
          } else {
            res = await firestore
              .collection("transactions")
              .where("teacher_uid", "==", teacher_uid)
              .limit(pageSize)
              .orderBy("created_at", "desc")
              .where("created_at", ">=", from)
              .where("created_at", "<=", to)
              .startAfter(data[data.length - 1])
              .get();
          }
        } else {
          if (user.docs.length > 0 && user.docs[0].id !== undefined) {
            res = await firestore
              .collection("transactions")
              .where("user_uid", "==", user.docs[0].id)
              .where("teacher_uid", "==", teacher_uid)
              .limit(pageSize)
              .orderBy("created_at", "desc")
              .where("created_at", ">=", from)
              .where("created_at", "<=", to)
              .get();
          } else {
            res = await firestore
              .collection("transactions")
              .where("teacher_uid", "==", teacher_uid)
              .limit(pageSize)
              .orderBy("created_at", "desc")
              .where("created_at", ">=", from)
              .where("created_at", "<=", to)
              .get();
          }
        }
      }

      dispatch({ type: "FETCHED_TX", res });
    } catch (err) {
      dispatch({ type: "FETCH_ERROR", err });
    }
  };
};

export const getStudentByPhoneNumber = (phoneNumber) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      let studentSnaps = await firestore
        .collection("students")
        .where("phoneNumber", "==", phoneNumber)
        .get();
      if( !studentSnaps.empty ){
        const student = studentSnaps.docs[0];
        dispatch({ type: "GET_STUDENT_SUCCESS", res: student.id });
      }

    } catch (err) {
      dispatch({ type: "GET_STUDENT_ERROR", err });
    }
  };
};

export const getTransactionById = (transactionId) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.get(
        `/payments/transactions/${transactionId}`,
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      let transaction = res?.data?.data?.transaction;
      transaction.created_at = transaction.created_at?.replace('.000Z', '');
      dispatch({ type: "GET_TRANSACTION_SUCCESS", res: transaction });
    } catch (err) {
      dispatch({ type: "GET_TRANSACTION_ERROR", err });
    }
  };
};

export const exportTransactions = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/api/exportTransactionDetails",
        Object.assign(
          {},
          { type: data.type },
          { paper_uid: data.paper_uid },
          { class_uid: data.class_uid },
          { session_uid: data.session_uid },
          { payment_type: data.payment_type },
          data.from ? { from: data.from } : null,
          data.to ? { to: data.to } : null
        ),
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      var fileName = "";
      if (data.type === "PAPER") {
        fileName = `${data.paper_uid}_${
          data.payment_type
        }_${new Date().getTime()}`;
      } else if (data.type === "SESSION") {
        fileName = `${data.class_name}_${data.session_name}_${
          data.payment_type
        }_${new Date().getTime()}`;
      }

      if (data.from) {
        fileName = `${fileName}_${data.from
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }
      if (data.to) {
        fileName = `${fileName}_${data.to
          .replaceAll(" ", "")
          .replaceAll(":", "-")}`;
      }

      fileDownload(res.data, `${fileName}.csv`);
      dispatch({ type: "EXPORT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EXPORT_ERROR", err });
    }
  };
};
