import React from "react";
import { Result, Button, Row, Col } from "antd";

const NotFound = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 5 }}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={() => window.history.back()}>
              Go Back
            </Button>
          }
        />
        ,
      </Col>
    </Row>
  );
};

export default NotFound;
