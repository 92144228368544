import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBdHagiukKxqsXPRmDp6XLW-XPd5AKt5-U",
  authDomain: "viduna-live-system.firebaseapp.com",
  projectId: "viduna-live-system",
  storageBucket: "viduna-live-system.appspot.com",
  messagingSenderId: "760289412133",
  appId: "1:760289412133:web:7f47f70b612b4c25752bcc",
  measurementId: "G-KZ553KN01Y",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({});
firebase.functions();
export default firebase;
