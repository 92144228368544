import { message } from "antd";

const initState = {
  addError: null,
};

const coord = (state = initState, action) => {
  switch (action.type) {
    case "COORD_ADD_SUCCESS":
      return {
        ...state,
        addError: null,
      };
    case "COORD_ADD_ERROR":
      message.error(action.err.message);
      return {
        ...state,
        addError: "Coordinator add failed",
      };
    default:
      return state;
  }
};

export default coord;
