export const getVerifications = (data) => {
  return async (dispatch, getState, { aws }) => {
    try {
      dispatch({ type: "VERIFICATION_LOADING", loading: true });

      var res = await aws.post("/verifications", data);

      const records = res?.data?.data;

      if (res.data?.success) {
        dispatch({ type: "VERIFICATION_LOADING", loading: false });
        dispatch({ type: "GET_VERIFICATIONS", res: records });
      } else {
        dispatch({ type: "GET_VERIFICATIONS_ERROR", err: res.data?.message });
      }
    } catch (err) {
      dispatch({ type: "GET_VERIFICATIONS_ERROR", err });
      dispatch({ type: "VERIFICATION_LOADING", loading: false });
    }
  };
};

export const rejectVerification = (verificationId) => {
  return async (dispatch, getState, { aws }) => {
    try {
      dispatch({ type: "VERIFICATION_LOADING", loading: true });

      var res = await aws.delete(`/verifications/reject/${verificationId}`);

      if (res.data?.success) {
        dispatch({ type: "VERIFICATION_LOADING", loading: false });
        dispatch({ type: "REJECT_VERIFICATION_SUCCESS", res });
      } else {
        dispatch({
          type: "REJECT_VERIFICATION_ERROR",
          err: res.data?.message,
        });
      }
    } catch (error) {}
  };
};

export const approveVerification = (
  studentUid,
  deviceUid,
  onlyDevice = false
) => {
  return async (dispatch, getState, { aws }) => {
    try {
      const res = await aws.post(
        `/verifications/approve/${studentUid}/${deviceUid}?onlyDevice=${
          onlyDevice ? "true" : "false"
        }`
      );

      if (res.data?.success) {
        dispatch({ type: "APPROVE_SUCCESS", res });
      }
    } catch (err) {
      dispatch({ type: "APPROVE_ERROR", err });
    }
  };
};
export const disapproveVerification = (
  studentUid,
  deviceUid,
  onlyDevice = false
) => {
  return async (dispatch, getState, { aws }) => {
    try {
      const res = await aws.post(
        `/verifications/disapprove/${studentUid}/${deviceUid}?onlyDevice=${
          onlyDevice ? "true" : "false"
        }`
      );

      if (res.data?.success) {
        dispatch({ type: "DISAPPROVE_SUCCESS", res });
      }
    } catch (err) {
      dispatch({ type: "DISAPPROVE_ERROR", err });
    }
  };
};
