import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Layout, PageHeader, Row, Col, Card, Statistic, Skeleton, Table, Tag, Space, } from "antd";
import {
  ResponsiveContainer,
} from "recharts";
import { VideoCameraOutlined } from "@ant-design/icons";
import moment from "moment";

const { Content, Footer } = Layout;
const currentTime = new Date();

class Dashboard extends Component {
  getZoomColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Start',
        dataIndex: 'start',
        key: 'start',
      },
      {
        title: 'End',
        dataIndex: 'end',
        key: 'end',
      },
      {
        title: 'Status',
        key: 'tags',
        dataIndex: 'tags',
        render: tags => (
          <>
            {tags.map(tag => {
              let color = tag === 'ongoing' ? 'volcano' : 'green';
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: 'Meeting Link',
        key: 'link',
        dataIndex: 'link',
        render: (text) => (
          <Space size="middle">
            <a href={text} target="_blank" rel="noopener noreferrer">Open Meeting</a>
          </Space>
        ),
      },
    ];
  }

  getZoomData = (zoomMeetings) => {
    var items = [];
    items = zoomMeetings.map(item => {
      const startMoment = moment( new Date(item.zoom_start_time.seconds * 1000) );
      const endMoment = moment( new Date(item.zoom_end_time.seconds * 1000) );
      const now = moment();

      return {
        key: item.id,
        name: item.topic,
        start: startMoment.format('YYYY-MM-DD hh:mm A'),
        end: endMoment.format('YYYY-MM-DD hh:mm A'),
        tags: [ now.isAfter(startMoment) ? 'ongoing' : 'upcoming'],
        link: item.join_url
      }
    });

    return items;
  }

  render() {
    const { myProfile, role, zoomMeetings } = this.props;

    if (isLoaded(myProfile)) {
      return (
        <Layout className="site-layout">
          <PageHeader ghost={false} title="Admin Dashboard"></PageHeader>
          <Content style={{ margin: "1rem" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Card hoverable>
                  <Statistic
                    title="Welcome Back"
                    value={myProfile.name}
                    precision={0}
                    valueStyle={{ color: "#1890ff" }}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card hoverable>
                  <Statistic
                    title="Scheduled Live Sessions"
                    value={zoomMeetings && Array.isArray(zoomMeetings) ? zoomMeetings.length : 0}
                    valueStyle={{ color: "#8884d8" }}
                    prefix={<VideoCameraOutlined />}
                  />
                </Card>
              </Col>
            </Row>

            {
              role === 'TEACHER' && isLoaded(zoomMeetings) && Array.isArray(zoomMeetings) && zoomMeetings.length > 0 &&
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col span={24}>
                  <Card hoverable title={"Upcoming Live Sessions"}>
                    <ResponsiveContainer width={"100%"} >
                      <Table columns={this.getZoomColumns()} dataSource={this.getZoomData(zoomMeetings)} />
                    </ResponsiveContainer>
                  </Card>
                </Col>
              </Row>
            }
          </Content>
          <Footer style={{ textAlign: "center" }}>©{new Date().getFullYear()}</Footer>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title={
              <Skeleton.Input
                style={{ width: "300px", height: "1rem", marginTop: ".5rem" }}
                active={true}
              />
            }
            className="site-page-header"
          ></PageHeader>
          <Content style={{ margin: "1rem" }}>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: "1rem" }}>
              <Col span={24}>
                <Card>
                  <Skeleton paragraph={{ rows: 6 }} active />
                </Card>
              </Col>
            </Row>
          </Content>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state) => {
  //console.log(state.firestore);
  return {
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
    myProfile: state.firestore.data.myProfile,
    zoomMeetings: state.firestore.ordered.zoomMeetings,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    var timestamp = props.firebase.firestore.Timestamp.fromDate(currentTime);
    let ret = [
      {
        collection: props.role === 'TEACHER' ? "teachers":  "users",
        doc: props.auth.uid,
        storeAs: "myProfile",
      }
    ];

    if( props.role === 'TEACHER' && props.myProfile && props.myProfile.zoomAccountUserId ){
      ret.push(
        {
          collection: "zoom_accounts",
          doc: props.myProfile.zoomAccountUserId,
          subcollections: [
            {
              collection: "meetings_created",
              where: ["zoom_end_time", ">=", timestamp],
              orderBy: ["zoom_end_time", "asc"],
              limit: 10,
            },
          ],
          storeAs: "zoomMeetings"
        }
      );
    }

    return ret;
  })
)(Dashboard);
