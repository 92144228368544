import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Layout,
  PageHeader,
  Result,
  Row,
  Select,
  Skeleton,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import {
  approveVideo,
  delAttachment,
  editDiscussion,
} from "../../store/actions/paper";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import ReactPlayer from "react-player";
import axios from "axios";
import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
const { REACT_APP_CLOUDFUNCTIONS_URL } = process.env;
const { Paragraph, Text } = Typography;

class EditDiscussion extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val, field) => {
    //  console.log(field, val);
    this.setState({
      loading: true,
    });
    if (this.props.discussion[field] !== val) {
      await this.props.editDiscussion({
        examId: this.props.examId,
        discussionId: this.props.discussionId,
        key: field,
        value: val,
      });

      // update video url if type updated
      if (field.toUpperCase() === "TYPE") {
        const vidUrlValue = val === "ATTACHMENT_ONLY" ? null : ".";
        await this.props.editDiscussion({
          examId: this.props.examId,
          discussionId: this.props.discussionId,
          key: "videoUrl",
          value: vidUrlValue,
        });
      }

      if (!this.props.error) {
        message.success("Discussion updated successfully");
      } else {
        message.error("There was an error updating discussion");
      }
    }
    this.setState({
      visible: false,
      loading: false,
    });
  };

  deleteAttachment = async (file) => {
    this.setState({
      uploading: true,
    });
    if (!file.url) {
      message.warning("Something isn't right, please reload the page.");
      return;
    }
    await this.props.delAttachment({
      examId: this.props.examId,
      discussionId: this.props.discussionId,
      attachmentId: file.uid,
    });
    message.success("Attachment deleted successfully");
    this.setState({
      uploading: false,
    });
  };
  setMpdUrl = async () => {
    var res = await axios.post(
      REACT_APP_CLOUDFUNCTIONS_URL +
        "papers/discussions/getDiscussionUniqueUrl",
      {
        examId: this.props.examId,
        discussionId: this.props.discussionId,
      },
      {
        headers: {
          Authorization: "Bearer " + this.props.token,
        },
      }
    );
    if (res.status === 200) {
      this.setState({
        previewUrl: res.data?.data?.url,
      });
    } else {
      message.error("Error retrieving media");
    }
  };

  componentDidMount() {
    this.setMpdUrl();
  }

  render() {
    let fileList = [];
    const { discussion, examId, discussionId, attachments, token } = this.props;

    if (
      discussion != null &&
      isLoaded(discussion, attachments) &&
      discussion.id === discussionId
    ) {
      for (let attachment of attachments) {
        fileList.push({
          uid: attachment.id,
          name: attachment.name,
          url: attachment.url,
          status: "done",
        });
      }
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit discussion"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "name"),
                            }
                      }
                    >
                      {discussion.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Avatar shape="square" src={discussion.cover} />}
              >
                <Row gutter={[16, 16]}>
                  {discussion.type === "YOUTUBE" ? (
                    <Col span={12}>
                      <Typography.Title level={4}>
                        Discussion Details
                      </Typography.Title>
                      <div className="discussion-video-container">
                        <iframe
                          className="discussion-video-iframe"
                          src={
                            "https://www.youtube.com/embed/" +
                            discussion.videoUrl?.split("?v=")[1]
                          }
                          title="Video"
                        ></iframe>
                      </div>
                    </Col>
                  ) : null}

                  {!discussion.type || discussion.type === "DEFAULT" ? (
                    <Col span={12}>
                      <Typography.Title level={4}>
                        Discussion Details
                      </Typography.Title>
                      <div>
                        <ReactPlayer
                          width={"inherit"}
                          controls={true}
                          url={this.state.previewUrl}
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col span={12}>
                    <Typography.Title level={4}>Attachments</Typography.Title>
                    <Form>
                      <Form.Item className="attachment-file-list">
                        <Upload.Dragger
                          accept=".pdf"
                          listType="picture"
                          defaultFileList={fileList}
                          onRemove={this.deleteAttachment}
                          disabled={this.state.uploading}
                          headers={{
                            Authorization: "Bearer " + token,
                          }}
                          action={
                            REACT_APP_CLOUDFUNCTIONS_URL +
                            "papers/discussions/uploadAttachment/" +
                            examId +
                            "/" +
                            discussionId
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Only .pdf files are allowed to upload, make sure the
                            filename is correct before uploading
                          </p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Descriptions>
                      <Descriptions.Item
                        label={
                          discussion.isPublished
                            ? "Status"
                            : "Publish discussion"
                        }
                        span={3}
                      >
                        <Button
                          disabled={
                            discussion.isPublished || discussion.isProcessing
                          }
                          type="primary"
                          onClick={() =>
                            !discussion.isPublished && !discussion.isProcessing
                              ? this.props.approveVideo()
                              : null
                          }
                        >
                          {discussion.isProcessing ? "Uploading" : "Publish"}
                        </Button>
                      </Descriptions.Item>

                      {discussion.type && discussion.type === "YOUTUBE" ? (
                        <Descriptions.Item
                          label={"Discussion Video Url:"}
                          span={3}
                        >
                          <Text
                            copyable
                            disabled={this.state.loading}
                            editable={
                              this.state.loading
                                ? false
                                : {
                                    onChange: (newStr) =>
                                      this.onFinish(newStr, "videoUrl"),
                                  }
                            }
                          >
                            {discussion.videoUrl}
                          </Text>
                        </Descriptions.Item>
                      ) : null}

                      <Descriptions.Item label="Session Type" span={3}>
                        <Select
                          defaultValue={
                            discussion.type ? discussion.type : "DEFAULT"
                          }
                          disabled={this.state.loading}
                          style={{ width: 250 }}
                          onChange={(val) => this.onFinish(val, "type")}
                        >
                          <Select.Option value="DEFAULT">DEFAULT</Select.Option>
                          <Select.Option value="YOUTUBE">YOUTUBE</Select.Option>
                          <Select.Option value="ATTACHMENT_ONLY">
                            ATTACHMENT ONLY
                          </Select.Option>
                        </Select>
                      </Descriptions.Item>

                      <Descriptions.Item label="Sub Title" span={3}>
                        <Text
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "subHeading"),
                                }
                          }
                        >
                          {discussion.subHeading}
                        </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Video Start" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="vidStart"
                            initialValue={moment(discussion.vidStart.toDate())}
                          >
                            <DatePicker
                              disabled={this.state.loading}
                              showTime
                              allowClear={false}
                              value={moment(discussion.vidStart.toDate())}
                              onChange={(val) =>
                                this.onFinish(val.toDate(), "vidStart")
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Video End" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="vidEnd"
                            initialValue={moment(discussion.vidEnd.toDate())}
                          >
                            <DatePicker
                              disabled={this.state.loading}
                              showTime
                              allowClear={false}
                              value={moment(discussion.vidEnd.toDate())}
                              onChange={(val) =>
                                this.onFinish(new Date(val.toDate()), "vidEnd")
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Description" span={3}>
                        <Paragraph
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "description"),
                                }
                          }
                        >
                          {discussion.description}
                        </Paragraph>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else if (isEmpty(discussion) && isLoaded(discussion)) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit discussion"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Discussion doesn't exists"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the discussion you've selected does not exist."
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit discussion"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Discussion is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    attachments:
      state.firestore.ordered[
        `${routeProps.match.params.discussionId}_attachments`
      ],
    discussion: state.firestore.ordered.selected_discussion
      ? state.firestore.ordered.selected_discussion[0]
      : null,
    examId: routeProps.match.params.examId,
    token: state.firebase.profile.token.token,
    discussionId: routeProps.match.params.discussionId,
  };
};

const mapDispatchToProps = (dispatch, routeProps) => {
  const { examId, discussionId } = routeProps.match.params;
  return {
    editDiscussion: (data) => dispatch(editDiscussion(data)),
    delAttachment: (data) => dispatch(delAttachment(data)),
    approveVideo: () => dispatch(approveVideo({ examId, discussionId })),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => [
    {
      collection: "exams",
      doc: routeProps.match.params.examId,
      subcollections: [
        {
          collection: "discussions",
          doc: routeProps.match.params.discussionId,
        },
      ],
      storeAs: "selected_discussion",
    },
    {
      collection: "exams",
      doc: routeProps.match.params.examId,
      subcollections: [
        {
          collection: "discussions",
          doc: routeProps.match.params.discussionId,
          subcollections: [{ collection: "attachments", orderBy: ["name"] }],
        },
      ],
      storeAs: `${routeProps.match.params.discussionId}_attachments`,
    },
  ])
)(EditDiscussion);
