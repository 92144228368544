import {
  Button,
  Card,
  Col,
  Descriptions,
  Layout,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import React, { Component } from "react";
import { editPaper, publishPaper } from "../../store/actions/paper";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import { LoadingOutlined } from "@ant-design/icons";
import Questions from "./components/Questions";
import { compose } from "redux";
import { connect } from "react-redux";

const { Text } = Typography;

class EditPaper extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    publishing: false,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };

  publishPaper = async () => {
    this.setState({
      publishing: true,
    });
    await this.props.publishPaper(this.props.examId);
    if (!this.props.error) {
      message.success("Paper publish was successful");
    } else {
      message.error("There was an error publishing paper");
    }
    this.setState({
      publishing: false,
    });
  };

  onFinish = async (val, field) => {
    //  console.log(field, val);
    this.setState({
      loading: true,
    });
    if (this.props.exam[field] !== val) {
      await this.props.editPaper({
        uid: this.props.examId,
        [field]: val,
      });

      if (!this.props.error) {
        message.success("Paper updated successfully");
      } else {
        message.error("There was an error updating paper");
      }
    }
    this.setState({
      visible: false,
      loading: false,
    });
  };
  render() {
    const { exam, examId, role } = this.props;
    if (exam != null && isLoaded(exam)) {
      let examData = Object.assign({ id: examId }, exam);
      // console.log(examData);
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit paper"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <>
                    {examData.name} <Tag color="blue">{examData.status}</Tag>
                    {examData.lastPublishedAt &&
                      examData.updatedAt &&
                      examData.lastPublishedAt.seconds <
                        examData.updatedAt.seconds && (
                        <Tag color={"red"}>SHOULD REPUBLISH</Tag>
                      )}
                  </>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={
                  role === "COMPANY_ADMIN" ? (
                    <Button
                      type="primary"
                      onClick={this.publishPaper}
                      disabled={this.state.publishing}
                      loading={this.state.publishing}
                    >
                      {examData.status !== "DRAFT" ? "Republish" : "Publish"}
                    </Button>
                  ) : null
                }
              >
                <Descriptions size="small" column={3}>
                  <Descriptions.Item label="Name">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "name"),
                            }
                      }
                    >
                      {examData.name.toString()}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Description">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "description"),
                            }
                      }
                    >
                      {examData.description.toString()}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Instructions">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "instructions"),
                            }
                      }
                    >
                      {examData.instructions.toString()}
                    </Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="Fee">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(parseInt(newStr), "fee"),
                            }
                      }
                    >
                      {examData.fee.toString()}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="No. of Answers">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(parseInt(newStr), "noOfAnswers"),
                            }
                      }
                    >
                      {examData.noOfAnswers.toString()}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="No. of Questions">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(
                                  parseInt(newStr),
                                  "noOfQuestions"
                                ),
                            }
                      }
                    >
                      {examData.noOfQuestions.toString()}
                    </Text>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          <Questions exam={examData} />
        </Layout>
      );
    } else if (isEmpty(exam) && isLoaded(exam)) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit paper"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Paper doesn't exists"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the paper you've selected does not exist."
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit paper"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Paper is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    exams: state.firestore.ordered.exams,
    exam: state.firestore.data.exams
      ? state.firestore.data.exams[routeProps.match.params.id]
      : null,
    examId: routeProps.match.params.id,
    role: state.firebase.profile.token.claims.role,
    error: state.paper.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishPaper: (paperId) => dispatch(publishPaper(paperId)),
    editPaper: (data) => dispatch(editPaper(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "exams",
      orderBy: ["createdAt", "desc"],
    },
  ])
)(EditPaper);
