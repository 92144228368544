import { KeyOutlined, StopOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Space, Table, Tooltip, Typography, message } from "antd";
import { sortAlphabetically } from "../../../utils/helpers";
import SendPasswordResetEmailModal from "../../common/SendPasswordResetEmailModal";
import ToggleAccountDisableModal from "../../common/ToggleAccountDisableModal";
import { sendPasswordResetEmail, toggleAccountDisableStatus } from "../../../store/actions/teacher";

const { Text } = Typography;

class CoordTable extends Component {

  constructor(props){
    super(props);
    this.state = {
      toggleAccountDisableModalVisibile: false,
      passwordResetModalVisible: false,
      loading: false,
    }
  }
  
  toggleAccountDisable = async () => {
    this.setState({
      toggleAccountDisableModalVisibile: true,
    });
  };
  resetPassword = async () => {
    this.setState({
      passwordResetModalVisible: true,
    });
  };

  onPasswordResetSubmit = async (email) => {
    this.setState({
      loading: true,
    });

    if( email ){
      await this.props.sendPasswordResetEmail(email);
    }
    
    if (this.props.passwordResetError) {
      message.error("There was an error");
    } else {
      message.success("Password reset email sent successfully! Please check the email!");
    }

    this.setState({
      loading: false,
      passwordResetModalVisible: false,
    });
  };

  onToggleAccountDisableSubmit = async (uid) => {
    this.setState({
      loading: true,
    });

    await this.props.toggleAccountDisableStatus(uid);
    
    if (this.props.toggleDisableError) {
      message.error("There was an error");
    } else {
      message.success("Successfully toggled the account status!");
    }

    this.setState({
      loading: false,
      toggleAccountDisableModalVisibile: false,
    });
  };

  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Status",
        key: "status",
        render: (_, record) => <div>{record.disabled ? 'Disabled' : 'Active'}</div>
      },
      {
        title: "Action",
        key: "operation",
        render: (_, record) => {
          return (
            <>
            <Space>
              <Typography.Link onClick={this.toggleAccountDisable}>
                <Tooltip title={record.disabled ? "Enable account" : "Disable Account"}>
                  {
                    record.disabled ? <CheckCircleOutlined /> : <StopOutlined />
                  }
                </Tooltip>
              </Typography.Link>
              <Typography.Link onClick={this.resetPassword}>
                <Tooltip title="Reset password">
                  <KeyOutlined />
                </Tooltip>
              </Typography.Link>
            </Space>

            <SendPasswordResetEmailModal 
              loading={this.state.loading}
              visible={this.state.passwordResetModalVisible}
              onOk={() => this.onPasswordResetSubmit(record.email)}
              onCancel={() => {
                this.setState({
                  passwordResetModalVisible: false
                })
              }}
              title={"Send Password Reset Email"}
              okText={"Send"}
              email={record && record.email ? record.email : ''}
            />

            <ToggleAccountDisableModal 
              loading={this.state.loading}
              visible={this.state.toggleAccountDisableModalVisibile}
              onOk={() => this.onToggleAccountDisableSubmit(record.id)}
              onCancel={() => {
                this.setState({
                  toggleAccountDisableModalVisibile: false
                })
              }}
              title={record.disabled ? "Enable Account" : "Disable Account"}
              okText={record.disabled ? "Enable" : "Disable"}
              name={record && record.name ? record.name : ''}
              email={record && record.email ? record.email : ''}
              isActive={!record.disabled}
              accountType={'TEACHER_DATA_ENTRY'}
            />
            </>
          );
        },
      },
    ];

    // add teacher column
    if( this.props.role === 'COMPANY_ADMIN' ){
      columns.splice(0, 0, 
        {
          title: "Teacher",
          dataIndex: "teacherName",
          sorter: (a, b) => sortAlphabetically(a.teacherName, b.teacherName),
        }  
      );
    }

    return (
      <div>
        <Table columns={columns} dataSource={this.props.data} rowKey="name" />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    passwordResetError: state.teacher.passwordResetError,
    toggleDisableError: state.teacher.toggleDisableError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    sendPasswordResetEmail: (userEmail) => dispatch(sendPasswordResetEmail(userEmail)),
    toggleAccountDisableStatus: (userUid) => dispatch(toggleAccountDisableStatus(userUid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CoordTable);
