import React, { Component } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import CardListItem from "./CardListItem";
import { Link } from "react-router-dom";
import { Skeleton } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";

class ClassCardsTable extends Component {
  state = {
    visible: false,
    editing: null,
    loading: true,
  };
  componentDidUpdate() {
    if (isLoaded(this.props.class_cards) && this.state.loading) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { class_cards, selectedCategory } = this.props;
    return (
      <div>
        <div className="md:flex content-center flex-wrap -mx-2 p-1">
          {isLoaded(this.props.class_cards) ? (
            class_cards &&
            class_cards.map((card) => (
              <Link
                className="flex md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 md:px-2 py-2"
                key={card.id}
                to={`/cards/edit/${card.id}`}
              >
                <CardListItem card={card} cover={card.coverUrl} />
              </Link>
            ))
          ) : (
            <div
              key={777}
              className="flex md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 md:px-2 py-2"
            >
              <Skeleton
                className="bg-gray-100 rounded-md p-2"
                avatar
                active
                paragraph={{ rows: 4 }}
              />
            </div>
          )}
          {class_cards && class_cards.length === 0 ? (
            <div className=" text-gray-700 p-2 rounded-lg">
              Sorry, There are no class cards available, Please add a card.
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teacherUid:
      state.firebase.profile.token.claims.role === "TEACHER"
        ? state.firebase.auth.uid
        : state.firebase.profile.token.claims.teacherUid,
  };
};

export default compose(connect(mapStateToProps))(ClassCardsTable);
