import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  List,
  Typography,
  Tooltip,
  Avatar,
  message,
} from "antd";
import { KeyOutlined, StopOutlined, CheckCircleOutlined, DeleteOutlined, EditOutlined, CloudUploadOutlined, CloudOutlined, DisconnectOutlined } from "@ant-design/icons";
import SendPasswordResetEmailModal from "../../common/SendPasswordResetEmailModal";
import ToggleAccountDisableModal from "../../common/ToggleAccountDisableModal";
import CreateIAMUserModal from "../../common/CreateIAMUserModal";
import ToggleTeacherUploadStatusModal from "../../common/ToggleTeacherUploadStatusModal";
import { sendPasswordResetEmail, toggleAccountDisableStatus, addTeacherIAMUser, toggleTeacherUploadStatus } from "../../../store/actions/teacher";

const { Text } = Typography;

class TeacherListItem extends Component {
  constructor(props){
    super(props);
    this.state = {
      toggleAccountDisableModalVisibile: false,
      passwordResetModalVisible: false,
      createIAMUserModalVisible: false,
      toggleTeacherUploadStatusModalVisible: false,
      loading: false,
    }
  }

  toggleAccountDisable = async () => {
    this.setState({
      toggleAccountDisableModalVisibile: true,
    });
  };
  resetPassword = async () => {
    this.setState({
      passwordResetModalVisible: true,
    });
  };
  deleteAccount = async () => {
    console.log("clicked delete");
  };

  createIAMUser = async () => {
    this.setState({
      createIAMUserModalVisible: true
    })
  }

  toggleTeacherUploadStatus = async () => {
    this.setState({
      toggleTeacherUploadStatusModalVisible: true
    })
  }

  onPasswordResetSubmit = async () => {
    this.setState({
      loading: true,
    });

    if( this.props.teacherProfile && this.props.teacherProfile.email ){
      await this.props.sendPasswordResetEmail(this.props.teacherProfile.email);
    }
    
    if (this.props.passwordResetError) {
      message.error("There was an error");
    } else {
      message.success("Password reset email sent successfully! Please check the email!");
    }

    this.setState({
      loading: false,
      passwordResetModalVisible: false,
    });
  };

  onToggleAccountDisableSubmit = async () => {
    this.setState({
      loading: true,
    });

    await this.props.toggleAccountDisableStatus(this.props.uid);
    
    if (this.props.toggleDisableError) {
      message.error("There was an error");
    } else {
      message.success("Successfully toggled the account status!");
    }

    this.setState({
      loading: false,
      toggleAccountDisableModalVisibile: false,
    });
  };

  onCreateIAMUserSubmit = async () => {
    this.setState({
      loading: true,
    });

    if( this.props.teacher && this.props.teacher.id ){
      await this.props.addTeacherIAMUser(this.props.teacher.id);
    }
    
    if (this.props.createIAMUserError) {
      message.error("There was an error");
    } else {
      message.success("Successfully created the IAM user!");
    }

    this.setState({
      loading: false,
      createIAMUserModalVisible: false,
    });
  };

  onToggleTeacherUploadStatusSubmit = async () => {
    this.setState({
      loading: true,
    });

    if( this.props.teacher && this.props.teacher.id ){
      await this.props.toggleTeacherUploadStatus(this.props.teacher.id);
    }
    
    if (this.props.toggleTeacherUploadStatusError) {
      message.error("There was an error");
    } else {
      message.success("Successfully toggled the teacher upload status!");
    }

    this.setState({
      loading: false,
      toggleTeacherUploadStatusModalVisible: false,
    });
  };

  render() {
    let { teacher, teacherS3Creds } = this.props;
    const isUserDisabled = this.props.teacherProfile && this.props.teacherProfile.disabled;
    const hasS3Creds = teacherS3Creds !== null;
    const canUpload = teacherS3Creds !== null && teacherS3Creds.canUpload;
    return (
      <List.Item
        actions={
          teacher.id === 'viduna_admin' ? [] :
          [
          <Typography.Link href={`/teachers/${teacher.id}`}>
            <Tooltip title="Edit Teacher">
              <EditOutlined />
            </Tooltip>
          </Typography.Link>,
          <Typography.Link onClick={this.toggleAccountDisable}>
            <Tooltip title={isUserDisabled ? "Enable account" : "Disable Account"}>
              {
                isUserDisabled ? <CheckCircleOutlined /> : <StopOutlined />
              }
            </Tooltip>
          </Typography.Link>,
          <Typography.Link onClick={this.resetPassword}>
            <Tooltip title="Reset password">
              <KeyOutlined />
            </Tooltip>
          </Typography.Link>,
          <Tooltip 
            title={
              canUpload 
                ? 'Teacher can upload Sessions/Discussions' 
                : (
                  hasS3Creds
                    ? 'Has IAM user, but Inactive'
                    : 'Not setup to upload Sessions/Discussions'
                )
            }
          >
            {
              canUpload &&
                <Text type="success">
                  <CloudUploadOutlined onClick={this.toggleTeacherUploadStatus} />
                </Text>                
            }
            {
              !canUpload && hasS3Creds &&
                <Text type="warning">
                  <CloudOutlined onClick={this.toggleTeacherUploadStatus} />
                </Text>               
            }
            {
              !canUpload && !hasS3Creds &&
                <Text type="danger" onClick={this.createIAMUser}>
                  <DisconnectOutlined />
                </Text>  
            }

          </Tooltip>
          // <Typography.Link onClick={this.deleteAccount}>
          //   <Tooltip title="Delete account">
          //     <DeleteOutlined />
          //   </Tooltip>
          // </Typography.Link>
        ]}
      >
        <List.Item.Meta
          title={
            <>
              <Typography.Link href={teacher.id === 'viduna_admin'? null : `/teachers/${teacher.id}`}>
                {teacher.name}
              </Typography.Link>
              <div>
                <Text type="secondary" style={{fontSize: '12px'}}>
                  {teacher.email}
                </Text>
              </div>
            </>
          }
          avatar={
            <Avatar size={64} src={teacher.photo ? teacher.photo : '/profile.png'} />
          }
          description={isUserDisabled ? <Text type="danger">Disabled</Text> : ""}
        />

        <SendPasswordResetEmailModal 
          loading={this.state.loading}
          visible={this.state.passwordResetModalVisible}
          onOk={this.onPasswordResetSubmit}
          onCancel={() => {
            this.setState({
              passwordResetModalVisible: false
            })
          }}
          title={"Send Password Reset Email"}
          okText={"Send"}
          email={this.props.teacherProfile && this.props.teacherProfile.email ? this.props.teacherProfile.email : ''}
        />

        <ToggleAccountDisableModal 
          loading={this.state.loading}
          visible={this.state.toggleAccountDisableModalVisibile}
          onOk={this.onToggleAccountDisableSubmit}
          onCancel={() => {
            this.setState({
              toggleAccountDisableModalVisibile: false
            })
          }}
          title={isUserDisabled ? "Enable Account" : "Disable Account"}
          okText={isUserDisabled ? "Enable" : "Disable"}
          name={this.props.teacherProfile && this.props.teacherProfile.name ? this.props.teacherProfile.name : ''}
          email={this.props.teacherProfile && this.props.teacherProfile.email ? this.props.teacherProfile.email : ''}
          isActive={!isUserDisabled}
        />

        <CreateIAMUserModal 
          loading={this.state.loading}
          visible={this.state.createIAMUserModalVisible}
          onOk={this.onCreateIAMUserSubmit}
          onCancel={() => {
            this.setState({
              createIAMUserModalVisible: false
            })
          }}
          title={"Create AWS IAM User"}
          okText={"OK"}
          email={this.props.teacherProfile && this.props.teacherProfile.email ? this.props.teacherProfile.email : ''}
        />

        <ToggleTeacherUploadStatusModal 
          loading={this.state.loading}
          visible={this.state.toggleTeacherUploadStatusModalVisible}
          onOk={this.onToggleTeacherUploadStatusSubmit}
          onCancel={() => {
            this.setState({
              toggleTeacherUploadStatusModalVisible: false
            })
          }}
          title={"Toggle Teacher Upload Status"}
          okText={"OK"}
          email={this.props.teacherProfile && this.props.teacherProfile.email ? this.props.teacherProfile.email : ''}
          canUpload={this.props.teacherS3Creds && this.props.teacherS3Creds.canUpload}
        />

      </List.Item>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    passwordResetError: state.teacher.passwordResetError,
    toggleDisableError: state.teacher.toggleDisableError,
    createIAMUserError: state.teacher.createIAMUserError,
    toggleTeacherUploadStatusError: state.teacher.toggleTeacherUploadStatusError,
    teacherProfile: state.firestore.data.teachers ? state.firestore.data.teachers[props.uid] : null,
    teacherS3Creds: state.firestore.data[`teacher_${props.uid}_s3creds`] ? state.firestore.data[`teacher_${props.uid}_s3creds`] : null
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    sendPasswordResetEmail: (userEmail) => dispatch(sendPasswordResetEmail(userEmail)),
    toggleAccountDisableStatus: (userUid) => dispatch(toggleAccountDisableStatus(userUid)),
    addTeacherIAMUser: (teacherUid) => dispatch(addTeacherIAMUser(teacherUid)),
    toggleTeacherUploadStatus: (teacherUid) => dispatch(toggleTeacherUploadStatus(teacherUid))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    let query = [
      { 
        collection: "teachers",
        doc: props.uid,
        storeAs: `teacher_${props.uid}`
      },
    ];

    if( props.uid ){
      query.push({
        collection: "config",
        doc: 'recordedSessions',
        subcollections: [
          {
            collection: "s3Credentials",
            doc: props.uid
          },
        ],
        storeAs: `teacher_${props.uid}_s3creds`
      })
    }

    return query;
  })
)(TeacherListItem);
