import {
  Avatar,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Layout,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Spin,
  Typography,
  Upload,
  message,
  Select,
  Button,
} from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import {
  delLiveAttachment,
  editLiveSession,
  publishLiveSession,
} from "../../store/actions/onlineClass";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import { compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";

const { Paragraph, Text } = Typography;

class EditSession extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    month: null,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val, field) => {
    console.log(field, val);
    this.setState({
      loading: true,
    });
    if (this.props.session[field] !== val) {
      let updateData = {
        classUid: this.props.classId,
        liveSessionUid: this.props.sessionId,
      };
      updateData[field] = val;
      await this.props.editSession(updateData);

      if (!this.props.error) {
        message.success("Session updated successfully");
      } else {
        message.error("There was an error updating session");
      }
    }
    this.setState({
      visible: false,
      loading: false,
    });
  };

  deleteAttachment = async (file) => {
    this.setState({
      uploading: true,
    });
    if (!file.url) {
      message.warning("Something isn't right, please reload the page.");
      return;
    }
    await this.props.delAttachment({
      classUid: this.props.classId,
      liveSessionUid: this.props.sessionId,
      attachmentUid: file.uid,
    });
    message.success("Attachment deleted successfully");
    this.setState({
      uploading: false,
    });
  };

  onChangeMonth = (val) => {
    this.setState({
      month: val,
    });
  };

  getClassCard = () => {
    const classCards = this.props.class_cards;
    if (!classCards) {
      return [];
    }

    if (!this.state.month) {
      return classCards;
    }

    let firstDayOfMonth = this.state.month.clone().startOf("month");
    let endOfMonth = this.state.month.clone().endOf("month");

    var filtered = classCards.filter((classCard) => {
      var validFrom = moment(classCard.validFrom?.toDate());
      var validTill = moment(classCard.validTill?.toDate());

      return (
        validFrom.isSameOrAfter(firstDayOfMonth) &&
        validTill.isSameOrBefore(endOfMonth)
      );
    });

    return filtered;
  };

  render() {
    let fileList = [];
    const { session, classId, sessionId, attachments } = this.props;

    if (session != null && isLoaded(session, attachments)) {
      for (let attachment of attachments) {
        fileList.push({
          uid: attachment.id,
          name: attachment.name,
          url: attachment.url,
          status: "done",
        });
      }
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit zoom session"
            className="site-page-header"
            extra={
              this.state.loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : null
            }
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text
                      disabled={this.state.loading}
                      editable={
                        this.state.loading
                          ? false
                          : {
                              onChange: (newStr) =>
                                this.onFinish(newStr, "name"),
                            }
                      }
                    >
                      {session.name}
                    </Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Avatar shape="square" src={session.cover} />}
              >
                <Row gutter={[16, 16]}>
                  <Col>
                    <Descriptions>
                      {/* <Descriptions.Item label="Meeting URL" span={3}>
                        <Paragraph
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "meetingUrl"),
                                }
                          }
                        >
                          {session.meetingUrl}
                        </Paragraph>
                      </Descriptions.Item> */}
                      {/* <Descriptions.Item label="Video Start" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="vidStart"
                            initialValue={moment(
                              typeof String() === typeof session.vidStart
                                ? session.vidStart
                                : session.vidStart.toDate()
                            )}
                          >
                            <DatePicker
                              disabled={this.state.loading}
                              showTime
                              allowClear={false}
                              value={moment(
                                typeof String() === typeof session.vidStart
                                  ? session.vidStart
                                  : session.vidStart.toDate()
                              )}
                              onChange={(val) =>
                                this.onFinish(val.toDate(), "vidStart")
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>
                      <Descriptions.Item label="Video End" span={3}>
                        <Form layout="inline">
                          <Form.Item
                            name="vidEnd"
                            initialValue={moment(
                              typeof String() === typeof session.vidEnd
                                ? session.vidEnd
                                : session.vidEnd.toDate()
                            )}
                          >
                            <DatePicker
                              disabled={this.state.loading}
                              showTime
                              allowClear={false}
                              value={moment(
                                typeof String() === typeof session.vidEnd
                                  ? session.vidEnd
                                  : session.vidEnd.toDate()
                              )}
                              onChange={(val) =>
                                this.onFinish(new Date(val.toDate()), "vidEnd")
                              }
                            />
                          </Form.Item>
                        </Form>
                      </Descriptions.Item> */}
                      <Descriptions.Item label="Fee" span={3}>
                        <Text
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(parseInt(newStr), "fee"),
                                }
                          }
                        >
                          {session.fee.toString()}
                        </Text>
                      </Descriptions.Item>

                      <Descriptions.Item label="Class Cards" span={3}>
                        <Form layout="inline">
                          <Form.Item style={{ width: "200px" }}>
                            <DatePicker
                              onChange={this.onChangeMonth}
                              picker="month"
                            />
                          </Form.Item>
                          <Form.Item
                            name="class_cards"
                            style={{ width: "300px" }}
                            initialValue={session.classCards}
                          >
                            <Select
                              showSearch
                              placeholder="Select Class Cards"
                              mode="multiple"
                              allowClear
                              onChange={(val) => {
                                this.onFinish(val, "classCards");
                              }}
                              disabled={this.state.loading}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.getClassCard().map((card) => {
                                return (
                                  <Select.Option value={card.id} key={card.id}>
                                    {card.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Form>
                      </Descriptions.Item>

                      <Descriptions.Item label="Description" span={3}>
                        <Paragraph
                          disabled={this.state.loading}
                          editable={
                            this.state.loading
                              ? false
                              : {
                                  onChange: (newStr) =>
                                    this.onFinish(newStr, "description"),
                                }
                          }
                        >
                          {session.description}
                        </Paragraph>
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={
                          session.isPublished ? "Status" : "Publish Session"
                        }
                        span={3}
                      >
                        {!session.isPublished ? (
                          <Button
                            disabled={session.isPublished}
                            type="primary"
                            onClick={() =>
                              !session.isPublished
                                ? this.props.publishLiveSession({
                                    classUid: this.props.classId,
                                    liveSessionUid: this.props.sessionId,
                                  })
                                : null
                            }
                          >
                            Publish
                          </Button>
                        ) : (
                          <Text>Published</Text>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={"Attachments"}
                style={{ margin: "1rem 1rem 1rem 1rem" }}
              >
                <Form>
                  <Form.Item className="attachment-file-list">
                    <Upload.Dragger
                      accept=".pdf"
                      listType="picture"
                      defaultFileList={fileList}
                      onRemove={this.deleteAttachment}
                      disabled={this.state.uploading}
                      action={
                        process.env.REACT_APP_CLOUDFUNCTIONS_URL +
                        "/api/liveSessions/uploadAttachment/" +
                        classId +
                        "/" +
                        sessionId
                      }
                      headers={{
                        Authorization: `Bearer ${this.props.token}`,
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Only .pdf files are allowed to upload, make sure the
                        filename is correct before uploading
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else if (isEmpty(session) && isLoaded(session)) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit zoom session"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Session doesn't exists"
                style={{ margin: "1rem 1rem 0 1rem" }}
              >
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the session you've selected does not exist."
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Edit zoom session"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Session is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                style={{ margin: "1rem 1rem 1rem 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    attachments: state.firestore.ordered.selected_session_attachments,
    session: state.firestore.ordered.selected_session
      ? state.firestore.ordered.selected_session[0]
      : null,
    classId: routeProps.match.params.classId,
    sessionId: routeProps.match.params.sessionId,
    selected_class: state.firestore.data.selected_class,
    token: state.firebase.profile.token.token,
    class_cards: state.firestore.ordered.class_cards,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editSession: (data) => dispatch(editLiveSession(data)),
    publishLiveSession: (data) => dispatch(publishLiveSession(data)),
    delAttachment: (data) => dispatch(delLiveAttachment(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => {
    let queries = [
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        storeAs: "selected_class",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          {
            collection: "live_sessions",
            doc: routeProps.match.params.sessionId,
          },
        ],
        storeAs: "selected_session",
      },
      {
        collection: "classes",
        doc: routeProps.match.params.classId,
        subcollections: [
          {
            collection: "live_sessions",
            doc: routeProps.match.params.sessionId,
            subcollections: [{ collection: "attachments", orderBy: ["name"] }],
          },
        ],
        storeAs: "selected_session_attachments",
      },
    ];

    if (routeProps.selected_class) {
      queries.push({
        collection: "class_cards",
        where: ["teacherUid", "==", routeProps.selected_class.teacher],
        storeAs: "class_cards",
      });
    }

    return queries;
  })
)(EditSession);