import React, { Component } from "react";
import { connect } from "react-redux";
import { sendResetPasswordEmail } from "../../store/actions/auth";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import { MailOutlined } from "@ant-design/icons";

class ResetPassword extends Component {
  state = {
    loading: false,
  };
  handleSubmit = async (val) => {
    this.setState({
      loading: true,
    });
    await this.props.sendResetPasswordEmail(val.email);
    if (!this.props.authError) {
      message.success("Verification code is sent to " + val.email);
      this.props.history.push("/password-update");
    } else {
      message.error(this.props.authError);
      this.setState({
        loading: false,
      });
    }
  };
  render() {
    return (
      <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
        <Col>
          <div className="container" style={{ maxWidth: "420px" }}>
            <Card
              style={{ textAlign: "center" }}
              hoverable={true}
              cover={
                <>
                  <img
                    style={{ padding: "20px 20px 0 20px" }}
                    alt="logo"
                    src="./logo.png"
                  />
                  <Typography.Text strong>
                    Enter your email address to
                    <br /> receive the password reset link
                  </Typography.Text>
                </>
              }
            >
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid Email!",
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Your Email Address"
                  />
                </Form.Item>
                {/* <Form.Item>
                  <NavLink
                    to={{
                      pathname: "/password-update",
                    }}
                    className="waves-effect sidenav-close"
                  >
                    Already have a code?
                  </NavLink>
                </Form.Item> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.state.loading}
                  >
                    Send Code
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendResetPasswordEmail: (email) => dispatch(sendResetPasswordEmail(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
