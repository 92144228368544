import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  List,
  Typography,
  Tag,
  Space,
  Tooltip,
  Avatar,
  message,
} from "antd";
import { KeyOutlined, StopOutlined, CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import SendPasswordResetEmailModal from "../../common/SendPasswordResetEmailModal";
import ToggleAccountDisableModal from "../../common/ToggleAccountDisableModal";
import { sendPasswordResetEmail, toggleAccountDisableStatus } from "../../../store/actions/teacher";

const { Text } = Typography;

class CoordsListItem extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      toggleAccountDisableModalVisibile: false,
      passwordResetModalVisible: false,
      loading: false,
    }
  }

  toggleAccountDisable = async () => {
    this.setState({
      toggleAccountDisableModalVisibile: true,
    });
  };
  resetPassword = async () => {
    this.setState({
      passwordResetModalVisible: true,
    });
  };

  onPasswordResetSubmit = async () => {
    this.setState({
      loading: true,
    });

    if( this.props.coord && this.props.coord.email ){
      await this.props.sendPasswordResetEmail(this.props.coord.email);
    }
    
    if (this.props.passwordResetError) {
      message.error("There was an error");
    } else {
      message.success("Password reset email sent successfully! Please check the email!");
    }

    this.setState({
      loading: false,
      passwordResetModalVisible: false,
    });
  };

  onToggleAccountDisableSubmit = async () => {
    this.setState({
      loading: true,
    });

    await this.props.toggleAccountDisableStatus(this.props.uid);
    
    if (this.props.toggleDisableError) {
      message.error("There was an error");
    } else {
      message.success("Successfully toggled the account status!");
    }

    this.setState({
      loading: false,
      toggleAccountDisableModalVisibile: false,
    });
  };

  render() {
    let { coord } = this.props;
    const isUserDisabled = coord && coord.disabled;
    return (
      <List.Item
        actions={[
          <Typography.Link onClick={this.toggleAccountDisable}>
            <Tooltip title={isUserDisabled ? "Enable account" : "Disable Account"}>
              {
                isUserDisabled ? <CheckCircleOutlined /> : <StopOutlined />
              }
            </Tooltip>
          </Typography.Link>,
          <Typography.Link onClick={this.resetPassword}>
            <Tooltip title="Reset password">
              <KeyOutlined />
            </Tooltip>
          </Typography.Link>,
          // <Typography.Link onClick={this.deleteAccount}>
          //   <Tooltip title="Delete account">
          //     <DeleteOutlined />
          //   </Tooltip>
          // </Typography.Link>
        ]}
      >
        <List.Item.Meta
          title={
            <>
              {coord.name}
              &nbsp;<Tag color="gold">{coord.type}</Tag>
              <div>
                <Text type="secondary" style={{fontSize: '12px'}}>
                  {coord.email}
                </Text>
              </div>
            </>
          }
          avatar={
            <Avatar size={64} src={'/profile.png'} />
          }
          description={isUserDisabled ? <Text type="danger">Disabled</Text> : ""}
        />

        <SendPasswordResetEmailModal 
          loading={this.state.loading}
          visible={this.state.passwordResetModalVisible}
          onOk={this.onPasswordResetSubmit}
          onCancel={() => {
            this.setState({
              passwordResetModalVisible: false
            })
          }}
          title={"Send Password Reset Email"}
          okText={"Send"}
          email={coord && coord.email ? coord.email : ''}
        />

        <ToggleAccountDisableModal 
          loading={this.state.loading}
          visible={this.state.toggleAccountDisableModalVisibile}
          onOk={this.onToggleAccountDisableSubmit}
          onCancel={() => {
            this.setState({
              toggleAccountDisableModalVisibile: false
            })
          }}
          title={isUserDisabled ? "Enable Account" : "Disable Account"}
          okText={isUserDisabled ? "Enable" : "Disable"}
          name={coord && coord.name ? coord.name : ''}
          email={coord && coord.email ? coord.email : ''}
          isActive={!isUserDisabled}
          accountType={'TEACHER_DATA_ENTRY'}
        />

      </List.Item>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    passwordResetError: state.teacher.passwordResetError,
    toggleDisableError: state.teacher.toggleDisableError,
    coords: state.firestore.ordered["coords_" + props.uid],
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    sendPasswordResetEmail: (userEmail) => dispatch(sendPasswordResetEmail(userEmail)),
    toggleAccountDisableStatus: (userUid) => dispatch(toggleAccountDisableStatus(userUid)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CoordsListItem);