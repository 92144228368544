import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Switch,
  Upload,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import { compose } from "redux";
import { connect } from "react-redux";
import { delQuestion } from "./../../../store/actions/paper";

class Question extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    deleting: false,
    saving: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };
  handleChange = (val) => {
    //  console.log(val);
    this.state.fileList.push({
      uid: val.file.uid,
      status: "done",
      name: val.file.name,
      thumbUrl: val.file,
      url: val.file,
    });
  };
  handleCancel = () => {
    if (!this.state.saving)
      this.setState({
        visible: false,
      });
  };
  onFinish = async (val) => {
    //  console.log(val);
    this.setState({
      saving: true,
    });

    this.setState({
      // visible: false,
      saving: false,
    });
  };

  deleteQuestion = async () => {
    this.setState({
      deleting: true,
    });
    await this.props.delQuestion(this.props.exam.id, this.props.question.id);
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  render() {
    const { answers, elements } = this.props;
    if (!isLoaded(answers, elements)) {
      return <Card loading hoverable></Card>;
    }

    let files = [];
    for (let element of elements) {
      files.push({
        uid: element.id,
        name: element.index,
        status: "done",
        url: element.value,
        thumbUrl: element.value,
      });
    }
    this.state.fileList = files;

    return (
      <Card
        key={this.props.question.index}
        actions={[
          <Button
            className="question-action"
            type="text"
            key="edit"
            onClick={this.showModal}
            disabled={this.state.deleting}
          >
            <EyeOutlined />
          </Button>,
          <Popconfirm
            title="Are you sure you want to delete this discussion?"
            onConfirm={this.deleteQuestion}
            okText="Yes"
            cancelText="No"
            disabled={this.state.deleting}
            key="delete"
          >
            <Button
              className="question-action"
              type="text"
              key="delete"
              loading={this.state.deleting}
              disabled={this.state.deleting}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>,
        ]}
        hoverable
      >
        <Skeleton loading={this.state.loading} active>
          <Card.Meta
            style={{ textAlign: "center" }}
            title={"Question " + this.props.question.index}
          />
          <Modal
            centered
            title={"Edit question " + this.props.question.index}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            destroyOnClose={true}
            okButtonProps={{ loading: this.state.saving }}
            cancelButtonProps={{ disabled: this.state.saving }}
          >
            <Form
              onFinish={this.onFinish}
              validateMessages={{ required: "${label} is required!" }}
              ref={this.formRef}
              layout="vertical"
            >
              <Form.Item
                name={"elements"}
                label="Question Image(s)"
                // rules={[{ required: true }]}
                className={"question-image-list"}
                valuePropName="file"
              >
                <Upload.Dragger
                  beforeUpload={() => false}
                  listType="picture"
                  onChange={this.handleChange}
                  onPreview={this.handlePreview}
                  disabled={this.state.saving}
                  fileList={this.state.fileList}
                  disabled
                >
                  <p className="ant-upload-drag-icon">
                    <PlusOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Questions with multiple images, add them in order
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Divider orientation="left" plain>
                Answers
              </Divider>
              {answers.map((answer) => {
                return (
                  <Space
                    key={answer.index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 4,
                    }}
                    align="center"
                    className="question-answers"
                  >
                    <Form.Item
                      style={{ width: 320 }}
                      name={[answer.index, "value"]}
                      label={"Answer " + answer.index}
                      initialValue={
                        "<rev-sin>ms<s;=r " + answer.index + "</rev-sin>"
                      }
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Answer" disabled={true} />
                    </Form.Item>
                    <Form.Item
                      name={answer.id}
                      label={"Is Correct"}
                      // rules={[{ required: true }]}
                      initialValue={answer.isCorrect}
                      valuePropName="checked"
                    >
                      <Switch
                        // disabled={this.state.saving}
                        disabled
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </Form.Item>
                  </Space>
                );
              })}
            </Form>
          </Modal>
          <Modal
            visible={this.state.previewVisible}
            title={this.state.previewTitle}
            footer={null}
            onCancel={() =>
              this.setState({
                previewVisible: false,
              })
            }
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={this.state.previewImage}
            />
          </Modal>
        </Skeleton>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    questions: state.firestore.ordered[props.exam.id + "_questions"],
    answers:
      state.firestore.ordered[
        "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/answers"
      ],
    elements:
      state.firestore.ordered[
        "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/elements"
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    delQuestion: (paperId, questionId) =>
      dispatch(delQuestion(paperId, questionId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection:
          "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/answers",
        orderBy: ["index", "asc"],
      },
      {
        collection:
          "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/elements",
        orderBy: ["index", "asc"],
      },
    ];
  })
)(Question);
