import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  message,
  Table,
  Space,
  Tooltip,
  Button,
  Tag,
  Spin,
  Input,
  Skeleton,
} from "antd";
import moment from "moment";
import Highlighter from "react-highlight-words";
import {
  EyeOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import TicketSingle from "./TicketSingle";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

const sortByTimeB = (a, b) => {
  var A = moment(a.createdAt.toDate());
  var B = moment(b.createdAt.toDate());

  if (A.isBefore(B)) {
    return -1;
  }
  if (A.isAfter(B)) {
    return 1;
  }
  return 0;
};
class TicketTable extends Component {
  state = {
    visible: false,
    editing: null,
    loading: false,
    selected: null,
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const { topics, topics_ordered } = this.props;
    if (isLoaded(topics, topics_ordered)) {
      let topicFilters = [];
      for (let topic of topics_ordered) {
        topicFilters.push({
          text: topic.title,
          value: topic.id,
        });
      }
      const columns = [
        {
          title: "Topic",
          dataIndex: "topic",
          width: "30%",
          render: (_, record) => <p>{topics[record.topic].title}</p>,

          filters: topicFilters,
          filterMultiple: false,
          onFilter: (value, record) => record.topic.indexOf(value) === 0,
        },
        {
          title: "Message",
          dataIndex: "message",
          width: "45%",
          ...this.getColumnSearchProps("message"),
        },
        {
          title: "Status",
          width: "10%",
          render: (_, record) => (
            <Tag color={record.status === "CLOSED" ? "green" : "blue"}>
              {record.status}
            </Tag>
          ),
          filters: [
            {
              text: "Closed",
              value: "CLOSED",
            },
            {
              text: "Pending",
              value: "PENDING",
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => record.status.indexOf(value) === 0,
          sortDirections: ["descend", "ascend"],
          defaultSortOrder: "descend",
          sorter: (a, b) => a.status.length - b.status.length,
        },
        {
          title: "Created At",
          key: "createdAt",
          width: "10%",
          render: (_, record) => {
            return (
              <label>
                {record.createdAt
                  ? moment
                      .unix(record.createdAt.seconds)
                      .format("YYYY-MM-DD hh:mmA")
                  : "-"}
              </label>
            );
          },
          sorter: sortByTimeB,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Action",
          key: "operation",
          width: "5%",
          render: (_, record) => {
            return (
              <NavLink
                to={{
                  pathname: "/support/" + record.id,
                  key: record.id,
                }}
                target="_blank"
                className="waves-effect sidenav-close"
              >
                <Tooltip title="Preview">
                  <EyeOutlined />
                </Tooltip>
              </NavLink>
            );
          },
        },
      ];
      return (
        <div>
          <Table columns={columns} dataSource={this.props.data} rowKey="id" />
        </div>
      );
    }
    return <Skeleton active />;
  }
}

const mapStateToProps = (state) => {
  return {
    tickets: state.firestore.ordered.support_tickets,
    topics: state.firestore.data.support_topics,
    topics_ordered: state.firestore.ordered.support_topics,
    auth: state.firebase.auth,
    role: state.firebase.profile.token.claims.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    {
      collection: "support_tickets",
      orderBy: ["createdAt", "desc"],
    },
    {
      collection: "support_topics",
      orderBy: ["title"],
    },
  ])
)(TicketTable);
