import fileDownload from "js-file-download";

export const addPaper = (paper) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var formData = new FormData();
      formData.append("image", paper.file);

      var res = await api.post("/papers/uploadCover", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });

      if (res.status === 200) {
        await api.post(
          "/papers/create",
          {
            name: paper.name,
            fee: paper.fee,
            date: paper.date.format("YYYY-MM-DD"),
            description: paper.description,
            from: paper.time[0].format("HH:mm:ss"),
            to: paper.time[1].format("HH:mm:ss"),
            gradeUid: paper.gradeUid,
            instructions: paper.instructions,
            medium: paper.medium,
            streams: paper.streams,
            teacherUid: paper.teacherUid,
            subjectUid: paper.subjectUid,
            cover: res.data?.data[0].uid,
            noOfAnswers: paper.noOfAnswers,
            noOfQuestions: paper.noOfQuestions,
          },
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );
      }

      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const addQuestion = (paperId, question) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var elements = [];
      var answers = [];
      var i = 1;
      for (var element of question.elements.fileList) {
        const formData = new FormData();
        formData.append("image", element.originFileObj);
        var upRes = await api.post("/papers/questions/imageUpload", formData, {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        });
        elements.push({
          index: i,
          type: "IMAGE",
          value: upRes.data.data[0].url,
        });
        i = i + 1;
      }
      i = 1;
      for (let answer of question.answers) {
        answers.push({
          index: i,
          ...answer,
        });
        i = i + 1;
      }
      var res = await api.post(
        "/papers/questions",
        {
          paperUid: paperId,
          index: question.index,
          elements,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );

      var ans = await api.post(
        "/papers/questions/answers",
        {
          paperUid: paperId,
          questionUid: res.data.data.uid,
          answers,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "ADD_SUCCESS", ans });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const publishPaper = (paperId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/papers/publish",
        {
          uid: paperId,
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "PUBLISH_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "PUBLISH_ERROR", err });
    }
  };
};
export const editPaper = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.patch("/papers/edit", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "EDIT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EDIT_ERROR", err });
    }
  };
};

export const delQuestion = (paperId, questionId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.delete("/papers/questions", {
        data: {
          paperUid: paperId,
          questionUid: questionId,
        },
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "QDEL_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "QDEL_ERROR", err });
    }
  };
};

export const addDiscussion = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post("/papers/discussions", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const delDiscussion = (examId, discussionId) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.delete("/papers/discussions", {
        data: {
          examId,
          discussionId,
        },
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "DELETE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "DELETE_ERROR", err });
    }
  };
};

export const editDiscussion = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.patch("/papers/discussions", data, {
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ADD_ERROR", err });
    }
  };
};

export const delAttachment = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.delete("/papers/discussions/deleteAttachement", {
        data,
        headers: {
          Authorization: "Bearer " + state.firebase.profile.token.token,
        },
      });
      dispatch({ type: "DELETE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "DELETE_ERROR", err });
    }
  };
};

export const approveVideo = ({ examId, discussionId }) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.put(
        `/api/uploader/discussionVideoPublish/${examId}/${discussionId}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "APPROVE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "APPROVE_ERROR", err });
    }
  };
};

export const getExamScoreSheet = (data) => {
  return async (dispatch, getState, { api }) => {
    let state = getState();
    try {
      var res = await api.post(
        "/papers/getExamScoreSheet",
        Object.assign({}, { uid: data.paper_uid }),
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );

      var fileName = `${data.paper_name?.replace(
        " ",
        "_"
      )}_Score_Sheet_${new Date().getTime()}`;

      fileDownload(res.data, `${fileName}.csv`);
      dispatch({ type: "EXPORT_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "EXPORT_ERROR", err });
    }
  };
};
