import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import {
  Row,
  Col,
  Layout,
  PageHeader,
  Card,
  Button,
  Skeleton,
  List,
  Pagination,
  Input,
  Modal,
  Form,
  Select,
  message,
  Upload,
  Switch,
  InputNumber,
} from "antd";
import { PlusOutlined, LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
// import { NavLink } from "react-router-dom";
import TeacherListItem from "./components/TeacherListItem";
import { addNewTeacher, updateAWSBucketPolicy } from "../../store/actions/teacher";

class Teachers extends Component {
  state = {
    loading: false,
    addModalVisible: false,
    updatingBucketPolicy: false,
  };

  setAddModalVisible = (val) => {
    this.setState({
      addModalVisible: val,
    });
  };

  updateBucketPolicy = async () => {
    this.setState({
      updatingBucketPolicy: true
    })

    await this.props.updateAWSBucketPolicy();
    
    if (this.props.bucketPolicyUpdateError) {
      message.error("There was an error");
    } else {
      message.success("Successfully updated the AWS Bucket Policy!");
    }

    this.setState({
      updatingBucketPolicy: false,
    });
  }

  onAdd = async (val) => {
    this.setState({
      loading: true,
    });
    await this.props.addNewTeacher({
      email: val.email,
      name: val.name,
      password: val.password,
      grades: val.grades,
      subjects: Array.isArray(val.subjects) ? val.subjects : [val.subjects],
      streams: val.streams,
      isPapersEnabled: val.isPapersEnabled,
      isSessionsEnabled: val.isSessionsEnabled,
      isLiveSessionsEnabled: val.isLiveSessionsEnabled,
      isZoomWebEnabled: val.isZoomWebEnabled,
      isZoomAppEnabled: val.isZoomAppEnabled,
      score: val.score,
      cover: val.coverUid,
      photo: val.photoUid
    });
    if (this.props.addError) {
      message.error("There was an error");
    } else {
      message.success("Teacher added successfully");
    }
    this.setState({
      loading: false,
      addModalVisible: false,
    });
  };
  render() {
    const { grades, streams, teachers, subjects, token } = this.props;
    if (isLoaded(grades, streams, teachers, subjects, token)) {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Teachers"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="All teachers"
                style={{ margin: "1rem 1rem 0 1rem" }}
                bodyStyle={{ paddingTop: 0 }}
                extra={[
                  <Button
                    type="secondary"
                    disabled={this.state.updatingBucketPolicy}
                    onClick={
                      this.state.updatingBucketPolicy ? null :
                      () => {
                       this.updateBucketPolicy();
                    
                      }
                    }                    
                    key={4}
                  >
                    <div>
                      {this.state.updatingBucketPolicy ? (
                          <LoadingOutlined />
                        ) : null
                      }
                      Update AWS Bucket Policy
                    </div>
                  </Button>,
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setAddModalVisible(true);
                    }}
                    key={3}
                  >
                    Add New
                  </Button> 
                ]}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={teachers}
                  pagination={
                    <Pagination showSizeChanger defaultPageSize={10} />
                  }
                  renderItem={(teacher) => (
                    <TeacherListItem key={teacher.uid} uid={teacher.id} teacher={teacher} />
                  )}
                />
              </Card>
            </Col>
          </Row>
          <AddModal
            loading={this.state.loading}
            visible={this.state.addModalVisible}
            onOk={this.onAdd}
            onCancel={() => {
              this.setAddModalVisible(false);
            }}
            title={"Add new teacher"}
            okText={"Add"}
            grades={grades}
            streams={streams}
            subjects={subjects}
            token={token}
          />
        </Layout>
      );
    } else {
      return (
        <Layout>
          <PageHeader
            ghost={false}
            title="Teachers"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card title="All teachers" style={{ margin: "1rem 1rem 0 1rem" }}>
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const AddModal = ({
  loading,
  visible,
  onOk,
  onCancel,
  title,
  okText,
  grades,
  streams,
  subjects,
  token,
}) => {
  const [form] = Form.useForm();
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [imageUid, setImageUid] = useState(null);
  const [photoUid, setPhotoUid] = useState(null);

  // filter grades according to the first grade
  // filter either A/L OR Other Subjects
  const getFilteredGrades = () => {
    if( !selectedGrades ){
      return grades;
    }

    // check if first grade is stream selectable
    let showStreamSelectableGradesOnly = shouldShowStreamSelectableGradesOnly();
    if( showStreamSelectableGradesOnly === null ){
      return grades;
    }

    const filteredGrades = grades.filter((grade) => {
      return grade.streamSelectable === showStreamSelectableGradesOnly
    });
    return filteredGrades;
  }

  const shouldShowStreamSelectableGradesOnly = () => {
    let showStreamSelectableGradesOnly = null;

    const gradeObjectsFiltered = grades.filter((g) => g.id === selectedGrades[0]);
    if( gradeObjectsFiltered.length > 0 ){
      const firstGradeObject = gradeObjectsFiltered[0];
      showStreamSelectableGradesOnly = firstGradeObject.streamSelectable;
    }

    return showStreamSelectableGradesOnly;
  }

  // filter subjects according to grade
  const getFilteredSubjects = () => {
    if( !selectedGrades || selectedGrades.length === 0 || !subjects ){
      return [];
    }

    // filter
    const filtered = subjects.filter((subject) => {
      return subject.grades.some((g) => selectedGrades.includes(g));
    });

    return filtered;
  }

  const handleGradesChange = (value) => {
    setSelectedGrades(value);
    form.resetFields(["subjects", "streams"]);
  }

  const handleCoverChange = (val) => {
    setImageUid(null);
    if ("response" in val.file) {
      if (val.file.response.success) {
        setImageUid(val.file.response.data[0].uid);
        setUploading(false);
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      setUploading(true);
    }
  };

  const handlePhotoChange = (val) => {
    setPhotoUid(null);
    if ("response" in val.file) {
      if (val.file.response.success) {
        setPhotoUid(val.file.response.data[0].uid);
        setUploading(false);
        message.success("Photo uploaded successfully");
      } else {
        message.warn("Photo upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      setUploading(true);
    }
  };
  
  return (
    <Modal
      destroyOnClose={true}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={false}
      visible={visible}
      title={title}
      okText={okText}
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            let newValue = Object.assign({}, values, {
              coverUid: imageUid,
              photoUid: photoUid,
            })

            onOk(newValue);
            setSelectedGrades([]);
            form.resetFields();
          })
          .catch((info) => {
            // console.log("Validate Failed:", info);
          });
      }}
    >
      <Form form={form} layout="vertical" name="modal-form">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Password cannot be empty",
            },
            {
              type: "email",
              message: "Enter valid email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password cannot be empty",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Name cannot be empty",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Grade(s)" name="grades" rules={[{ required: true }]}>
          <Select
            showSearch
            mode="multiple"
            placeholder="Select a Grade"
            disabled={loading}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleGradesChange}
          >
            {getFilteredGrades().map((grade) => {
              return (
                <Select.Option value={grade.id} key={grade.id}>
                  {grade.display}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subjects"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a subject"
            disabled={loading}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {getFilteredSubjects().map((subject) => {
              return (
                <Select.Option value={subject.id} key={subject.id}>
                  {subject.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {
          shouldShowStreamSelectableGradesOnly() &&
          <Form.Item
            label="Stream(s)"
            name="streams"
            rules={[{ required: true }]}
          >
            <Select
              disabled={loading}
              mode="multiple"
              placeholder="Select streams"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {streams.map((stream) => (
                <Select.Option key={stream.id} value={stream.id}>
                  {stream.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }

        <Form.Item
          label="Papers Enabled"
          name="isPapersEnabled"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="Sessions Enabled"
          name="isSessionsEnabled"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="Live Sessions Enabled (can teacher create Zoom sessions)"
          name="isLiveSessionsEnabled"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="Live Sessions Enabled in App"
          name="isZoomAppEnabled"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label="Live Sessions Enabled in Web"
          name="isZoomWebEnabled"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          name="score"
          label="Display Score (1-100, 0=do not display)"
        >
          <InputNumber min={0} max={100} />
        </Form.Item>

        <Form.Item
          name={"cover"}
          label="Cover Image (512px x 250px)"
          // rules={[{ required: true }]}
          // valuePropName="file"
          className={"question-image-list"}
        >
          <ImgCrop aspect={256 / 125} rotate>
            <Upload
              accept={".jpg"}
              listType="picture-card"
              disabled={uploading}
              onChange={handleCoverChange}
              showUploadList={false}
              action={ process.env.REACT_APP_CLOUDFUNCTIONS_URL + "/api/teachers/uploadAsset"}
              headers={{
                "Authorization": "Bearer " + token,
              }}
            >
              {imageUid ? (
                // <img
                //   src={imageUrl}
                //   alt="avatar"
                //   style={{ width: "100%" }}
                // />
                <CheckCircleOutlined />
              ) : (
                <div>
                  {uploading ? (
                    <LoadingOutlined />
                  ) : (
                    <PlusOutlined />
                  )}
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          name={"photo"}
          label="Teacher Photo (500px x 500px)"
          // rules={[{ required: true }]}
          // valuePropName="file"
          className={"question-image-list"}
        >
          <ImgCrop aspect={1 / 1} rotate>
            <Upload
              accept={".jpg"}
              listType="picture-card"
              disabled={uploading}
              onChange={handlePhotoChange}
              showUploadList={false}
              action={ process.env.REACT_APP_CLOUDFUNCTIONS_URL + "/api/teachers/uploadAsset"}
              headers={{
                "Authorization": "Bearer " + token,
              }}
            >
              {photoUid ? (
                // <img
                //   src={photoUrl}
                //   alt="avatar"
                //   style={{ width: "100%" }}
                // />
                <CheckCircleOutlined />

              ) : (
                <div>
                  {uploading ? (
                    <LoadingOutlined />
                  ) : (
                    <PlusOutlined />
                  )}
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

      </Form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    grades: state.firestore.ordered.grades,
    streams: state.firestore.ordered.streams,
    teachers: state.firestore.ordered.teachers,
    subjects: state.firestore.ordered.subjects,
    addError: state.teacher.addError,
    bucketPolicyUpdateError: state.teacher.bucketPolicyUpdateError,
    auth: state.firebase.auth,
    token: state.firebase.profile.token.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    addNewTeacher: (data) => dispatch(addNewTeacher(data)),
    updateAWSBucketPolicy: () => dispatch(updateAWSBucketPolicy())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: "teachers", orderBy: "name" },
    { collection: "grades", orderBy: ["index", "desc"] },
    { collection: "streams" },
    { collection: "subjects" },
  ])
)(Teachers);
