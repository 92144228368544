import {
  Avatar,
  Card,
  Col,
  Layout,
  PageHeader,
  Result,
  Row,
  Skeleton,
  Typography,
} from "antd";
import React, { Component } from "react";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import PreviewLiveSessionCardGrid from "./components/PreviewLiveSessionCardGrid";
import { compose } from "redux";
import { connect } from "react-redux";

const { Paragraph, Text } = Typography;

class PreviewClass extends Component {
  state = {
    selectedCategoryId: null,
    selectedCategoryName: null,
  };
  selectCategory = (id, name) => {
    this.setState({
      selectedCategoryId: id,
      selectedCategoryName: name,
    });
  };
  render() {
    const { selected, classId } = this.props;
    if (
      selected != null &&
      isLoaded(selected) &&
      selected.id === classId
    ) {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Class preview"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  <div className="class-name">
                    <Text>{selected.name}</Text>
                  </div>
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Avatar shape="square" src={selected.cover} />}
              >
                <Paragraph>{selected.description}</Paragraph>
              </Card>
              <PreviewLiveSessionCardGrid
                selected={{ ...selected, id: classId }}
              />
            </Col>
          </Row>
        </Layout>
      );
    }
    // else if (isEmpty(selected)) {
    //   return (
    //     <Layout>
    //       <PageHeader
    //         onBack={() => window.history.back()}
    //         ghost={false}
    //         title="Class preview"
    //         className="site-page-header"
    //       ></PageHeader>
    //       <Row>
    //         <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
    //           <Card
    //             title="Class doesn't exists"
    //             style={{ margin: "1rem 1rem 0 1rem" }}
    //           >
    //             <Result
    //               status="404"
    //               title="404"
    //               subTitle="Sorry, the class you've selected does not exist."
    //             />
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Layout>
    //   );
    // }
    else {
      return (
        <Layout>
          <PageHeader
            onBack={() => window.history.back()}
            ghost={false}
            title="Class preview"
            className="site-page-header"
          ></PageHeader>
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title="Class is loading..."
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={<Skeleton.Button active />}
              >
                <Skeleton active />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }
  }
}

const mapStateToProps = (state, routeProps) => {
  return {
    selected: state.firestore.ordered.selected_class
      ? state.firestore.ordered.selected_class[0]
      : state.firestore.data.selected_class,
    classId: routeProps.match.params.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((routeProps) => {
    return [
    {
      collection: "classes",
      doc: routeProps.match.params.id,
      storeAs: "selected_class",
    },
  ]})
)(PreviewClass);
