import { Avatar, Space, Table, Tooltip } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import { sortAlphabetically } from "../../../utils/helpers";

class ClassesTable extends Component {
  state = {
    visible: false,
    editing: null,
    loading: false,
  };

  render() {
    const columns = [
      {
        title: "Cover",
        dataIndex: "cover",
        render: (_, record) => (
          <Avatar src={record.cover} alt="Class Cover" shape="square" />
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => sortAlphabetically(a.name, b.name),
        width:"40%"
      },
      {
        title: "Description",
        dataIndex: "description",
      },
      {
        title: "Action",
        key: "operation",
        render: (_, record) => {
          return record.status === "FINALIZED" ? (
            <Space>
              <NavLink
                to={{
                  pathname: "/classes/preview/" + record.id,
                  key: record.id,
                }}
                className="waves-effect sidenav-close"
              >
                <Tooltip title="Preview">
                  <EyeOutlined />
                </Tooltip>
              </NavLink>
              <Tooltip
                placement="top"
                title="Finalized classes cannot be edited"
              >
                <EditOutlined style={{ color: "#c9c9c9" }} />
              </Tooltip>
            </Space>
          ) : (
            <Space>
              <NavLink
                to={{
                  pathname: "/classes/preview/" + record.id,
                  key: record.id,
                }}
                className="waves-effect sidenav-close"
              >
                <Tooltip title="Preview">
                  <EyeOutlined />
                </Tooltip>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/classes/edit/" + record.id,
                  key: record.id,
                }}
                className="waves-effect sidenav-close"
              >
                <Tooltip title="Edit">
                  <EditOutlined />
                </Tooltip>
              </NavLink>
            </Space>
          );
        },
      },
    ];

    // add teacher column
    if( this.props.showTeacherColumn ){
      columns.splice(2, 0, 
        {
          title: "Teacher",
          dataIndex: "teacherName",
          sorter: (a, b) => sortAlphabetically(a.teacherName, b.teacherName),
          width:"20%"
        },  
      );
    }

    return (
      <div>
        <Table columns={columns} dataSource={this.props.data} rowKey="id" />
      </div>
    );
  }
}

export default ClassesTable;
