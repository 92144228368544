import { Button, Input, Space, Table, Tag, Tooltip } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  OrderedListOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { Component } from "react";

import Highlighter from "react-highlight-words";
import { NavLink } from "react-router-dom";
import moment from "moment";

const sortByTimeB = (a, b) => {
  var A = moment(a.createdAt.toDate());
  var B = moment(b.createdAt.toDate());

  if (A.isBefore(B)) {
    return -1;
  }
  if (A.isAfter(B)) {
    return 1;
  }
  return 0;
};
class PapersTable extends Component {
  state = {};

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search by ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: "40%",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Date",
        dataIndex: "date",
        width: "10%",
        ...this.getColumnSearchProps("date"),
      },
      {
        title: "Status",
        dataIndex: "status",
        width: "10%",
        render: (_, record) => (
          <>
            <Tag color={record.status === "FINALIZED" ? "green" : "blue"}>
              {record.status}
            </Tag>
            {record.lastPublishedAt &&
              record.updatedAt &&
              record.lastPublishedAt.seconds < record.updatedAt.seconds && (
                <Tag color={"red"}>SHOULD REPUBLISH</Tag>
              )}
          </>
        ),
        filters: [
          {
            text: "Draft",
            value: "DRAFT",
          },
          {
            text: "Finalized",
            value: "FINALIZED",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
      },
      {
        title: "Exam Booked",
        dataIndex: "totalStudentsBooked",
      },
      {
        title: "Exam Taken",
        dataIndex: "totalStudentsTaken",
      },
      {
        title: "Date Added",
        dataIndex: "createdAt",
        render: (_, record) => (
          <div>
            {moment(record.createdAt.toDate()).format("MMMM Do YYYY, h:mm A")}
          </div>
        ),
        sorter: sortByTimeB,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Action",
        key: "operation",
        render: (_, record) => {
          // return record.status === "FINALIZED" ? (
          //   <Space>
          //     <NavLink
          //       to={{
          //         pathname: "/papers/preview/" + record.id,
          //         key: record.id,
          //       }}
          //       className="waves-effect sidenav-close"
          //     >
          //       <Tooltip title="Preview">
          //         <EyeOutlined />
          //       </Tooltip>
          //     </NavLink>
          //     <NavLink
          //       to={"/papers/preview/" + record.id + "/bookings"}
          //       key="bookings"
          //     >
          //       <Tooltip title="View bookings">
          //         <OrderedListOutlined />
          //       </Tooltip>
          //     </NavLink>
          //     <Tooltip
          //       placement="top"
          //       title="Finalized papers cannot be edited"
          //     >
          //       <EditOutlined style={{ color: "#c9c9c9" }} />
          //     </Tooltip>
          //   </Space>
          // ) : (
          return (
            <Space>
              <NavLink
                to={{
                  pathname: "/papers/preview/" + record.id,
                  key: record.id,
                }}
                className="waves-effect sidenav-close"
              >
                <Tooltip title="Preview">
                  <EyeOutlined />
                </Tooltip>
              </NavLink>
              <NavLink
                to={"/papers/preview/" + record.id + "/bookings"}
                key="bookings"
              >
                <Tooltip title="View bookings">
                  <OrderedListOutlined />
                </Tooltip>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/papers/edit/" + record.id,
                  key: record.id,
                }}
                className="waves-effect sidenav-close"
              >
                <Tooltip title="Edit">
                  <EditOutlined />
                </Tooltip>
              </NavLink>
            </Space>
          );
          // );
        },
      },
    ];
    return (
      <div>
        <Table columns={columns} dataSource={this.props.data} rowKey="id" />
      </div>
    );
  }
}

export default PapersTable;
