export const addNewTeacher = (data) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();
      var res = await api.post(
        "/api/teachers/create",
        {
          email: data.email,
          password: data.password,
          name: data.name,
          gradeUids: data.grades,
          subjectUids: data.subjects,
          streamUids: data.streams,
          isPapersEnabled: data.isPapersEnabled,
          isSessionsEnabled: data.isSessionsEnabled,
          isLiveSessionsEnabled: data.isLiveSessionsEnabled,
          isZoomWebEnabled: data.isZoomWebEnabled,
          isZoomAppEnabled: data.isZoomAppEnabled,
          score: data.score,
          cover: data.cover,
          photo: data.photo
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "TEACHER_ADD_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "TEACHER_ADD_ERROR", err });
    }
  };
};

export const sendPasswordResetEmail = (userEmail) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      const firebase = getFirebase();
      await firebase
        .auth()
        .sendPasswordResetEmail(userEmail);
      
      dispatch({ type: "SEND_PASSWORD_RESET_EMAIL_SUCCESS", res: true });
    } catch (err) {
      dispatch({ type: "SEND_PASSWORD_RESET_EMAIL_ERROR", err });
    }
  };
}

export const toggleAccountDisableStatus = (userUid) => {
  return async (dispatch, getState, { api }) => {
    try {
      let state = getState();
      var res = await api.post(
        "/api/teachers/toggleAccountDisableStatus",
        {
          userUid: userUid
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );
      dispatch({ type: "ACCOUNT_TOGGLE_DISABLE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "ACCOUNT_TOGGLE_DISABLE_ERROR", err });
    }
  };
}

export const addTeacherIAMUser = (teacherUid) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post(
        "/services/addTeacherIAMUser",
        {
          teacherUid: teacherUid
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );

      // try to update policy
      try {
        await aws.post(
          "/services/updateBucketPolicy",
          {},
          {
            headers: {
              Authorization: "Bearer " + state.firebase.profile.token.token,
            },
          }
        );

      } catch (error) {
        console.error(error)
      }

      dispatch({ type: "IAM_USER_CREATE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "IAM_USER_CREATE_ERROR", err });
    }
  };
}

export const updateAWSBucketPolicy = () => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post(
        "/services/updateBucketPolicy",
        {},
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );      

      dispatch({ type: "BUCKET_POLICY_UPDATE_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "BUCKET_POLICY_UPDATE_ERROR", err });
    }
  };
}

export const toggleTeacherUploadStatus = (teacherUid) => {
  return async (dispatch, getState, { aws }) => {
    try {
      let state = getState();
      var res = await aws.post(
        "/services/toggleTeacherUploadStatus",
        {
          teacherUid: teacherUid
        },
        {
          headers: {
            Authorization: "Bearer " + state.firebase.profile.token.token,
          },
        }
      );      

      dispatch({ type: "TOGGLE_TEACHER_UPLOAD_STATUS_SUCCESS", res });
    } catch (err) {
      dispatch({ type: "TOGGLE_TEACHER_UPLOAD_STATUS_ERROR", err });
    }
  };
}