import React, { useState } from "react";
import { message, Modal, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ImageEditModal = ({
  visible,
  onCreate,
  onCancel,
  loading,
  aspect = 1,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  return (
    <Modal
      destroyOnClose
      width="240px"
      visible={visible}
      title="Select a new image"
      okText="Ok"
      cancelText="Cancel"
      onCancel={onCancel}
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={() => {
        if (!imageUrl) return message.warn("select an image");
        if (file) {
          onCreate(file);
        } else {
          message.error("There was an error, please refresh the page.");
        }
      }}
    >
      <ImgCrop aspect={aspect} rotate>
        <Upload
          accept={".jpg"}
          listType="picture-card"
          className="cover-edit"
          disabled={uploading}
          showUploadList={false}
          beforeUpload={(e) => {
            setFile(e);
            var urlCreator = window.URL || window.webkitURL;
            setImageUrl(urlCreator.createObjectURL(e));
            return false;
          }}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="cover" style={{ width: "100%" }} />
          ) : (
            <div>
              {uploading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
            </div>
          )}
        </Upload>
      </ImgCrop>
    </Modal>
  );
};

export default ImageEditModal;