import { Avatar, Card, Layout, Menu, Row } from "antd";
import React, { Component } from "react";

import AdminMenuLinks from "./adminMenuLinks";
import TeacherMenuLinks from "./teacherMenuLinks";
import { LogoutOutlined } from "@ant-design/icons";
import UserMenuLinks from "./userMenuLinks";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/auth";

const { Sider } = Layout;
const { Meta } = Card;

class SideNav extends Component {
  state = {
    collapsed: false,
  };
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const { profile, email } = this.props;
    return (
      <Sider
        theme="dark"
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >
        {this.state.collapsed ? (
          <Row
            justify="center"
            align="middle"
            style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
          >
            <Avatar style={{ backgroundColor: "#e6f7ff", color: "#1890ff" }}>
              {profile.name[0]}
            </Avatar>
          </Row>
        ) : (
          <Card bodyStyle={{ background: "#002140" }} bordered={false}>
            <Meta
              title={profile.name}
              description={email}
              className="user-info"
            />
          </Card>
        )}
        {
          profile.token.claims.role == "COMPANY_ADMIN" && <AdminMenuLinks />
        }
        {
          profile.token.claims.role == "TEACHER" && <TeacherMenuLinks />
        }
        {
          profile.token.claims.role == "TEACHER_DATA_ENTRY" && <UserMenuLinks />
        }

        <Menu
          theme="dark"
          mode="inline"
          style={{ bottom: "48px", position: "fixed", maxWidth: "200px" }}
        >
          <Menu.Item
            key="9"
            icon={<LogoutOutlined />}
            onClick={this.props.signOut}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(null, mapDispatchToProps)(SideNav);
