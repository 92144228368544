import React, { Component } from "react";
import {Tag} from 'antd'
import {LoadingOutlined} from '@ant-design/icons'
import { connect } from "react-redux";
import moment from "moment";
import {aws} from '../../../utils/api';
class CardListItem extends Component {
  
  state = {
    loading: false,
    bookingCount: null,
  }

  getHexColorFromFlutterHex = (color) => color.replace("0xFF", "#");

  fetchBookingCount = async () => {
    const classCardUid = this.props.card?.id;
    const token = this.props.token;

    if( classCardUid && token ){
      const res = await aws.post(
        "/payments/getBookingCount",
        {
          entityType: 'C',
          entityUid: classCardUid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // set count
      const count = res?.data?.data?.count;
      this.setState({
        bookingCount: count ? count : 0,
      })
    }
  }

  componentDidMount() {
    this.fetchBookingCount();
  }

  render() {
    return (
      <div
        className={`${
          this.state.loading ? "animate-pulse" : ""
        } bg-gradient-to-r from-blue-500 to-blue-400 select-none rounded-md shadow w-full overflow-hidden p-2 flex flex-col border hover:shadow-md cursor-pointer duration-300 ease-out`}
        style={{
          "--tw-gradient-from": this.getHexColorFromFlutterHex(
            this.props.card.gradientTopLeft ?? "0xFF3366FF"
          ),
          "--tw-gradient-to": this.getHexColorFromFlutterHex(
            this.props.card.gradientBottomRight ?? "0xFF00CCFF"
          ),
        }}
      >
        <div className="flex flex-row space-x-2">
          <div
            className="bg-gray-400 h-16 w-16 rounded-md flex-shrink-0 bg-center bg-cover"
            style={{
              backgroundImage: `url(${this.props.cover})`,
            }}
          ></div>
          <div className="text-white">
            <div className="text-lg font-sans font-bold">{this.props.card.name}</div>
            <div className="flex flex-row items-end">
              <div className="text-sm py-1 pr-1">Rs.</div>
              <div className="text-3xl font-bold font-mono">{this.props.card.amount}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-2 flex-grow justify-between">
          {
            !this.props.hideDescription &&
            <div className="text-sm h-14 overflow-x-auto overflow-y-hidden leading-none text-justify text-gray-100">
              {this.props.card.description}
            </div>
          }
          
          <div className="flex flex-row justify-between text-xs mt-2 text-white">
            <div className="flex flex-row justify-between space-x-1">
              <div className="opacity-70">Valid from:</div>
              <div className="font-bold">
                {moment(this.props.card.validFrom?.toDate()).format("DD/MM/YY")}
              </div>
            </div>
            <div className="flex flex-row justify-between space-x-1">
              <div className="opacity-70">Valid till:</div>
              <div className="font-bold">
                {moment(this.props.card.validTill?.toDate()).format("DD/MM/YY")}
              </div>
            </div>
          </div>
  
          <div className="flex flex-row justify-center mt-2">
            {
              this.state.bookingCount == null &&
              <LoadingOutlined />
            }
            {
              this.state.bookingCount == 0 &&
              <Tag style={{ display: "inline-block" }}>No bookings yet</Tag>
            }
            {
              this.state.bookingCount > 0 &&
              <Tag color="blue">
                  Students Booked : {this.state.bookingCount}
                </Tag>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.firebase.profile.token.token,
  };
};

export default connect(mapStateToProps)(CardListItem);
