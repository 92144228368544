import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Upload,
  message,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import Discussion from "./Discussion";
import ImgCrop from "antd-img-crop";
import { addDiscussion } from "../../../store/actions/paper";
import { compose } from "redux";
import { connect } from "react-redux";
const { REACT_APP_CLOUDFUNCTIONS_URL } = process.env;

class Discussions extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    file: null,
    imageUrl: "",
    videoType: "DEFAULT",
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
        imageUrl: "",
      });
  };
  onFinish = async (val) => {
    if (!this.state.imageUrl) {
      message.error("Select a cover image");
      return;
    }
    this.setState({
      loading: true,
    });

    await this.props.addDiscussion({
      ...val,
      vidStart: val.startEnd[0].format(),
      vidEnd: val.startEnd[1].format(),
      cover: this.state.imageUrl,
      type: this.state.videoType,
    });
    if (!this.props.error) {
      message.success("Discussion added successfully");
    } else {
      message.error("There was an error adding discussion");
    }
    this.setState({
      visible: false,
      loading: false,
      imageUrl: "",
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleCoverChange = (val) => {
    this.setState({ imageUrl: undefined });
    if ("response" in val.file) {
      if (val.file.response.success) {
        this.setState({
          imageUrl: val.file.response?.data[0].url,
          uploading: false,
        });
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      this.setState({ uploading: true });
    }
    this.setState({ coverFile: val.file });
  };
  render() {
    const { discussions, token } = this.props;
    const { imageUrl } = this.state;
    const modal = (
      <>
        <Modal
          centered
          title={"Add new discussion"}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          okButtonProps={{ loading: this.state.loading }}
          cancelButtonProps={{ disabled: this.state.loading }}
          width={720}
        >
          <Form
            onFinish={this.onFinish}
            validateMessages={{ required: "${label} is required!" }}
            ref={this.formRef}
            layout="vertical"
          >
            <Form.Item
              name={"examId"}
              hidden
              initialValue={this.props.selected.id}
            ></Form.Item>
            <Form.Item
              name={"teacherUid"}
              hidden
              initialValue={this.props.selected.teacherUid}
            ></Form.Item>
            <Form.Item
              name={"cover"}
              label="Cover Image"
              className={"question-image-list"}
            >
              <ImgCrop rotate aspect={16 / 9}>
                <Upload
                  accept={".jpg"}
                  listType="picture-card"
                  className="class-cover-uploader"
                  onPreview={this.handlePreview}
                  disabled={this.state.loading || this.state.uploading}
                  onChange={this.handleCoverChange}
                  showUploadList={false}
                  headers={{
                    Authorization: "Bearer " + token,
                  }}
                  action={
                    REACT_APP_CLOUDFUNCTIONS_URL +
                    "papers/discussions/imageUpload"
                  }
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <div>
                      {this.state.uploading ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
            <Form.Item
              name={"name"}
              label="Discussion Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"subHeading"}
              label="Discussion Subtitle"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"description"}
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item name={"type"} label="Video Type">
              <Select
                defaultValue={this.state.videoType}
                disabled={this.state.loading}
                style={{ width: 250 }}
                onChange={(val) => {
                  this.setState({
                    videoType: val,
                  });
                }}
              >
                <Select.Option value="DEFAULT">DEFAULT</Select.Option>
                <Select.Option value="YOUTUBE">YOUTUBE</Select.Option>
                <Select.Option value="ATTACHMENT_ONLY">
                  ATTACHMENT ONLY
                </Select.Option>
              </Select>
            </Form.Item>
            {this.state.videoType === "YOUTUBE" && (
              <Form.Item name={"videoUrl"} label="Video URL">
                <Input />
              </Form.Item>
            )}
            <Form.Item
              label="Discussion Time"
              name="startEnd"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker disabled={this.state.loading} showTime />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={() =>
            this.setState({
              previewVisible: false,
            })
          }
        >
          <img
            alt="selectedple"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </>
    );
    if (!isLoaded(discussions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Discussions"
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      );
    }
    if (isEmpty(discussions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Discussions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Empty
                description={<span>There are no discussions to be found</span>}
              >
                <Button type="primary" onClick={() => this.showModal()}>
                  Add new
                </Button>
              </Empty>
            </Card>
          </Col>
          {modal}
        </Row>
      );
    }

    return (
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
          <Card
            title="Discussions"
            style={{ margin: "1rem 1rem 0 1rem" }}
            extra={
              <Button type="primary" onClick={() => this.showModal()}>
                Add a discussion
              </Button>
            }
          >
            <Row gutter={[16, 16]}>
              {discussions.map((discussion) => {
                return (
                  <Col span={6} key={discussion.id}>
                    <Discussion
                      discussion={discussion}
                      selected={this.props.selected}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
        {modal}
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    discussions: state.firestore.ordered[props.selected.id + "_discussions"],
    token: state.firebase.profile.token.token,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addDiscussion: (data) =>
      dispatch(addDiscussion({ ...data, examId: props.selected.id })),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "exams",
        doc: props.selected.id,
        subcollections: [
          { collection: "discussions", orderBy: ["vidStart", "desc"] },
        ],
        storeAs: props.selected.id + "_discussions",
      },
    ];
  })
)(Discussions);
