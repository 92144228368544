import React from "react";
import { Result, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import { signOut } from "../../store/actions/auth";

const NotAuthorized = (props) => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Col sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 5 }}>
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={props.signOut}>
              Go Back
            </Button>
          }
        />
      </Col>
    </Row>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};
export default connect(null, mapDispatchToProps)(NotAuthorized);
