import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { Row, Col, Card, Empty, Skeleton } from "antd";
import Question from "./PreviewQuestion";

class PreviewQuestions extends Component {
  state = {
    visible: false,
    loading: false,
  };

  render() {
    const { questions } = this.props;

    if (!isLoaded(questions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Questions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      );
    }
    if (isEmpty(questions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Questions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Empty
                description={<span>There are no questions to be found</span>}
              ></Empty>
            </Card>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
          <Card title="Questions" style={{ margin: "1rem 1rem 0 1rem" }}>
            <Row gutter={[16, 16]}>
              {questions.map((question) => {
                return (
                  <Col span={4} key={question.index}>
                    <Question question={question} exam={this.props.exam} />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    questions: state.firestore.ordered[props.exam.id + "_questions"],
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "exams",
        doc: props.exam.id,
        subcollections: [
          { collection: "questions", orderBy: ["index", "asc"] },
        ],
        storeAs: props.exam.id + "_questions",
      },
    ];
  })
)(PreviewQuestions);
