import React from "react";
import {
  Modal,
  Form,
  Typography,
} from "antd";

const { Text } = Typography;

const ToggleTeacherUploadStatusModal = ({
    loading,
    visible,
    onOk,
    onCancel,
    title,
    okText,
    email,
    canUpload = false,
  }) => {
    const [form] = Form.useForm();
    
    return (
      <Modal
        destroyOnClose={true}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
        maskClosable={false}
        visible={visible}
        title={title}
        okText={okText}
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          onOk();
        }}
      >
        <div>
          This will {canUpload ? 'disable': 'enable'} the uploads for teacher <Text mark>{email}</Text>. Continue?
        </div>
      </Modal>
    );
};

export default ToggleTeacherUploadStatusModal;