import { KeyOutlined, StopOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { Space, Table, Tooltip, Typography } from "antd";
import { draftPost, publishPost } from "../../../store/actions/post";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sortAlphabetically } from "../../../utils/helpers";

class PostTable extends Component {
 
  deletePost = async () => {
    console.log("clicked delete");
  };

  render() {
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => sortAlphabetically(a.title, b.title),
        width: "70%",
      },
      {
        title: "Type",
        dataIndex: "contentType",
        filters: [
          {
            text: "IMG",
            value: "IMG",
          },
          {
            text: "VID",
            value: "VID",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.contentType.indexOf(value) === 0,
      },
      {
        title: "Status",
        dataIndex: "status",
        filters: [
          {
            text: "DRAFT",
            value: "DRAFT",
          },
          {
            text: "PUBLISHED",
            value: "PUBLISHED",
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
      },
      {
        title: "Action",
        key: "operation",
        render: (_, record) => {
          return (
            <Space>
              <Link to={`/posts/${record.id}`}>Edit</Link>

              <Typography.Link
                onClick={() => {
                  record.status === "PUBLISHED"
                    ? this.props.draftPost(record.id)
                    : this.props.publishPost(record.id);
                }}
              >
                {record.status === "PUBLISHED" ? "Draft" : "Publish"}
              </Typography.Link>

              <Typography.Link onClick={this.deletePost}>
                Delete
              </Typography.Link>
            </Space>
          );
        },
      },
    ];
    return (
      <div>
        <Table columns={columns} dataSource={this.props.data} rowKey="id" />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch, routeProps) => {
  return {
    publishPost: (postId) => dispatch(publishPost(postId)),
    draftPost: (postId) => dispatch(draftPost(postId)),
  };
};

export default connect(null, mapDispatchToProps)(PostTable);
