import axios from "axios";
import firebase from "../config/fbConfig";

const api = axios.create({
  baseURL: process.env.REACT_APP_CLOUDFUNCTIONS_URL,
  withCredentials: false,
});

api.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const authHeader = `Bearer ${token}`;
    const headers = {
      Authorization: authHeader,
    };
    config.headers = headers;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const aws = axios.create({
  baseURL: process.env.REACT_APP_AWS_LAMBDA_BASE_URL,
  withCredentials: false,
});

aws.interceptors.request.use(
  async (config) => {
    const token = await firebase.auth().currentUser?.getIdToken(true);
    const authHeader = `Bearer ${token}`;
    const headers = {
      authorization: authHeader,
    };
    config.headers = headers;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api, aws };
