import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Skeleton,
  Switch,
  Upload,
  message,
  Typography,
} from "antd";
import { LoadingOutlined, PlusOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";

import ImgCrop from "antd-img-crop";
import Session from "./LiveSession";
import { addLiveSession } from "../../../store/actions/onlineClass";
import { compose } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

const { Text } = Typography;

class LiveSessions extends Component {
  formRef = React.createRef();
  state = {
    visible: false,
    loading: false,
    uploading: false,
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    file: null,
    imageUid: "",
    extAvailable: false,
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleOk = () => {
    this.formRef.current.submit();
  };

  handleCancel = () => {
    if (!this.state.loading)
      this.setState({
        visible: false,
        imageUid: "",
      });
  };
  onFinish = async (val) => {
    if (!this.state.imageUid) {
      message.error("Select a cover image");
      return;
    }
    this.setState({
      loading: true,
    });
    await this.props.addSession({
      ...val,
      vidStart: val.startEnd[0].valueOf(),
      vidEnd: val.startEnd[1].valueOf(),
      coverUid: this.state.imageUid,
    });
    if (!this.props.error) {
      message.success("Session added successfully");
    } else {
      message.error("There was an error adding session");
    }
    this.setState({
      visible: false,
      loading: false,
      imageUid: "",
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleCoverChange = (val) => {
    console.log(val);
    this.setState({ imageUid: "" });
    if ("response" in val.file) {
      if (val.file.response.success) {
        this.setState({
          imageUid: val.file.response.data ? val.file.response.data[0].uid : '',
          uploading: false,
        });
        message.success("Cover uploaded successfully");
      } else {
        message.warn("Cover upload failed, please try again!");
      }
    }
    if (val.file.status === "uploading") {
      this.setState({ uploading: true });
    }
    this.setState({ coverFile: val.file });
  };

  extAvailable = (val) => {
    this.setState({
      extAvailable: val,
    });
  };
  getAddLiveSessionButton = () => {

    if( isLoaded(this.props.teacherProfile) ){

      // is zoom account connected
      if( this.props.teacherProfile.isZoomAccountConnected && this.props.teacherProfile.hasLicensedZoomAccount ){
        return (
          <Button type="primary" onClick={() => this.showModal()}>
            Add new
          </Button>
        );
      }else if( this.props.profile.type === 'TEACHER' ){
        return (
          <NavLink
            to={{
              pathname: "/profile",
            }}
            className="waves-effect sidenav-close"
          >
            <Button type="primary" key="console">
              Connect Zoom Account / Check configuration from Profile page
            </Button>
          </NavLink>
        );
      }else {
        return (
          <Text mark>
            Zoom Account not connected! Ask your Teacher to connect a Zoom Account by going to their `Profile` page
          </Text>
        );
      } 

    }else{
      return ("");
    }
    
  }

  render() {
    const { sessions } = this.props;
    const { imageUid } = this.state;
    const modal = (
      <>
        <Modal
          centered
          title={"Add new zoom session"}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          okButtonProps={{ loading: this.state.loading }}
          cancelButtonProps={{ disabled: this.state.loading }}
          width={720}
        >
          <Form
            onFinish={this.onFinish}
            validateMessages={{ required: "${label} is required!" }}
            ref={this.formRef}
            layout="vertical"
          >
            <Form.Item
              name={"cover"}
              label="Cover Image"
              className={"question-image-list"}
            >
              <ImgCrop rotate aspect={16 / 9}>
                <Upload
                  accept={".jpg"}
                  listType="picture-card"
                  className="class-cover-uploader"
                  onPreview={this.handlePreview}
                  disabled={this.state.loading || this.state.uploading}
                  onChange={this.handleCoverChange}
                  showUploadList={false}
                  action={process.env.REACT_APP_CLOUDFUNCTIONS_URL + "/api/liveSessions/uploadCover"}
                  headers={{
                    'Authorization': `Bearer ${this.props.profile.token.token}`
                  }}
                >
                  {imageUid ? (
                    <CheckCircleOutlined />
                  ) : (
                    <div>
                      {this.state.uploading ? (
                        <LoadingOutlined />
                      ) : (
                        <PlusOutlined />
                      )}
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
            <Form.Item
              name={"name"}
              label="Session Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={"description"}
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name={"fee"}
              label="Fee (Rs.)"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>

            {/* <Form.Item
              name={"meetingUrl"}
              label="Meeting URL"
            >
              <Input />
            </Form.Item> */}
            <Form.Item
              label="Session Time"
              name="startEnd"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker disabled={this.state.loading} showTime />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.previewVisible}
          title={this.state.previewTitle}
          footer={null}
          onCancel={() =>
            this.setState({
              previewVisible: false,
            })
          }
        >
          <img
            alt="selectedple"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </>
    );
    if (!isLoaded(sessions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="Zoom Sessions"
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      );
    }
    if (isEmpty(sessions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Zoom Sessions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Empty
                description={<span>There are no sessions to be found</span>}
              >
                {this.getAddLiveSessionButton()}
              </Empty>
            </Card>
          </Col>
          {modal}
        </Row>
      );
    }

    return (
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
          <Card
            title="Zoom Sessions"
            style={{ margin: "1rem 1rem 0 1rem" }}
            extra={
              this.getAddLiveSessionButton()
            }
          >
            <Row gutter={[16, 16]}>
              {sessions.map((session) => {
                return (
                  <Col span={6} key={session.id}>
                    <Session session={session} selected={this.props.selected} />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
        {modal}
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  // console.log(state.firestore);
  return {
    sessions: state.firestore.ordered[props.selected.id + "_live_sessions"],
    profile: state.firebase.profile,
    teacherProfile: state.firestore.data.teacher_profile,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    addSession: (data) =>
      dispatch(addLiveSession({ ...data, classUid: props.selected.id })),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "classes",
        doc: props.selected.id,
        subcollections: [
          { collection: "live_sessions", orderBy: ["vidStart", "desc"] },
        ],
        storeAs: props.selected.id + "_live_sessions",
      },
      {
        collection: "teachers",
        doc: props.selected.teacher,
        storeAs: "teacher_profile",
      },
    ];
  })
)(LiveSessions);
