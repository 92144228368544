import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import { Card, Divider, Form, Button, Skeleton, Modal, Switch } from "antd";
import { EyeOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

class PreviewQuestion extends Component {
  state = {
    visible: false,
    loading: false,
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  render() {
    const { answers, elements } = this.props;
    if (!isLoaded(answers, elements)) {
      return <Card loading hoverable></Card>;
    }

    return (
      <Card
        key={this.props.question.index}
        actions={[
          <Button
            className="question-action"
            type="text"
            key="edit"
            onClick={this.showModal}
          >
            <EyeOutlined />
          </Button>,
        ]}
        hoverable
      >
        <Skeleton loading={this.state.loading} active>
          <Card.Meta
            style={{ textAlign: "center" }}
            title={"Question " + this.props.question.index}
          />

          <Modal
            visible={this.state.visible}
            title={"Question " + this.props.question.index}
            footer={null}
            onCancel={() =>
              this.setState({
                visible: false,
              })
            }
          >
            {elements.map((element) => {
              return (
                <div key={element.index}>
                  <img src={element.value} alt="element" />
                </div>
              );
            })}
            <Divider orientation="left" plain>
              Answers
            </Divider>
            <Form>
              {answers.map((answer) => {
                return (
                  <Form.Item
                    name={answer.index}
                    key={answer.id}
                    label={"Answer " + answer.index}
                    rules={[{ required: true }]}
                    initialValue={answer.isCorrect}
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      disabled
                    />
                  </Form.Item>
                );
              })}
            </Form>
          </Modal>
        </Skeleton>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    questions: state.firestore.ordered[props.exam.id + "_questions"],
    answers:
      state.firestore.ordered[
        "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/answers"
      ],
    elements:
      state.firestore.ordered[
        "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/elements"
      ],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection:
          "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/answers",
        orderBy: ["index", "asc"],
      },
      {
        collection:
          "exams/" +
          props.exam.id +
          "/questions/" +
          props.question.id +
          "/elements",
        orderBy: ["index", "asc"],
      },
    ];
  })
)(PreviewQuestion);
