import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
import { Row, Col, Card, Empty, message, Skeleton } from "antd";
import PreviewLiveSessionCard from "./PreviewLiveSessionCard";

class PreviewLiveSessionCardGrid extends Component {
  state = {
    loading: false,
  };

  render() {
    const { live_sessions } = this.props;
    if (!isLoaded(live_sessions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Sessions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      );
    }
    if (isEmpty(live_sessions)) {
      return (
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card title="Sessions" style={{ margin: "1rem 1rem 0 1rem" }}>
              <Empty
                description={<span>There are no sessions to be found</span>}
              ></Empty>
            </Card>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
          <Card title="Sessions" style={{ margin: "1rem 1rem 0 1rem" }}>
            <Row gutter={[16, 16]}>
              {live_sessions.map((live_sessions) => {
                return (
                  <Col span={6} key={live_sessions.id}>
                    <PreviewLiveSessionCard liveSession={live_sessions} selected={this.props.selected} />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    live_sessions: state.firestore.ordered[props.selected.id + "_live_sessions"],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "classes",
        doc: props.selected.id,
        subcollections: [
          { collection: "live_sessions", orderBy: ["vidStart", "desc"] },
        ],
        storeAs: props.selected.id + "_live_sessions",
      },
    ];
  })
)(PreviewLiveSessionCardGrid);
