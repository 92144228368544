import { Button, Form, Input, message, Space } from "antd";
import React from "react";

const ImportUrl = ({ callback, loading }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    await callback(values["url"]);
  };
  const onFinishFailed = () => {
    message.error("Import url failed!");
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        name="url"
        label="URL"
        rules={[
          { required: true },
          { type: "url", warningOnly: true },
          { type: "string", min: 6 },
        ]}
      >
        <Input disabled={loading} placeholder="Enter video url here" />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button disabled={loading} type="primary" htmlType="submit">
            Import
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ImportUrl;
