import React, { Component } from "react";
import {
  Col,
  PageHeader,
  Row,
  Card,
  Layout,
  Skeleton,
  Table,
  Image,
  Space,
  Tag,
  Button,
  message,
  Switch,
  Descriptions,
  Popconfirm,
} from "antd";
import {
  EyeOutlined,
  UserOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { connect } from "react-redux";
import {
  approveVerification,
  disapproveVerification,
  getVerifications,
  rejectVerification,
} from "../../store/actions/verifications";
import { compose } from "redux";
import { Link } from "react-router-dom";
import JsonViewer from "react-json-view";

class Verifications extends Component {
  state = {
    pageSize: 10,
    page: 1,
    loading: false,
    isApproved: false,
  };
  componentDidMount() {
    this.props.getVerifications({
      page: 1,
      size: 10,
      isApproved: false,
    });

    if (this.props.error) {
      message.error(this.props.error ?? "Something went wrong!");
    }
  }

  refreshVerifications = async (page, pageSize) => {
    await this.props.getVerifications({
      page: page ?? this.state.page,
      size: pageSize ?? this.state.pageSize,
      isApproved: this.state.isApproved,
    });
  };

  approve = async (studentUid, deviceUid, onlyDevice) => {
    this.setState({
      loading: true,
    });
    await this.props.approveVerification(studentUid, deviceUid, onlyDevice);

    await this.refreshVerifications();

    this.setState({
      loading: false,
    });
    if (this.props.error) {
      message.error(this.props.error ?? "Something went wrong!");
    } else {
      message.success(
        onlyDevice ? "Device Verified Successful!" : "Verified Successfully!"
      );
    }
  };
  disapprove = async (studentUid, deviceUid, onlyDevice) => {
    this.setState({
      loading: true,
    });
    await this.props.disapproveVerification(studentUid, deviceUid, onlyDevice);

    await this.refreshVerifications();
    this.setState({
      loading: false,
    });
    if (this.props.error) {
      message.error(this.props.error ?? "Something went wrong!");
    } else {
      message.success(
        onlyDevice
          ? "Device Unverified Successful!"
          : "Unverified Successfully!"
      );
    }
  };

  reject = async (verificationId) => {
    this.setState({
      loading: true,
    });

    await this.props.rejectVerification(verificationId);

    await this.refreshVerifications();
    this.setState({
      loading: false,
    });

    if (this.props.error) {
      message.error(this.props.error ?? "Something went wrong!");
    } else {
      message.success("Rejected successfull!");
    }
  };

  onChangeSwitch(checked) {
    this.setState(
      {
        isApproved: checked,
      },
      () => {
        this.refreshVerifications();
      }
    );
  }

  render() {
    const { loading, verifications, error } = this.props;

    if (!loading && verifications) {
      const columns = [
        {
          title: "NIC Images",
          dataIndex: "nicImages",
          key: "deviceUid",
          render: (text, record) => (
            <Row>
              <Space>
                <Image
                  width={50}
                  src={`https://d3gmsfsy250cpm.cloudfront.net/${record.nicImageFront}`}
                />
                <Image
                  width={50}
                  src={`https://d3gmsfsy250cpm.cloudfront.net/${record.nicImageBack}`}
                />
              </Space>
            </Row>
          ),
        },
        {
          title: "Selfie Images",
          dataIndex: "selfieImages",
          key: "deviceUid",
          render: (text, record) => (
            <Row>
              <Space>
                <Image
                  width={50}
                  src={`https://d3gmsfsy250cpm.cloudfront.net/${record.selfieImage1}`}
                />

                <Image
                  width={50}
                  src={`https://d3gmsfsy250cpm.cloudfront.net/${record.selfieImage2}`}
                />
              </Space>
            </Row>
          ),
        },
        {
          title: "Student Status",
          dataIndex: "status",
          key: "deviceUid",
          render: (text, record) => (
            <>
              <Row>
                <Space>
                  <Tag color={record.isApproved ? "green" : "red"}>
                    {record.isApproved ? "Verified" : "Not Verified"}
                  </Tag>
                </Space>
              </Row>
            </>
          ),
        },
        {
          title: "Device",
          dataIndex: "deviceStatus",
          key: "deviceUid",
          render: (text, record) => (
            <>
              <Row>
                <Space>
                  {record.isApproved && !record.deviceApproved && (
                    <Tag color="blue">NEW</Tag>
                  )}
                  <Tag color={record.deviceApproved ? "green" : "red"}>
                    {record.deviceApproved ? "Verified" : "Not Verified"}
                  </Tag>
                  <p>{record.deviceNickname}</p>
                </Space>
              </Row>
            </>
          ),
        },
        {
          title: "Actions",
          key: "action",
          render: (text, record) => (
            <Space>
              <Link target={"_blank"} to={`/student/${record.studentUid}`}>
                <Button icon={<UserOutlined />}>View Student</Button>
              </Link>
              {!record.isApproved && (
                <Popconfirm
                  title="Confirm Verification?"
                  onConfirm={() =>
                    this.approve(record.studentUid, record.deviceUid)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    type="primary"
                  >
                    Verify Student
                  </Button>
                </Popconfirm>
              )}

              {record.isApproved && record.deviceApproved && (
                <Button
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={() =>
                    this.disapprove(record.studentUid, record.deviceUid)
                  }
                  danger
                  type="default"
                >
                  Unverify Student
                </Button>
              )}

              {record.isApproved && !record.deviceApproved && (
                <Button
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={() =>
                    this.approve(record.studentUid, record.deviceUid, true)
                  }
                  type="dashed"
                >
                  Verify Device Only
                </Button>
              )}

              {record.isApproved && record.deviceApproved && (
                <Button
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={() =>
                    this.disapprove(record.studentUid, record.deviceUid, true)
                  }
                  danger
                  type="dashed"
                >
                  Unverify Device
                </Button>
              )}
              <Popconfirm
                title="Confirm Reject Verification?"
                onConfirm={() => this.reject(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  icon={<CloseCircleFilled />}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  danger
                  type="primary"
                >
                  Reject
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ];

      let data = verifications.map((item) => ({
        key: item.studentUid,
        id: item.id,
        deviceUid: item.deviceUid,
        studentUid: item.studentUid,
        nicImageFront: item.nicImageFront,
        nicImageBack: item.nicImageBack,
        selfieImage1: item.selfieImage1,
        selfieImage2: item.selfieImage2,
        isApproved: item.isApproved,
        deviceApproved: item.deviceApproved,
        deviceNickname: item.deviceNickname,
        deviceInfo: item.deviceInfo,
      }));

      return (
        <Layout>
          <PageHeader
            ghost={false}
            className="site-page-header"
            title="Verifications"
          />
          <Row>
            <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
              <Card
                title={
                  this.state.isApproved ? "All Verifications" : "To be Verified"
                }
                style={{ margin: "1rem 1rem 0 1rem" }}
                extra={[
                  <Row>
                    <Space>
                      <p>All Verifications</p>
                      <Switch
                        checked={this.state.isApproved}
                        onChange={(changed) => this.onChangeSwitch(changed)}
                      />
                    </Space>
                  </Row>,
                ]}
              >
                <Table
                  loading={this.state.loading}
                  size="small"
                  dataSource={data}
                  columns={columns}
                  expandable={{
                    expandedRowRender: (record) => {
                      const deviceInfo = JSON.parse(record.deviceInfo);

                      return (
                        <div className="p-5 w-full">
                          <Row>
                            <Col
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}
                            >
                              <div className="mb-5">
                                <Tag
                                  color={
                                    deviceInfo["isPhysicalDevice"]
                                      ? "blue"
                                      : "red"
                                  }
                                >
                                  {deviceInfo["isPhysicalDevice"]
                                    ? "Physical Device"
                                    : "Not a Physical Device"}
                                </Tag>
                              </div>
                              <Descriptions
                                layout="vertical"
                                title="Device Information"
                              >
                                <Descriptions.Item label="Device Nickname">
                                  <b>{record.deviceNickname}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Device Info">
                                  <JsonViewer
                                    theme={"google"}
                                    name="info"
                                    collapsed
                                    src={JSON.parse(record.deviceInfo)}
                                  />
                                </Descriptions.Item>
                              </Descriptions>
                            </Col>
                          </Row>
                        </div>
                      );
                    },
                  }}
                  pagination={{
                    current: this.state.page,
                    pageSize: this.state.pageSize,
                    total: this.props.count,
                    onChange: async (page, pageSize) => {
                      this.setState(
                        {
                          page,
                          pageSize,
                        },
                        () => this.refreshVerifications(page, pageSize)
                      );
                    },
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Layout>
      );
    }

    return (
      <Layout>
        <PageHeader
          ghost={false}
          title="Verifications"
          className="site-page-header"
        ></PageHeader>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card
              title="All Verifications"
              style={{ margin: "1rem 1rem 0 1rem" }}
              extra={<Skeleton.Button active />}
            >
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    error: state.verifications.error ?? null,
    verifications: state.verifications.data ?? [],
    loading: state.verifications.loading,
    count: state.verifications.count,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVerifications: (data) => dispatch(getVerifications(data)),
    approveVerification: (studentUid, deviceUid, onlyDevice) =>
      dispatch(approveVerification(studentUid, deviceUid, onlyDevice)),
    disapproveVerification: (studentUid, deviceUid, onlyDevice) => {
      dispatch(disapproveVerification(studentUid, deviceUid, onlyDevice));
    },
    rejectVerification: (verificationId) =>
      dispatch(rejectVerification(verificationId)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Verifications
);
