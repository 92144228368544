export const getVideoStatus = (text) => {
  switch (text) {
    case "ready":
      return {
        title: "Ready",
        color: "green",
      };
    case "pending":
      return {
        title: "Pending",
        color: "orange",
      };
    case "obtained":
      return {
        title: "Obtained",
        color: "blue",
      };
    case "error":
      return {
        title: "Failed",
        color: "red",
      };
    case "processing":
      return {
        title: "Processing",
        color: "purple",
      };
    default:
      return {
        title: "No Status",
        color: "gray",
      };
  }
};
