import React from "react";

import {
  Card,
  Col,
  PageHeader,
  Row,
  Skeleton,
  Tooltip,
  Image,
  Space,
  Typography,
  Button,
  message,
  Tabs,
} from "antd";
import Layout from "antd/lib/layout/layout";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import {
  CheckCircleFilled,
  WarningOutlined,
  CloseCircleFilled,
  CheckOutlined,
} from "@ant-design/icons";
import firebase from "../../config/fbConfig";
import { useState } from "react";
import { green } from "@ant-design/colors";
import StudentTransactions from "./components/StudentTransactions";

const { TabPane } = Tabs;

const StudentSingle = (props) => {
  const { student, studentUid } = props;

  const [updating, setUpdating] = useState(false);

  async function verifyProfilePicture(verified) {
    setUpdating(true);
    const db = firebase.firestore();
    const update = {};
    update[`profilePicture.isVerified`] = verified;
    await db
      .collection("students")
      .doc(student.user)
      .update(update)
      .then(() => {
        setUpdating(false);
        if (verified) return message.success("Profile Picture Verified!");
        else {
          message.error("Profile Picture Unverified!");
        }
      })
      .catch(() => {
        setUpdating(false);
        message.error("Something went wrong!");
      });
  }

  if (isLoaded(student)) {
    return (
      <Layout>
        <PageHeader
          onBack={() => window.history.back()}
          ghost={false}
          className="site-page-header"
          title={`${student.firstName} ${student.lastName}`}
        ></PageHeader>

        <Card style={{ margin: "1rem 1rem 0 1rem" }}>
          <Row align="middle" justify="space-between" gutter={[50, 50]}>
            <Col flex={1}>
              <Row justify="center">
                <Space size={"middle"} direction="vertical" align="center">
                  <ProfileAvatar student={student} />
                  {student.profilePicture ? (
                    student.profilePicture.isVerified ? (
                      <Button
                        disabled={updating}
                        danger
                        onClick={() => verifyProfilePicture(false)}
                        icon={<CloseCircleFilled />}
                      >
                        Unverify Profile Picture
                      </Button>
                    ) : (
                      <Button
                        disabled={updating}
                        style={{ color: green[5], borderColor: green[5] }}
                        onClick={() => verifyProfilePicture(true)}
                        icon={<CheckCircleFilled />}
                      >
                        Verify Profile Picture
                      </Button>
                    )
                  ) : null}
                </Space>
              </Row>
            </Col>
            <Col flex={5}>
              <Row gutter={[20, 20]}>
                <Col span={8}>
                  <Typography.Text>Full Name</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.firstName} {student.lastName}
                  </Typography.Title>
                </Col>
                <Col span={8}>
                  <Typography.Text>Phone Number</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.phoneNumber}
                  </Typography.Title>
                </Col>
                <Col span={8}>
                  <Typography.Text>School</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.schoolName}
                  </Typography.Title>
                </Col>
                <Col span={8}>
                  <Typography.Text>Medium</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.medium}
                  </Typography.Title>
                </Col>
                <Col span={8}>
                  <Typography.Text>Stream</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.streamName}
                  </Typography.Title>
                </Col>
                <Col span={8}>
                  <Typography.Text>Grade</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.gradeName}
                  </Typography.Title>
                </Col>
                <Col span={8}>
                  <Typography.Text>District</Typography.Text>
                  <Typography.Title level={5} style={{ marginTop: 5 }}>
                    {student.district}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card style={{ margin: `1rem 1rem 0 1rem` }}>
          <Tabs defaultActiveKey="1" onChange={() => {}}>
            <TabPane tab="Transactions" key="1">
              {studentUid && (
                <StudentTransactions
                  student={student}
                  studentUid={studentUid}
                />
              )}
            </TabPane>
            <TabPane tab="Recent Activities" key="2">
              Currently on a maintenance
            </TabPane>
          </Tabs>
        </Card>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 24 }} justify="center">
            <Card style={{ margin: "1rem 1rem 0 1rem" }}>
              <Skeleton active />
            </Card>
          </Col>
        </Row>
      </Layout>
    );
  }
};

const ProfileAvatar = ({ student }) => {
  return (
    <div
      style={{
        position: "relative",
        borderRadius: "100%",
        width: 200,
        height: 200,
      }}
    >
      {student?.profilePicture && (
        <Tooltip
          title={
            student.profilePicture?.isVerified
              ? "Profile picture is verified"
              : "Profile picture not verified"
          }
        >
          <span
            style={{
              position: "absolute",
              top: 10,
              zIndex: 1,
              right: 10,
              width: 40,
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
              background: student?.profilePicture?.isVerified
                ? "#21BF73"
                : "#F05454",
            }}
          >
            {student?.profilePicture?.isVerified ? (
              <CheckOutlined style={{ fontSize: "18px", color: "#fff" }} />
            ) : (
              <WarningOutlined style={{ fontSize: "18px", color: "#fff" }} />
            )}
          </span>
        </Tooltip>
      )}
      <div
        style={{
          position: "relative",
          borderRadius: "100%",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
      >
        <Image
          style={{
            width: 200,
            height: 200,
          }}
          src={student?.profilePicture?.url ?? "/profile.png"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const uid = props.match.params?.uid;
  return {
    studentUid: uid,
    student: state.firestore.data.students?.[uid],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const query = [];

    if (props.userRole === "COMPANY_ADMIN") {
      query.push({
        collection: "students",
        doc: props.match.params?.uid,
      });
    }

    return query;
  })
)(StudentSingle);
